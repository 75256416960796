import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CampaignQuestion from "./CampaignQuestion";
import {useEffect, useState} from "react";
import {CampaignStoreResponseEndPoint} from "../../Axios/EndPoints";
import {post} from "../../Axios/post";
import {useDispatch, useSelector} from "react-redux";
import {setCampaignAnswered} from "../../redux/actions/MiscActions";
import {getHostname} from "../../CustomFunctions/getHostname";
import {COLORS, FONT_COLOR, LOGO, NAV_CLASS} from "../../Configs/Targets";
import {SCREEN_SIZE} from "../../Configs/Constants";
import {isValidPhoneNumber, parsePhoneNumber} from "react-phone-number-input";

const CampaignPopup = (props) => {
    let dispatch = useDispatch();
    const store = useSelector((state) => state);

    const campaign = props.campaign.length ? props.campaign[0] : null;

    const [currentTeamName, setCurrentTeamName] = useState('');
    const [answers, setAnswers] = useState(campaign && campaign.questions);
    const [isMobile, setIsMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const updateScreenWidth = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        updateScreenWidth();
    }, []);

    useEffect(() => {
        setCurrentTeamName(store.misc.appInit?.team_name);
    }, [store.misc.appInit]);

    useEffect(() => {
        if (width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [width]);

    const submitResponse = () => {
        let userAnswers = {};
        let shouldStore = true;
        Object.values(answers).map((value) => {
            if (value.is_required && (typeof value.answer === 'undefined' || value.answer === null)) {
                alert(value.question + ' is a required field');
                shouldStore = false;
                return false;
            }
            if (value.type_id === 'EMAIL' && !value.answer.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                alert('Please enter a valid email');
                shouldStore = false;
                return false;
            }
            let parsedPhone = parsePhoneNumber(value.answer);
            let countryCode = 'GB';
            if (typeof parsedPhone !== 'undefined') {
                countryCode = parsedPhone.country;
            }
            if (value.question === 'Phone' && !isValidPhoneNumber(value.answer, countryCode)) {
                alert('Please enter a valid phone');
                shouldStore = false;
                return false;
            }
            userAnswers['_' + value.id] = value.answer;
        });
        if (shouldStore) {
            storeUserResponse(userAnswers);
        }
        // props?.setCampaignModalShow(false);
    }

    const storeUserResponse = (answers) => {
        post(CampaignStoreResponseEndPoint + '/' + campaign.id, answers)
            .then((response) => {
                if (response.data.success) {
                    dispatch(setCampaignAnswered(true));
                    if (campaign.type === 'fantasy') {
                        props.setCampaignLeagueCodeModalShow(true);
                    } else {
                        props.onHide();
                    }
                } else {
                    alert('Some error occurred. Please try again');
                }
            })
            .catch((err) => {
                console.log('campaign_err', err);
            })
    }

    useEffect(() => {
        setAnswers(campaign && campaign.questions);
    }, [campaign]);

    const renderModal = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const navColor = NAV_CLASS[target];
        const fontColor = FONT_COLOR[target];
        const logo = LOGO[target];
        const color = COLORS[target];
        return (<Modal
            {...props}
            /*scrollable={true}*/
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className={`${navColor}`}>
                <Modal.Title className={`text-center w-100 bebasFont font-34 ${fontColor}`}>
                    <img src={logo} alt={''} style={{height: '70px', width: 'auto'}}/>
                    {campaign.title}
                </Modal.Title>
                <Button className={`btn rounded-circle bg-white border-0`} onClick={props.onHide}>
                    <FontAwesomeIcon icon={faTimes} size={'2x'} color={color}/>
                </Button>
            </Modal.Header>
            <Modal.Body style={{marginLeft: '10%', width: '80%'}}>
                <p className={'text-center w-100 font-16 sfProDisplayFontBold'}>{campaign.description}</p>
                <div className={'offset-xl-2 offset-lg-2 offset-md-0 offset-sm-0 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'}>
                    <img src={campaign.image} alt={campaign.title} className={'img-fluid'}/>
                </div>
                <div className={'row'}>
                    {Object.values(campaign.questions).map((value) => {
                        return (<div className={`mt-3 mb-3 ${['First Name', 'Last Name'].includes(value.question) ? 'col-6' : 'col-12'}`}>
                            <CampaignQuestion question={value} answers={answers} setAnswers={setAnswers} currentTeamName={currentTeamName} setCurrentTeamName={setCurrentTeamName}/>
                        </div>);
                    })}
                </div>
                <Modal.Footer className={`w-100 border-0 justify-content-center`}>
                    <Button className={`${navColor} border-0 rounded-pill btn-lg ps-4 pe-4`} onClick={submitResponse}>
                        {campaign.type === 'fantasy' ? 'Generate League Code' : 'Submit'}
                    </Button>
                    <Button className={`btn-secondary border-0 rounded-pill btn-lg ps-4 pe-4`} onClick={props.onHide}>Close</Button>
                </Modal.Footer>
                <Modal.Footer className={`w-100 border-0 justify-content-end`}>
                    <a className={`border-0 rounded-pill btn-lg ps-4 pe-4 btn-link`} href={'https://fanzine.com/terms'} target={'_blank'} style={{color: color}} rel="noreferrer">Terms & Conditions</a>
                </Modal.Footer>
            </Modal.Body>
        </Modal>);
    }

    const render = () => {
        if (campaign) {
            return renderModal();
        } else {
            return null;
        }
    }

    return render();

}

CampaignPopup.propTypes = {
    campaign: PropTypes.array.isRequired,
}

export default CampaignPopup;
