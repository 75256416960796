import TopMatchesSlider from '../../Components/Slider/TopMatchesSlider';
import TopStories from '../../Components/Layout/TopStories';
import LatestSection from '../../Components/Layout/LatestSection/LatestSection';
import SpecificLeagueSection from '../../Components/Layout/SpecificLeagueSection/SpecificLeagueSection';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import {SCREEN_SIZE} from '../../Configs/Constants';
import {useSelector} from 'react-redux';

const FootballHome = () => {


    let {team} = useParams();


    const store = useSelector((state) => state);
    const location = useLocation();
    let queryParams = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [homepageLoading, setHomePageLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [teamData, setTeamData] = useState(null);

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect(() => {
        let clubFound = false;
        store.football?.navbarTeamSlugsId?.length && store.football.navbarTeamSlugsId.map((value, index) => {
                if (team === value?.club) {
                    setTeamData(value);
                    clubFound = true;
                }
            },
        );

        // if(!clubFound)
        // {
        //     console.log('store?.football?.fanzineTeamsLeagues =',store?.football?.fanzineTeamsLeagues)
        //     const womanLeague = store?.football?.fanzineTeamsLeagues[7]?.leagues;
        //     for(let i=0;i<womanLeague?.length;i++) {
        //
        //         for (let j = 0; j < womanLeague[i]?.teams?.length; j++)
        //         {
        //             const womanTeam = womanLeague[i]?.teams[j];
        //             console.log('teams in woman leagues =', team, womanTeam);
        //         }
        //     }
        // }


    }, [team, store?.football?.fanzineTeamsLeagues,store.football?.navbarTeamSlugsId, teamData, location.pathname]);

    const renderMainFootballPage = () => {
        return (
            <section className={'text-center'}>
                <SubNavbar isMobile={isMobile} items={['news', 'teams', 'matches']}/>
                {!isMobile && <TopMatchesSlider/>}
                <TopStories isMobile={isMobile}/>
                <LatestSection isMobile={isMobile}/>
                <SpecificLeagueSection isMobile={isMobile}/>
            </section>
        );
    };

    const renderClubPage = () => {

        return (
            <>
                <SubNavbar data={teamData} isMobile={isMobile} isClub={team}/>
                <section>
                    {!isMobile && teamData && <TopMatchesSlider teamData={teamData}/>}
                    <div className={isMobile ? 'pt-5' : ''}>

                        {
                            teamData ?
                                <TopStories isClubPage={true} isMobile={isMobile} location={location}
                                            teamData={teamData}/> : null
                        }
                        {
                            teamData &&
                            <LatestSection data={teamData} isMobile={isMobile}
                                           teamData={teamData}/>
                        }
                    </div>
                </section>
            </>
        );
    };

    const renderHomePageMain = () => {
        return typeof team === 'undefined' ?
            renderMainFootballPage() :
            renderClubPage();
    };

    return renderHomePageMain();

};

export default FootballHome;
