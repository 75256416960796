import Card from "react-bootstrap/Card";
import shareLogoUrl from "../../assets/images/shareIcon.png";
import playButton from "../../assets/images/playButton.png";
import "./videostyle.css";
import moment from 'moment-timezone';
import {Link, useNavigate} from 'react-router-dom';
import SocialShareButton
  from '../../Components/Layout/Social/SocialShareButton';
const VideoCards = (props) => {
  const {data,setSelectedVideo,selectedVideo,isMobile } = props;

  const navigate = useNavigate();
  const handleClick = () => {
    if(setSelectedVideo && !isMobile)
    {
      setSelectedVideo(data);
      // window.scrollTo(0, 0);
    }
    else
    {
      navigate(`/football/video/${data?.id}`);
    }
  };


  return (
      <div onClick={handleClick} className={'text-decoration-none text-black'} >
      <Card className={"video_card_top_component cursor-pointer shadow border-radius-15px"}>
        <Card.Img variant="top" src={data?.preview_image} className={"video_card_img border-radius-top-15px"} />
        <Card.ImgOverlay className={"d-flex video_card_img_overlay justify-content-center align-items-center"}>
            <div className={"d-flex justify-content-center align-items-center"}>
              <img
                  src={playButton}
                  alt={"play button"}
                  className={"video_card_play_btn"}
              />
            </div>
        </Card.ImgOverlay>

        <Card.Body>
          <div className={"d-flex justify-content-between align-items-center"}>
            <div className={"d-flex align-items-center justify-content-center"} style={{ flex: 0.75 }}>
              <img
                  style={{
                    height:26,
                    width:26,
                    objectFit:'cover',
                  }}
                  src={data?.publisher?.icon}
                  alt={"logo"+data?.publisher?.name}
              />
              &nbsp;
              <span className={'font-10 font-weight-600'}>{data?.publisher?.name?.length > 20 ?
                  data?.publisher?.name.slice(0,25)+'...'
                  :
                  data?.publisher?.name
              }</span>
            </div>
            <div
                className={
                  "d-flex align-items-center justify-content-center flex-grow-1"
                }
            >
              <SocialShareButton tmpSocialNews={data}  />
              {/*<img*/}
              {/*    src={shareLogoUrl}*/}
              {/*    alt={"Share URL"}*/}
              {/*    className={"video_card_share_logo"}*/}
              {/*/>*/}
            </div>
            <div
                className={
                  "d-flex align-items-center justify-content-center video_card_time"
                }
            >
              {moment.utc(data?.published_at?.date)
                  .local()
                  .startOf('seconds')
                  .fromNow()}

            </div>
          </div>
        </Card.Body>
      </Card>
      </div>
  );
};
export default VideoCards;
