import {get} from '../../../Axios/get';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {FOOTBALL} from '../../../Axios/EndPoints';
import {Button} from 'react-bootstrap';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import VideoCard from '../../Cards/VideoCard';
import {getHostname} from "../../../CustomFunctions/getHostname";
import {COLORS, FONT_COLOR} from "../../../Configs/Targets";
import {
  capitalizeWords
} from '../../../CustomFunctions/UpperCaseEveryFirstWord';

const LatestVideos = (props) => {
    const {isMobile, data, leagueId, leagueName,showOnSlider} = props;
    const [searchParams, setSearchParams] = useSearchParams()
    const [latestVideos, setLatestVideos] = useState([])
    const [latestVideosLoading, setLatestVideosLoading] = useState(true)

    const fetchLatestVideos = (endpoint = null) => {

        let league_id = (leagueId || searchParams.get('team_id') || searchParams.get('league_id') || data?.clubId || '');
        let parameters = leagueId ? '&league_id='+ leagueId : searchParams.get('league_id') ?
            '&league_id=' + league_id : searchParams.get('team_id') || data?.clubId ?
                '&team_id=' + league_id :
                '&main_site=1';
        // console.log('endpoint =',endpoint)
      if(endpoint?.includes('undefined'))
      {
        endpoint = endpoint.replace('?team_id=undefined','')
      }

        let url = endpoint ? endpoint : ((leagueId || searchParams.get('league_id')) ?
            FOOTBALL.LatestClubVideosEndPoint + parameters : searchParams.get('team_id') || data?.clubId ?
                FOOTBALL.LatestClubVideosEndPoint + parameters :
                FOOTBALL.LatestVideosEndPoint + parameters);

        get(url).then((response) => {
            // eslint-disable-next-line array-callback-return
            let tempData = response.data;
            tempData.sort(function (a, b) {
                let keyA = new Date(a.published_at.date),
                    keyB = new Date(b.published_at.date);
                // Compare the 2 dates
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });
            setLatestVideos(tempData);
            setLatestVideosLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };


    useEffect(() => {
        setLatestVideosLoading(true);
        // if (props.videosData) {
        //     setLatestVideos(props.videosData);
        //     setLatestVideosLoading(props.loading);
        // } else {
        fetchLatestVideos(props.videosEndpoint)
        // }
    }, [searchParams.get('league_id'), searchParams.get('team_id'), props.videosEndpoint, data])


    const renderLeftButton = (id) => {

        let style =
            {
                width: '63px',
                height: '68px',
                backgroundColor: '#fff',
                color: 'black',
                border: '0px',
                borderRadius: '0px',
            };

        const handleLeftClick = () => {
            document.getElementById('videoSliderContainer' + id).scrollLeft -= 400;

        };

        return (
            <div
                style={{
                    marginLeft: '-3em',
                    marginRight: '-2em',
                    zIndex: '1',
                    marginTop: '-4em',
                }}
                onClick={handleLeftClick}
            >
                <Button className={''} style={style}>
                    <AiOutlineLeft/>
                </Button>
            </div>
        );
    };

    const renderRightButton = (id) => {

        let style =
            {
                width: '63px',
                height: '68px',
                backgroundColor: '#fff',
                color: 'black',
                border: '0px',
                borderRadius: '0px',
            };

        const handleRightClick = () => {
            document.getElementById('videoSliderContainer' + id).scrollLeft += 400;
        };

        return (
            <div
                onClick={handleRightClick}
                style={{
                    marginLeft: '-3em',
                    zIndex: '1',
                    marginTop: '-4em',

                }}
            >
                <Button
                    style={style}
                > <AiOutlineRight/>
                </Button>
            </div>
        );
    };

    const renderVideos = (videoData, title) => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        let style = {
            background: target === 'fanzine' ? 'linear-gradient(to bottom, #74dbef, #0092af)' : showOnSlider ? COLORS[target] : '',
        };

        return (
            <div className={`w-100 mb-5 ${target === 'fanzine' ? 'text-black' : showOnSlider ? FONT_COLOR[target] : 'text-black'}`}
                 style={style}
            >
                <div className={'container'}>
                    <div className={`${isMobile ? 'w-100' : 'container'}`}>
                        <div className={`row ${isMobile ? '' : 'ps-5 pe-5'}`}>
                            <p className={`font-30 pt-4 pb-3 m-0 text-start robotoCondensedBold font-italic ${target === 'fanzine' ? 'text-black' : showOnSlider ? FONT_COLOR[target] : 'text-black'}`}>
                                {title}
                            </p>
                            <div
                                className={'d-flex justify-content-center align-items-center'}>
                                {!isMobile && renderLeftButton(videoData[0]?.id)}
                                <div
                                    id={'videoSliderContainer' + videoData[0]?.id}
                                    className={`m-1 ${showOnSlider ? 'slider-mobile-container' : ''} ${videoData?.length <=
                                    3 ?
                                        'justify-content-center' :
                                        'overflow-scroll-x'}`}>
                                    {videoData?.map((match) => (
                                        <VideoCard video={match} isFanzineAllFootball={true}
                                                   videoHeight={isMobile ? '120px' : '180px'} isMain={false}
                                                   extraClass={`${target === 'fanzine' ? 'text-black' : showOnSlider ? FONT_COLOR[target] : 'text-black row border-radius-15px'} m-2`}
                                                   league={'Latest Videos'}
                                                   showOnSlider={showOnSlider}
                                                   sport={props.sport || 'football'}
                                                   isMobile={isMobile}
                                                   target={target}
                                        />
                                    ),)}
                                </div>
                                {!isMobile && renderRightButton(videoData[0]?.id)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderMain = () => {

      let teamNameRaw = (data?.team_name || data?.clubName || data?.name);

        return latestVideos?.length > 0 && renderVideos(latestVideos, `${data ? 'Latest ' + (capitalizeWords(teamNameRaw)) + ' Videos' :
            leagueName ? leagueName + ' Latest Videos' : 
            'Latest Videos'}`);

    };

    return renderMain();

}

export default LatestVideos;
