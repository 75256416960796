import {Link, useLocation} from 'react-router-dom';
import MobileNewsMenuItem from './MobileNewsMenuItem';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

export const GenderNavbar = (props) => {

  const location = useLocation();
  const {
    isMobile,
    setParams,
    selectedTeamType,
    setSelectedTeamType,
    isFanzineClubMatches,
  } = props;
  const store = useSelector(state => state.football);

  const [currentActive, setCurrentActive] = useState('All');
  const [sportName, setSportName] = useState((location.pathname.split('/'))[1]);

  useEffect(() => {
    //
    // store?.fanzineTeamsLeagues.filter((value)=>{
    //   return value?.teamTypes
    // })

    setSportName((location.pathname.split('/'))[1]);

  }, [props]);

  const renderMobileMenu = () => {

    let sportName = '';
    let selectedSports = '';

    switch (location.pathname.split('/')[1]) {
      case 'football':
        sportName = 'football';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'football';
        });

        break;
      case 'cricket':
        sportName = 'cricket';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'cricket';
        });

        break;
      case 'nfl':
        sportName = 'nfl';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'nfl';
        });

        break;
      case 'nba':
        sportName = 'nba';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'nba';
        });

        break;
      case 'mlb':
        sportName = 'mlb';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'mlb';
        });

        break;
      default:
        sportName = 'football';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'football';
        });

        break;
    }

    const handleClick = (value) => {
      console.log('I am clicked =', value);
      // setParams(value);
    };

    console.log('selectedSports[0]?.teamTypes', selectedSports[0]?.teamTypes);
    return (
        <div className={`slider-mobile-news-container`}>
          {
            sportName === 'football' ? null :
                <MobileNewsMenuItem
                    handleClick={handleClick}
                    isGenderNavbar={true}
                    inactiveFontColor={'#000000'} isMobile={isMobile}
                    setCurrentActive={setCurrentActive} title={'All'}
                    uri={`All`}
                    selected={currentActive === 'All'}
                >
                </MobileNewsMenuItem>
          }
          {((sportName === 'football' || sportName === 'cricket') &&
                  selectedSports?.length > 0) &&
              Object.keys(selectedSports[0]?.teamTypes)?.map((value, index) => (
                  <MobileNewsMenuItem
                      handleClick={handleClick}
                      isGenderNavbar={true}
                      inactiveFontColor={'#000000'} isMobile={isMobile}
                      setCurrentActive={setCurrentActive}
                      title={selectedSports[0]?.teamTypes[value]}
                      uri={`${value}`}
                      selected={currentActive === value}
                  >
                  </MobileNewsMenuItem>
              ))
          }
          {((sportName !== 'football' || sportName !== 'cricket') &&
                  selectedSports?.length > 0) &&
              selectedSports[0]?.conferences?.map((value, index) => (
                  <MobileNewsMenuItem
                      handleClick={handleClick}
                      isGenderNavbar={true}
                      inactiveFontColor={'#000000'} isMobile={isMobile}
                      setCurrentActive={setCurrentActive} title={value?.name}
                      uri={`/${value?.id}`}
                      selected={currentActive === value}
                  >
                  </MobileNewsMenuItem>
              ))
          }

        </div>
    );
  };

  const renderBigView = () => {

    return (
        <>
          <div
              className={`${isMobile ?
                  'col-7' :
                  'd-flex align-items-center col-xxl-10 col-xl-10 col-lg-9 col-md-8 col-sm-7'}`}>
            {
              isMobile ? renderMobileMenu() :
                  (
                      <div className={`d-flex flex-row  justify-content-start`}>
                        <Link to={`/${sportName}/+''}`}
                              className={`text-white font-weight-500 pe-5  text-decoration-underline-fanzine`}>
                          Men
                        </Link>
                        <Link to={`/${sportName}/news`}
                              className={`text-white font-weight-500 pe-5 text-decoration-underline-fanzine`}>
                          Women
                        </Link>
                        <Link to={`/${sportName}/teams`}
                              className={`text-white font-weight-500 pe-5 text-decoration-underline-fanzine`}>
                          Youth
                        </Link>
                      </div>
                  )
            }
          </div>
        </>
    );
  };


  const handleSelectTeamType = (value)=>
  {
    setSelectedTeamType(value);
  }
  const render = () => {
    return (
        <div
            className={`${isMobile ?
                'w-100' :
                'ps-5'} bg-white d-flex align-items-center fanzine-football-nav`}
            style={{
              height: '3em',
              position: isMobile && 'fixed',
              marginTop: (isMobile && selectedTeamType === 'all') ? 140 : (isMobile && selectedTeamType !== 'all') ? 80 : 0,
            }}
        >
          <div
              className={`${isMobile ? '' : 'ps-5'} container`}>
            <div className={`${isMobile ? '' : 'ps-5'} row ${isMobile ?
                'pt-2' :
                'ms-3'}`}
                 style={{
                   position: 'relative',
                 }}
                 id={'subNavbar'}
            >
              {(isMobile && !isFanzineClubMatches) ? renderMobileMenu()
                  :
                  (isMobile && isFanzineClubMatches) ?
                      (
                          <div
                              className={`d-flex flex-row  justify-content-start`}>
                            <div
                                onClick={()=>handleSelectTeamType('all')}
                                className={`text-dark font-weight-500 pe-5  text-decoration-underline-fanzine ${selectedTeamType ===
                                'all' ?
                                    'font-weight-800' :
                                    'font-weight-300'}`}>
                              All
                            </div>
                            <div
                                onClick={()=>handleSelectTeamType('men')}
                                className={`text-dark font-weight-500 pe-5 text-decoration-underline-fanzine ${selectedTeamType ===
                                'men' ?
                                    'font-weight-800' :
                                    'font-weight-300'}`}>
                              Men
                            </div>
                            <div
                                onClick={()=>handleSelectTeamType('women')}
                                className={`text-dark font-weight-500 pe-5 text-decoration-underline-fanzine ${selectedTeamType ===
                                'women' ?
                                    'font-weight-800' :
                                    'font-weight-300'}`}>
                              Women
                            </div>
                          </div>
                      )
                      :
                      (
                          ''
                      )
              }
            </div>
          </div>
        </div>
    );
  };

  return isMobile && render();
};
