import {Card} from "react-bootstrap";
import cutOutOne from "../../../assets/images/cutOutOne.webp";
import "../../../assets/styles/ClubSquads.css"

const ClubSquadsCard = () => {
	return(
			<Card className={"main-card"}>
				<Card.Img src={cutOutOne} alt="Image" className="img-fluid custom-shadow" style={{borderRadius: "10px"}}/>
				<div className={"black-background-behind-texts"}>
					<div className={"d-flex flex-column flex-wrap"}>
                  <span className="sfProDisplayFontBold card-text">
                    Jordan
                  </span>
						<span className="sfProDisplayFontBold card-text">
                    Pickford
						</span>
					</div>
					<span className={"bebasFont align-self-center card-text-number"}
					>
                10
					</span>
				</div>
			</Card>
	)
}

export default ClubSquadsCard;
