import {Card} from 'react-bootstrap';
import {NavLink, useLocation} from 'react-router-dom';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html.modern';
import {FONT_COLOR} from '../../../Configs/Targets';
import {getHostname} from '../../../CustomFunctions/getHostname';

const FanzineSportsNewsCard = (props) => {
    const {
        news,
        imageHeight,
        cardTextSize,
        isNewsPage,
        publisherIconSize,
        isMain,
        newsOnSlider,
        isMobile,
        showNewsBelow,
        showNewsSide,
        fontColor,
        bgColor,
        isClubWebsite,
    } = props;

    const location = useLocation();

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    const addDefaultSrc = (ev) => {
        ev.target.src = require(
            '../../../assets/images/football-shots-you-need-to-know.jpg');
    };

    const addDefaultPublisherIcon = (ev) => {
        ev.target.src = require('../../../assets/images/default_publisher_icon.png');
    };

    const newsOverImage = (news, publisherIconSize) => {
        return (
            <>
                <span
                    className={`robotoCondensedBold ${imageHeight === '500px' ?
                        'font-36' :
                        'font-22'}`}
                    dangerouslySetInnerHTML={{__html: news && news?.news_title}}
                />
                <br/>
                <span
                    className={`robotoCondensedBold me-2 ${props?.BigImage ?
                        'font-18' :
                        'font-16'} text-white`}
                    dangerouslySetInnerHTML={{
                        __html: news && news?.news_publisher?.publisher_name,
                    }}
                />
                <span
                    className={`float-end font-12 robotoCondensedBold font-weight-800 text-white ${props?.BigImage ?
                        'font-18' :
                        'font-16'}`}
                >
                    {news && news?.news_time
                        ?.replace('minutes', 'mins')
                        ?.replace('ago', '')
                        ?.replace('an', '1')
                        ?.replace('a day', '1 day')}
                </span>
            </>);
    };

    const newsBelowImage = (news, publisherIconSize) => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        return (<div style={{width: isNewsPage && isMobile ? '170px' : 'auto'}}>
            {/*<div
                className={`robotoCondensedBold font-18 mt-2 news-text text-white`}
                dangerouslySetInnerHTML={{__html: news && news.news_title}}
            />*/}
            <HTMLEllipsis
                unsafeHTML={news?.news_title}
                maxLine="2"
                ellipsis="..."
                basedOn="letters"
                style={{
                    lineHeight: 1.2,
                    height: '3em',
                    width: isNewsPage && isMobile ? '170px' : 'auto',
                    color: props?.league?.font_color ?
                        props?.league?.font_color :
                        fontColor ? fontColor : '#000',
                }}
                className={`robotoFont font-weight-600 font-16 text-wrap text-start ${fontColor ?
                    fontColor :
                    'text-black'}`}
            />
            {/*<img
				src={news && news.news_publisher?.publisher_icon}
				alt={news && news.news_publisher?.publisher_name}
				onError={addDefaultPublisherIcon}
				height={'25px'}
			/>*/}
            <div className={'d-flex justify-content-between'}>
                <span
                    className={`robotoFont me-2 font-12 text-start
                          ${target === 'fanzine' ?
                        'text-black' :
                        fontColor ? fontColor : 'text-black'}
                // ${target === 'fanzine' ?
                        'font-fanzine' :
                        fontColor ? fontColor : 'text-black'}`}
                    dangerouslySetInnerHTML={{
                        __html: news && news.news_publisher?.publisher_name,
                    }}
                />
                <span className={`text-end
       font-12 robotoFont
                            ${target === 'fanzine' ?
                    'text-black' :
                    fontColor ? fontColor : 'text-black'}
             // ${target === 'fanzine' ?
                    'font-fanzine' :
                    fontColor ? fontColor : 'text-black'}
              pt-1`}>
                    {news && news?.news_time
                        .replace('minutes', 'mins')
                        .replace('ago', '')
                        .replace('an', '1')
                        .replace('a day', '1 day')}
                </span>
            </div>
        </div>);
    };

    const newsSideImage = (news, publisherIconSize, cardTextSize) => {
        return (
            <div className={`col-7`}>
                <img
                    src={news && news.news_publisher.publisher_icon}
                    alt={news && news.news_publisher.publisher_name}
                    onError={addDefaultPublisherIcon}
                    height={21}
                />
                <span
                    className={"sfProDisplayFont ms-2 me-2 font-12 text-black"}
                    dangerouslySetInnerHTML={{
                        __html: news && news.news_publisher.publisher_name,
                    }}
                />
                <div
                    className={`sfProDisplayFontBold font-14 mt-2 news-text`}
                    dangerouslySetInnerHTML={{__html: news && news.news_title}}
                />
                <span className={`float-start font-12 sfProDisplayFont text-gray pt-1`}>
                    {news && news.news_time
                        .replace("minutes", "mins")
                        .replace("ago", "")
                        .replace("an", "1")
                        .replace("a day", "1 day")}
                </span>
            </div>);
    };

    const renderCard = () => {

        if (showNewsSide) {
            return (
                <div className={`row mt-2 p-3`}>
                    <Card
                        className={`col-5 border-0 p-0 ${!props?.isNewDesign && 'rounded-custom'}`}
                    >
                        <Card.Img
                            variant="top"
                            className={'rounded-custom'}
                            height={imageHeight}
                            width={'auto'}
                            style={{objectFit: 'cover'}}
                            src={news && news.news_image}
                            alt={news && news.news_title}
                            onError={addDefaultSrc}
                        />
                    </Card>
                    {newsSideImage(news, publisherIconSize)}
                </div>
            );
        }

        return (
            <>
                <Card
                    className={`${newsOnSlider &&
                    'slider-mobile-match-card'} border-radius-15 col mt-2 border-0 bg-transparent`}
                    style={{
                        height: isNewsPage && isMobile ?
                            ('120px') :
                            (imageHeight || '245px'),
                        width: isNewsPage && isMobile ? '170px' : 'auto',
                    }}
                >
                    <Card.Img
                        variant="top"
                        className={'rounded-4'}
                        height={isNewsPage && isMobile ?
                            ('120px') :
                            (imageHeight || '245px')}
                        width={isNewsPage && isMobile ? '170px' : 'auto'}
                        src={news && news?.news_image}
                        alt={news && news?.news_title}
                        onError={addDefaultSrc}
                        style={{
                            objectFit: 'cover',
                            borderRadius: !props.isNewDesign && '15px',
                            width: isNewsPage && isMobile ? '170px' : 'auto',
                        }}
                    />
                    {
                        !isMobile && <Card.ImgOverlay
                            style={{
                                lineHeight: 1,
                            }}
                            className={`d-flex rounded-4 align-items-start flex-column justify-content-end ${news?.type ===
                            'videos' ? 'video-overlay' : 'news-overlay'}`}
                        >
                            <Card.Text
                                className={`text-white text-start robotoCondensedBold text-wrap ${props?.isFanzineNews ?
                                    'font-10' :
                                    cardTextSize} w-100`}
                            >
                                {(isNewsPage && isMobile) || showNewsBelow ?
                                    null :
                                    newsOverImage(news, publisherIconSize)}
                            </Card.Text>
                        </Card.ImgOverlay>
                    }
                </Card>
                {(isNewsPage && isMobile) || showNewsBelow ?
                    newsBelowImage(news, publisherIconSize) :
                    null}
            </>
        );
    };

    const renderSnack = (
        news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize,
        isMobile, textColor, isMain) => {

        const sport = location.pathname.split('/')[1];

        let link = '';
        if (typeof news.main_team !== 'undefined' && news.main_team) {
            link = `/${sport}/${news.main_team}/${news.slug}`;
        } else {
            link = `/${sport}/${news.slug}`;
        }
        return (
            <NavLink to={link} className={'text-decoration-none text-dark'}>
                {isMobile ?
                    renderCard(news, mainImageStyle, cardTextSize, isNewsPage,
                        publisherIconSize, isMain) :
                    renderCard(news, mainImageStyle, cardTextSize, isNewsPage,
                        publisherIconSize, isMain)}
            </NavLink>
        );
    };

    const renderNewsNow = (
        news, imageHeight, cardTextSize, isNewsPage, publisherIconSize, isMobile,
        textColor, isMain) => {
        const sport = location.pathname.split('/')[1] || 'football';

        let link = '';
        if (target === 'fanzine') {
            if (typeof news.main_team !== 'undefined' && news.main_team) {
                link = `/${sport}/${news.main_team}/${news.slug}`;
            } else {
                link = `/${sport}/news/${news.slug}`;
            }
        } else {
            link = `/news/${news.slug}`;
        }
        return (<a
            href={link}
            className={'text-decoration-none text-dark'}
            target="_blank"
            rel="noreferrer"
        >
            {isMobile ?
                renderCard(news, imageHeight, cardTextSize, isNewsPage,
                    publisherIconSize, isMain) :
                renderCard(news, imageHeight, cardTextSize, isNewsPage,
                    publisherIconSize, isMain)}
        </a>);
    };

    return (
        <>
            {news && news.is_snack && target === 'fanzine' ?
                renderSnack(news, imageHeight, 'cardTextSize', isNewsPage,
                    publisherIconSize, 'isMobile', 'textColor', isMain) :
                renderNewsNow(news, imageHeight, 'cardTextSize', isNewsPage,
                    publisherIconSize, 'isMobile', 'textColor', isMain)}
        </>);

    // return renderCard();
};

export default FanzineSportsNewsCard;
