import React, {useEffect, useMemo, useRef, useState} from 'react';
import {get} from '../../../Axios/get';
import {FOOTBALL, WebLinks} from '../../../Axios/EndPoints';
import SpecificLeagueVideos
  from '../../../Components/Layout/SpecificLeagueSection/SpecificLeagueVideos';
import ItemLoader from '../../../Components/Loader/ItemLoader';
import SliderNoBackground from './sliderNoBackground';
import GoogleAd from '../../../Components/Ads/GoogleAd';
import {useSelector} from 'react-redux';
import axios from 'axios';

const SpecificLeagueVideosOnly = (props) => {

  const {isMobile, teamData, isClub, setSelectedVideo, isFanzineClub} = props;
  const [league, setLeague] = useState([]);
  const [leagueLoading, setLeagueLoading] = useState(true);
  const store = useSelector(state => state.misc.appInit);
  const [fetchedFanzineClubData, setFetchedFazineClubData] = useState(props?.fetchedFanzineClubData ||null);

  function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
    ), [ref]);

    useEffect(() => {
      observer.observe(ref.current);
      return () => observer.disconnect();
    }, []);

    return isIntersecting;
  }

  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  const fetchLeagues = () => {
    // let parameters = '?league_id=1204&limit=15';

    if (isFanzineClub) {
      let baseURL = '';
      baseURL = teamData?.clubUrl?.replace('www', 'api2');
      axios.get(baseURL + FOOTBALL.AllLeaguesEndPoint).then((response) => {
        setLeague(response.data?.leagues);
        setLeagueLoading(false);
      }).catch((err) => {
        console.error(err);
      });

    } else {
      get(FOOTBALL.AllLeaguesEndPoint).then((response) => {
        setLeague(response.data?.leagues);
        setLeagueLoading(false);
      }).catch((err) => {
        console.error(err);
      });
    }

  };


  useEffect(() => {
    isVisible && league?.length === 0 && fetchLeagues();
    if(props?.fetchedFanzineClubData)
    {
      setFetchedFazineClubData(props?.fetchedFanzineClubData);
    }
  }, [isVisible,props?.fetchedFanzineClubData]);

  const renderSpecificLeagueVideos = (data) => {
    return <SpecificLeagueVideos
        isClub={isClub}
        setSelectedVideo={setSelectedVideo}
        isMobile={isMobile} leagueData={data}/>;
  };

  const renderSpecificLeagueGoals = (data) => {

    return (<SliderNoBackground
        // endpoint={FOOTBALL.HighlightsFanzineVideosEndPoint+'&type=goal'}
        setSelectedVideo={setSelectedVideo}
        leagueName={data?.name?.toUpperCase() + ' GOALS'}
        leagueId={data?.league_id}/>);
  };

  const renderSpecificLeagueVideosOnlyMain = () => {

    return (
        <div ref={ref}>
          {leagueLoading ?
              <ItemLoader loading={leagueLoading}/>
              :
              league?.length > 0 && league?.map((leagueValue, leagueIndex) => {

                    return isClub ? leagueValue?.league_id == store?.league_gs_id ? (
                                <div key={leagueIndex}>
                                  {renderSpecificLeagueVideos(leagueValue)}
                                  {!isMobile && <GoogleAd id={'4009154580'}/>}
                                  {renderSpecificLeagueGoals(leagueValue)}
                                </div>
                            ) :
                            leagueValue?.league_id == fetchedFanzineClubData?.league_gs_id ?
                                (
                                    <div key={leagueIndex}>
                                      {renderSpecificLeagueVideos(leagueValue)}
                                      {!isMobile && <GoogleAd id={'4009154580'}/>}
                                      {renderSpecificLeagueGoals(leagueValue)}
                                    </div>)
                                :
                                <></> :
                        (
                            <div key={leagueIndex}>
                              {renderSpecificLeagueVideos(leagueValue)}
                              {!isMobile && <GoogleAd id={'4009154580'}/>}
                              {renderSpecificLeagueGoals(leagueValue)}
                            </div>
                        );
                  },
              )
          }
        </div>
    );

  };

  return renderSpecificLeagueVideosOnlyMain();

};

export default SpecificLeagueVideosOnly;
