import {useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../../../Configs/Constants';
import {
  FanzineSportsSubNavbar
} from '../../../Components/Navbar/FanzineSportsSubNavbar';
import FanzineSportsNavBar
  from '../../../Components/Navbar/FanzineSportsNavBar';
import ClubOverview from './ClubOverview';
import ClubNews from './ClubNews';
import ClubMatches from './ClubMatches';
import ClubTable from './ClubTable';
import ClubSquads from './ClubSquads';
import GoogleAd from '../../../Components/Ads/GoogleAd';
import {useSelector} from 'react-redux';

const Club = () =>
{
  const allSportsStore = useSelector(state => state.football.fanzineTeamsLeagues);

  const location = useLocation();
  const [isMobile,setIsMobile] = useState(false);

  const [sidelined,setSidelined] =useState([]);
  const [teamData, setTeamData] = useState(null)


  useEffect(() => {
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.screen.width, isMobile]);

  useEffect(()=>{
    let teamName = location.pathname.split('/')[1];
    let teamDataToBeSend = [];
    for (let i = 0; i < allSportsStore[0]?.leagues?.length; i++) {
      for (let j = 0; j < allSportsStore[0]?.leagues[i]?.teams?.length; j++) {
        if (allSportsStore[0]?.leagues[i]?.teams[j].slug === teamName) {
          teamDataToBeSend = allSportsStore[0]?.leagues[i]?.teams[j];
          console.log("teamDataToBeSend: ", teamDataToBeSend)
        }
      }
    }

    setTeamData(teamDataToBeSend);
  },[allSportsStore])

  console.log("teamData: ", teamData)




  const renderMain = () =>{

    const selectedView = location.pathname.split('/')[2];

    return(
        <div className={'bg-white'}>
          <FanzineSportsNavBar  />
          <div className="d-flex justify-content-center align-items-center h1 h-25px">
            {!isMobile && <GoogleAd id={'3349434796'}/>}
          </div>
          <FanzineSportsSubNavbar teamData={teamData} isMobile={isMobile}  />
            <div className={'bg-white'}>
              {selectedView === 'overview' && <ClubOverview teamData={teamData} isMobile={isMobile} />}
              {selectedView === 'news' && <ClubNews teamData={teamData} isMobile={isMobile} />}
              {selectedView === 'matches' && <ClubMatches teamData={teamData} isMobile={isMobile} />}
              {selectedView === 'table' && <ClubTable singleMatchData={teamData} isMobile={isMobile} />}
              {selectedView === 'squad' && <ClubSquads teamData={teamData} isMobile={isMobile} />}
            </div>
        </div>
    )
  }

  return renderMain();

}

export default Club;
