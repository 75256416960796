import BGWhite from '../../assets/images/corporate/whiteBG.png';
import React from 'react';

const MultiSport = (props) => {
  const {isMobile} = props;

  return (
      <div
          style={{
            backgroundImage: `url("${BGWhite}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '200px',
          }}
      >
        <h1 className={'prometoFont'}
            style={{fontSize: isMobile ? '32px' : '50px', paddingTop: '80px'}}
        >
          LATEST PRODUCT RELEASES
        </h1>

      </div>
  );
};


export default MultiSport;
