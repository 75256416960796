let SCREEN_SIZE = {
    'MOBILE': 600,
    'TABLET': 786,
    'LAPTOP': 1024,
    'LAPTOP_LG': 1400,
    'LAPTOP_XL': 1900,
    'LAPTOP_XXL': 2160,
};

export {SCREEN_SIZE};

export const DOMAIN_EXTENSION = {
    'Greasychipbutty': '.app',
    'barca': '.club',
    'hala-madrid': '.net',
    'die-bayern':'.net',
    'allezparis':'.net',
    'theherons':'.net',
    'the-gulls': '.net',
}

export const SHOPIFY_HEADERS = {
    'Content-Type': 'application/json',
    'X-Shopify-Storefront-Access-Token': 'd1dac322c812662c00098cc60c2a2a7f',
};


export const SHOPIFY = {
    ALL: 'ALL',
    PRODUCT_TAGS: 'PRODUCT_TAGS',
    PRODUCT_BY_TAGS: 'PRODUCT_BY_TAGS',
    PRODUCT_TYPES: 'PRODUCT_TYPES',
    PRODUCT_BY_TYPES: 'PRODUCT_BY_TYPES',
    BEST_SELLING: 'BEST_SELLING',
    CATEGORIES: 'CATEGORIES',
    PRODUCT_LIST: 'PRODUCT_LIST',
    PRODUCT_DETAIL: 'PRODUCT_DETAIL',
    PRODUCT_TAGS_LIST: [
        'Featured',
        'Mens',
        'Womens',
        'Childrens',
        'Legends',
        'Unisex',
        'Phone Case',
        'Cushion',
        'Framed Print'],
    APPLY_DISCOUNT_CODE: 'APPLY_DISCOUNT_CODE',
    REMOVE_DISCOUNT_CODE: 'REMOVE_DISCOUNT_CODE',
    CHECKOUT_CREATE: 'CHECKOUT_CREATE',
    CHECKOUT_LINE_ITEMS_ADD: 'CHECKOUT_LINE_ITEMS_ADD',
    CHECKOUT_LINE_ITEMS_REMOVE: 'CHECKOUT_LINE_ITEMS_REMOVE',
    CHECKOUT_LINE_ITEMS_UPDATE: 'CHECKOUT_LINE_ITEMS_UPDATE',
    GET_SHOPIFY_CART: 'GET_SHOPIFY_CART',
};

export const PRODUCT_SIZES = [
    {shopify_name: 'XS', filter_name: 'XS'},
    {shopify_name: 'S', filter_name: 'S'},
    {shopify_name: 'M', filter_name: 'M'},
    {shopify_name: 'L', filter_name: 'L'},
    {shopify_name: 'XL', filter_name: 'XL'},
    {shopify_name: '2XL', filter_name: '2XL'},
    {shopify_name: '3XL', filter_name: '3XL'},
    {shopify_name: '4XL', filter_name: '4XL'},
    {shopify_name: '5XL', filter_name: '5XL'},
];

export const PRODUCT_COLORS = [
    'Grey',
    'White',
    'LightBlue',
    'Black',
    'LightBlue',
    'Light Blue',
    'Navy',
    'Charcoal',
    'BlueWhite',
    'WhiteLight',
    'Blue',
    'Navy Light Blue',
    'Light Blue Navy',
    'Black Light Blue',
    'Light Blue Navy',
    'Pink',

];

export const PRODUCT_GENDERS = [
    {shopify_name: 'Mens', filter_name: 'Mens'},
    {shopify_name: 'Womens', filter_name: 'Womens'},
    {shopify_name: 'Unisex', filter_name: 'Unisex'},
];

export const COMPARE_CATEGORY = [
    {
        compareName: 'tshirt',
        displayName: 'T Shirts',
    }, {
        compareName: 'T-Shirt',
        displayName: 'T Shirts',
    }, {
        compareName: 't-shirt',
        displayName: 'T Shirts',
    }, {
        compareName: 'Hoodies',
        displayName: 'Hoodies',
    }, {
        compareName: 'hoodies',
        displayName: 'Hoodies',
    }, {
        compareName: 'hoodie',
        displayName: 'Hoodies',
    }, {
        compareName: 'sweatshirts',
        displayName: 'Sweatshirts',
    }, {
        compareName: 'Sweatshirts',
        displayName: 'Sweatshirts',
    }, {
        compareName: 'Sweatshirt',
        displayName: 'Sweatshirts',
    }, {
        compareName: 'sweatshirt',
        displayName: 'Sweatshirts',
    }, {
        compareName: 'Jacket',
        displayName: 'Jackets',
    }, {
        compareName: 'Jackets',
        displayName: 'Jackets',
    }, {
        compareName: 'jacket',
        displayName: 'Jackets',
    }, {
        compareName: 'jackets',
        displayName: 'Jackets',
    }, {
        compareName: 'Polo',
        displayName: 'Polo Shirts',
    }, {
        compareName: 'Polos',
        displayName: 'Polo Shirts',
    }, {
        compareName: 'polo',
        displayName: 'Polo Shirts',
    }, {
        compareName: 'polos',
        displayName: 'Polo Shirts',
    }, {
        compareName: 'Sportswear',
        displayName: 'Sportswear',
    }, {
        compareName: 'sportswear',
        displayName: 'Sportswear',
    }, {
        compareName: 'Shorts',
        displayName: 'Shorts',
    }, {
        compareName: 'shorts',
        displayName: 'Shorts',
    }, {
        compareName: 'Short',
        displayName: 'Shorts',
    }, {
        compareName: 'short',
        displayName: 'Shorts',
    }, {
        compareName: 'hats',
        displayName: 'Hats',
    }, {
        compareName: 'Hats',
        displayName: 'Hats',
    }, {
        compareName: 'hat',
        displayName: 'Hats',
    }, {
        compareName: 'Hat',
        displayName: 'Hats',
    },];

export const MATCH_STATES = {
    IN_PLAY: [2, 9, 10, 11],
    UPCOMING: [3],
    POSTPONED: [4],
    ABANDONED: [5],
    CANCELLED: [6],
    SUSPENDED: [7],
    INTERRUPTED: [8],
    FINISHED: [1],
};

export const CRICKET_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [0],
    FINISHED: [3, 8],
};

export const MLB_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [7],
    FINISHED: [3, 8],
};

export const NBA_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [7],
    FINISHED: [3, 8],
};

export const NCAA_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [7],
    FINISHED: [3, 8],
};

export const NFL_MATCH_STATES = {
    IN_PLAY: [2, 10],
    UPCOMING: [1],
    POSTPONED: [5, 9],
    ABANDONED: [6],
    CANCELLED: [4],
    SUSPENDED: [7],
    INTERRUPTED: [7],
    FINISHED: [3, 8],
};

export const SPORTS = {
    football: 'Football',
    cricket: 'Cricket',
    mlb: 'MLB',
    nba: 'NBA',
    nfl: 'NFL',
};

export const NAVBAR_ITEMS = {
    news: ['football'],
    teams: ['football'],
    matches: ['football', 'nba', 'ncaa', 'mlb', 'cricket', 'nfl'],
    standings: ['football','nba', 'nfl','mlb', /*'cricket', */],
    videos: ['football','nba', 'nfl', 'mlb', 'cricket', ],
    shop: ['football'/*,'nba', 'nfl', 'mlb', 'cricket'*/, ],
};

export const CRICKET_NAVBAR_ITEMS = [
    {
        title: 'All',
        url: '/matches/all',
    }, {
        title: 'ODI',
        url: '/matches/all',
    }, {
        title: 'T20',
        url: '/matches/all',
    }, {
        title: 'Test',
        url: '/matches/all',
    }];
