import React, {useEffect, useMemo, useRef, useState} from 'react';
import {get} from '../../../Axios/get';
import {FOOTBALL} from '../../../Axios/EndPoints';
import SpecificLeagueNews from './SpecificLeagueNews';
import SpecificLeagueVideos from './SpecificLeagueVideos';
import ItemLoader from '../../Loader/ItemLoader';

const SpecificLeagueSection = (props) => {

  const {isMobile} = props;
  const [league, setLeague] = useState([]);
  const [leagueLoading, setLeagueLoading] = useState(true);

  function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
    ), [ref]);

    useEffect(() => {
      observer.observe(ref.current);
      return () => observer.disconnect();
    }, []);

    return isIntersecting;
  }

  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  const fetchLeagues = () => {
    // let parameters = '?league_id=1204&limit=15';

    get(FOOTBALL.AllLeaguesEndPoint).then((response) => {
      setLeague(response.data?.leagues);
      setLeagueLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  useEffect(() => {
    console.log('isVisible &&  league?.length === 0 =',
        isVisible && league?.length === 0, isVisible);
    isVisible && league?.length === 0 && fetchLeagues();
  }, [isVisible]);

  const renderSpecificLeagueVideos = (data) => {
    return <SpecificLeagueVideos isMobile={isMobile} leagueData={data}/>;
  };

  const renderSpecificLeagueNews = (data) => {
    return <SpecificLeagueNews isMobile={isMobile} leagueData={data}/>;
  };

  const renderSpecificLeagueSectionMain = () => {

    return (
        <div ref={ref}>
          {leagueLoading ?
              <ItemLoader loading={leagueLoading}/>
              :
              league?.length > 0 && league?.map((leagueValue, leagueIndex) => (
                      <div key={leagueIndex}>

                        {renderSpecificLeagueVideos(leagueValue)}
                        {renderSpecificLeagueNews(leagueValue)}
                      </div>
                  ),
              )
          }
        </div>
    );

  };

  return renderSpecificLeagueSectionMain();

};

export default SpecificLeagueSection;
