import {get} from '../../../Axios/get';
import {useEffect, useState} from 'react';
import {FANZINE_SPORTS} from '../../../Axios/EndPoints';
import {Button} from 'react-bootstrap';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import {getHostname} from "../../../CustomFunctions/getHostname";
import FanzineSportsHighlightsCard from "../FanzineSportsVideoCard/FanzineSportsHighlightsCard";
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

const FanzineSportsVideosOnSlider = (props) => {
    const {isMobile, title ,endpoint,isTypeGoal} = props;
    const [latestVideos, setLatestVideos] = useState([])
    const [latestVideosLoading, setLatestVideosLoading] = useState(true)
    const location = useLocation();
    const LoginStore = useSelector(state => state);

    const fetchHighlights = () => {
        get(endpoint ? endpoint : FANZINE_SPORTS.HIGHLIGHTS).then((response) => {
            // eslint-disable-next-line array-callback-return
            let tempData = response.data;
            // tempData.sort(function (a, b) {
            //   let keyA = new Date(a.published_at.date),
            //       keyB = new Date(b.published_at.date);
            //   // Compare the 2 dates
            //   if (keyA < keyB) return 1;
            //   if (keyA > keyB) return -1;
            //   return 0;
            // });
            setLatestVideos(tempData);
            setLatestVideosLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };


    useEffect(() => {
        setLatestVideosLoading(true);
      LoginStore?.fanzineSports?.isLogin && fetchHighlights()

      return(()=>{
        setLatestVideosLoading(true);
        setLatestVideos(null);

      })

    }, [location.pathname,endpoint,LoginStore])


    const renderLeftButton = (id) => {

        let style =
            {
                width: '63px',
                height: '68px',
                backgroundColor: '#fff',
                color: 'black',
                border: '0px',
                borderRadius: '0px',
            };

        const handleLeftClick = () => {
            document.getElementById('videoSliderContainer' + id).scrollLeft -= 400;

        };

        return (
            <div
                style={{
                    marginLeft: '-3em',
                    marginRight: '-2em',
                    zIndex: '1',
                    marginTop: '-4em',
                }}
                onClick={handleLeftClick}
            >
                <Button className={''} style={style}>
                    <AiOutlineLeft/>
                </Button>
            </div>
        );
    };

    const renderRightButton = (id) => {

        let style =
            {
                width: '63px',
                height: '68px',
                backgroundColor: '#fff',
                color: 'black',
                border: '0px',
                borderRadius: '0px',
            };

        const handleRightClick = () => {
            document.getElementById('videoSliderContainer' + id).scrollLeft += 400;
        };

        return (
            <div
                onClick={handleRightClick}
                style={{
                    marginLeft: '-3em',
                    zIndex: '1',
                    marginTop: '-4em',

                }}
            >
                <Button
                    style={style}
                > <AiOutlineRight/>
                </Button>
            </div>
        );
    };

    const renderVideos = (videoData, title) => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        let style = {
            background: '#000000',
        };

        return (
            <div className={`w-100 text-white`}
                 style={style}
            >
                <div className={'container'}>
                    <div className={`${isMobile ? 'w-100' : 'container'}`}>
                        <div className={`row ${isMobile ? '' : 'ps-5 pe-5'}`}>
                            <div
                                className={'position-absolute mt-4 text-white sfProDisplayFontBold font-20'}
                            >
                                <img src={title === 'Match Highlights' ?
                                    require('../../../assets/images/v2/playButton.png')
                                    : require(
                                    '../../../assets/images/fanzineSports/Component 369 – 8.png')}
                                     alt={'icon'} height={title === 'Match Highlights' ? 16 : 20} width={ title === 'Match Highlights' ? 24 : 20}
                                     className={'me-2'}/>
                                {title}
                            </div>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                {!isMobile && renderLeftButton(videoData[0]?.id)}
                                <div
                                    id={'videoSliderContainer'}
                                    className={`m-1 slider-mobile-container ${videoData?.length <= 3 ? 'justify-content-center' : 'overflow-scroll-x'}`}>
                                    {videoData?.map((highlight) => {
                                      return (
                                          <FanzineSportsHighlightsCard
                                              isTypeGoal={isTypeGoal}
                                              isFanzineSportsNews={true}
                                              videoHeight={'101'}
                                              extraClass={'rounded-custom mt-5 pt-3'}
                                              video={highlight}
                                              isMobile={isMobile}/>
                                      );
                                    },)}
                                </div>
                                {!isMobile && renderRightButton(videoData[0]?.id)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderMain = () => {

      console.log('latest videos =', latestVideos)

        return latestVideos?.length > 0 && renderVideos(latestVideos, title);

    };

    return renderMain();

}

export default FanzineSportsVideosOnSlider;
