import {Card} from 'react-bootstrap';
import {NavLink, useLocation} from 'react-router-dom';
import {FONT_COLOR} from '../../../Configs/Targets';
import {getHostname} from '../../../CustomFunctions/getHostname';

const GossipCard = (props) => {
    const {
        index,
        gossip,
        imageHeight,
        cardTextSize,
        isNewsPage,
        publisherIconSize,
        isMain,
        newsOnSlider,
        isMobile,
        showNewsBelow,
        fontColor,
    } = props;

    const location = useLocation();

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    const gossipOverImage = (gossip, publisherIconSize, index) => {
        return (
            <div className={'p-3'}>
                <span
                    className={`sfProDisplayFont ${'font-20'}`}
                    style={{
                        lineHeight: '28px',
                        fontWeight: 500,
                    }}
                    dangerouslySetInnerHTML={{__html: gossip && gossip?.text}}
                />
                <br/>
                <span
                    className={`robotoCondensedBold font-20 ${index % 2 === 0 ? 'text-white' : fontColor}`}
                    dangerouslySetInnerHTML={{
                        __html: gossip && gossip?.url_name,
                    }}
                />
            </div>);
    };

    const renderCard = (gossip, imageHeight, cardTextSize, isNewsPage, publisherIconSize, isMain, index) => {

        return (
            <>
                <Card
                    className={`${'slider-mobile-match-card'} col ${index % 2 === 0 ? 'bg-transparent' : 'bg-white'}`}
                    style={{
                        borderRadius: 0,
                        width: '400px',
                        height: '250px',
                        border: '1px solid white',
                    }}
                >
                    {
                        <Card.Text
                            className={`${index % 2 === 0 ? 'text-white' : fontColor} text-start robotoCondensedBold text-wrap font-12 w-100`}
                        >
                            {(isNewsPage && isMobile) || showNewsBelow ?
                                null :
                                gossipOverImage(gossip, publisherIconSize, index)}
                        </Card.Text>
                    }
                </Card>
                {/*{(isNewsPage && isMobile) || showNewsBelow ? gossipBelowImage(gossip, publisherIconSize) : null}*/}
            </>
        );
    };

    const renderSnack = (
        gossip, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize,
        isMobile, textColor, isMain) => {

        const sport = location.pathname.split('/')[1];

        let link = gossip?.url;

        return (
            <NavLink to={link} className={'text-decoration-none text-dark'}>
                {isMobile ?
                    renderCard(gossip, mainImageStyle, cardTextSize, isNewsPage,
                        publisherIconSize, isMain) :
                    renderCard(gossip, mainImageStyle, cardTextSize, isNewsPage,
                        publisherIconSize, isMain)}
            </NavLink>
        );
    };

    const renderNewsNow = (
        news, imageHeight, cardTextSize, isNewsPage, publisherIconSize, isMobile,
        textColor, isMain, index) => {
        const sport = location.pathname.split('/')[1] || 'football';

        let link = '';
        if (typeof news.main_team !== 'undefined' && news.main_team) {
            link = `/${sport}/${news.main_team}/${news.slug}`;
        } else {
            link = `/${sport}/news/${news.slug}`;
        }
        return (
            <a
                href={news.url}
                className={'text-decoration-none text-dark'}
                target="_blank"
                rel="noreferrer"
            >
                {renderCard(news, imageHeight, cardTextSize, isNewsPage, publisherIconSize, isMain, index)}
            </a>
        );
    };

    return (
        <>
            {!gossip ?
                renderSnack(gossip, imageHeight, 'cardTextSize', isNewsPage,
                    publisherIconSize, 'isMobile', 'textColor', isMain) :
                renderNewsNow(gossip, imageHeight, 'cardTextSize', isNewsPage,
                    publisherIconSize, 'isMobile', 'textColor', isMain, index)}
        </>);

    // return renderCard();
};

export default GossipCard;
