import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import {Col, Image, NavDropdown, NavItem, Row} from "react-bootstrap";
import {NAVBAR_ITEMS} from "../../Configs/Constants";

const SingleSportNavItem = (props) => {

    const {isMobile, fontColor, sport, topNavBarDropdown, index, handleCollapse, renderMore, renderWithoutDropdown, selectedSport, setSelectedSport, handleMainCollapse, handleMainNavClick,fanzineTeamsLeagues} = props;

    const [show, setShow] = useState(false);

    const [isNcaaf,setIsCnaaf] = useState(true);


    const handleClick = (id) => {
        if (typeof setSelectedSport !== 'undefined') {
            setSelectedSport(sport);
        }
        handleCollapse();
        handleMainNavClick(id);
    }

    const renderSportWithoutDropdown = () => {
        return (
            <NavItem
                key={index}
                id={`top_menu_dropdown_${index}`}
                className={`d-flex flex-column justify-content-center mt-2 ${isMobile ?
                    'ps-4 pt-3' :
                    'ps-3 pe-3'}`}>
                <NavLink to={`/${sport.name.toLowerCase()}`}
                         className={`${fontColor} text-decoration-none ${isMobile ?
                             'bebasFont' :
                             'sfProDisplayFontBold font-16'}`}
                         onClick={handleMainCollapse}>
                    {sport.name}
                </NavLink>
            </NavItem>
        );
    }

    const renderOther = () => {
        return (
            <NavItem
                key={index}
                className={`d-flex flex-column justify-content-center mt-2 dropdown-item text-white text-decoration-none font-14 justify-content-center`}>
                <NavLink to={`/${sport.name.toLowerCase()}`}
                         className={`dropdown-item text-white text-decoration-none ${isMobile ?
                             'sfProDisplayFont font-16' :
                             'sfProDisplayFontBold font-16'} font-14 justify-content-center `}
                         id={`top_menu_dropdown_${sport.name.toLowerCase()}`}
                         onClick={() => {
                             handleClick(`top_menu_dropdown_${sport.name.toLowerCase()}`)
                         }}
                >
                    <div
                        className={'d-flex flex-row justify-content-center border-bottom border-gray border-opacity-50 pb-3 font-weight-600'}
                        style={{color: fontColor || 'black'}}
                    >
                        <Image
                            src={sport.icon}
                            alt={sport.name}
                            height={25}
                            className={'pe-2'}
                        />
                        {" w" + sport.name}
                    </div>
                </NavLink>
            </NavItem>
        );
    }

    const renderNcaa = () => {
        if (sport.leagues.length) {
            return sport.leagues.map(
                (item, index) => {
                    return (
                        <Col key={index}
                             md={12}
                             className={`text-left text-white d-flex flex-column justify-content-start p-0`}>
                            <NavItem
                                className={`d-flex flex-column justify-content-center mt-0 dropdown-item text-white text-decoration-none font-14`}>
                                <div
                                    // to={item.link}
                                    className={`dropdown-item text-white text-decoration-none${isMobile ?
                                        'sfProDisplayFont font-16' :
                                        'sfProDisplayFontBold font-16'} font-14 d-flex flex-column justify-content-center `}
                                    onClick={handleCollapse}>
                                    <div
                                        className={'text-nowrap text-start sfProDisplayFontBold'}
                                        style={{
                                            zIndex: 1,
                                            padding: '8px 0 8px 0px',
                                            color: 'black',
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                </div>
                            </NavItem>
                            <Row xs={'4'}
                                 className={`text-left text-white justify-content-start p-0 `}>
                                {
                                    item.teams.map((team) => (
                                        <Col md={3}>
                                            <NavItem
                                                className={`justify-content-center mt-1 dropdown-item text-white text-decoration-none font-14 justify-content-center col-3`}>
                                                <NavLink to={team.link}
                                                         className={`dropdown-item text-white text-decoration-none sfProDisplayFont font-16 h-100 d-flex flex-column justify-content-center`}
                                                         onClick={handleCollapse}>
                                                    <div
                                                        className={'justify-content-start align-middle d-flex h-100'}
                                                        style={{color: '#737373'}}
                                                    >
                                                        <Image
                                                            src={team.icon}
                                                            width={25}
                                                            height={25}
                                                            style={{objectFit: 'contain'}}
                                                        />
                                                        <div className={'px-1'}>{team.name}</div>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        </Col>
                                    ))
                                }
                            </Row>
                            <hr style={{color: '#bdbdbd', marginLeft: '2.5%', width: '95%'}}/>
                        </Col>
                    );
                });
        }
        return (<></>);
    }

    const render = () => {
        return (
            <NavDropdown className={` ${isMobile ? 'pt-2' : 'pt-2 ps-3 pe-3'}`}
                         title={<span className={`${fontColor} ${isMobile ?
                             'bebasFont ps-4 pt-2' :
                             'sfProDisplayFontBold font-16'}`}>{sport.name === 'NCAAM' ? 'NCAA' : sport.name}</span>}
                         id={`top_menu_dropdown_${index}`}
                         key={`top_menu_dropdown_${index}`}
                         renderMenuOnMount={true}
                         style={topNavBarDropdown}
                         onClick={() => {
                             !['NCAAM'].includes(sport.name) && handleClick(`top_menu_dropdown_${index}`)
                         }}
            >
                {/*first change here*/}
                <div className="nav-mega-menu-fanzine-1 pb-5 bg-black container shadow bg-white" style={{zIndex: 1100}}>
                    <div className="container ">
                      {sport.name === 'NCAAM' && <div className={'row '}>
                        <div
                            className={'col-6 ms-auto me-auto d-flex justify-content-evenly'}>
                          <div
                              onClick={() => setIsCnaaf(true)}
                              className={'d-flex justify-content-center cursor-pointer'}>
                            <div
                                style={{
                                  borderBottom: isNcaaf && '5px solid black',
                                  color: isNcaaf ? '#000' : '#787878',
                                }}
                            >
                              <p
                                  className={isNcaaf ?
                                      'sfProDisplayFontBold' :
                                      'sfProDisplayFont'}
                              >
                                NCAAF
                              </p>
                            </div>
                          </div>
                          <div
                              onClick={() => setIsCnaaf(false)}
                              className={'d-flex justify-content-center cursor-pointer'}>
                            <div
                                style={{
                                  borderBottom: !isNcaaf && '5px solid black',
                                  color: !isNcaaf ? '#000' : '#787878',
                                }}
                            >
                              <p
                                  className={!isNcaaf ?
                                      'sfProDisplayFontBold' :
                                      'sfProDisplayFont'}
                              >
                                NCAAM
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      }                        <div className="row">
                            <div className={`col-2`}>
                                <NavItem
                                    className={`d-flex flex-column justify-content-center mt-2 dropdown-item text-white text-decoration-none font-14 justify-content-center`}>
                                    <NavLink to={`/${sport.name.toLowerCase()}`}
                                             className={`dropdown-item text-white text-decoration-none${isMobile ?
                                                 'sfProDisplayFont font-16' :
                                                 'sfProDisplayFontBold font-16'} font-14 justify-content-center `}
                                             onClick={handleCollapse}>
                                        <div
                                            className={'flex-row  justify-content-start align-middle border-bottom border-gray border-opacity-50 pb-3 font-weight-600'}
                                            style={{color: 'black'}}
                                        >
                                            Home
                                        </div>
                                    </NavLink>
                                </NavItem>
                                {
                                    NAVBAR_ITEMS.news.includes(sport.name.toLowerCase()) ?
                                        <NavItem
                                            className={`d-flex flex-column justify-content-center mt-2 dropdown-item text-white text-decoration-none font-14 justify-content-center `}>
                                            <NavLink to={`/${sport.name.toLowerCase()}/news`}
                                                     className={`dropdown-item text-white text-decoration-none${isMobile ?
                                                         'sfProDisplayFont font-16' :
                                                         'sfProDisplayFontBold font-16'} font-14 justify-content-center `}
                                                     onClick={handleCollapse}>
                                                <div
                                                    className={'flex-row  justify-content-start align-middle border-bottom border-gray border-opacity-50 pb-3 font-weight-600'}
                                                    style={{color: 'black'}}
                                                >
                                                    News
                                                </div>
                                            </NavLink>
                                        </NavItem> : null
                                }
                                {
                                    NAVBAR_ITEMS.teams.includes(sport.name.toLowerCase()) ?
                                        <NavItem
                                            className={`d-flex flex-column justify-content-center mt-2 dropdown-item text-white text-decoration-none font-14 justify-content-center `}>
                                            <NavLink to={`/${sport.name.toLowerCase()}/teams`}
                                                     className={`dropdown-item text-white text-decoration-none${isMobile ?
                                                         'sfProDisplayFont font-16' :
                                                         'sfProDisplayFontBold font-16'} font-14 justify-content-center `}
                                                     onClick={handleCollapse}>
                                                <div
                                                    className={'flex-row  justify-content-start align-middle border-bottom border-gray border-opacity-50 pb-3 font-weight-600'}
                                                    style={{color: 'black'}}
                                                >
                                                    Teams
                                                </div>
                                            </NavLink>
                                        </NavItem> : null
                                }
                                {
                                    NAVBAR_ITEMS.matches.includes(sport.name.toLowerCase()) ?
                                        <NavItem
                                            className={`d-flex flex-column justify-content-center mt-2 dropdown-item text-white text-decoration-none font-14 justify-content-center `}>
                                            <NavLink to={`/${sport.name.toLowerCase()}/matches`}
                                                     className={`dropdown-item text-white text-decoration-none${isMobile ?
                                                         'sfProDisplayFont font-16' :
                                                         'sfProDisplayFontBold font-16'} font-14 justify-content-center `}
                                                     onClick={handleCollapse}>
                                                <div
                                                    className={'flex-row  justify-content-start align-middle border-bottom border-gray border-opacity-50 pb-3 font-weight-600'}
                                                    style={{color: 'black'}}
                                                >
                                                    Matches
                                                </div>
                                            </NavLink>
                                        </NavItem> : null
                                }
                                {
                                    NAVBAR_ITEMS.standings.includes(sport.name.toLowerCase()) ?
                                        <NavItem
                                            className={`d-flex flex-column justify-content-center mt-2 dropdown-item text-white text-decoration-none font-14 justify-content-center `}>
                                            <NavLink to={`/${sport.name.toLowerCase()}/table`}
                                                     className={`dropdown-item text-white text-decoration-none${isMobile ?
                                                         'sfProDisplayFont font-16' :
                                                         'sfProDisplayFontBold font-16'} font-14 justify-content-center `}
                                                     onClick={handleCollapse}>
                                                <div
                                                    className={'flex-row  justify-content-start align-middle border-bottom border-gray border-opacity-50 pb-3 font-weight-600'}
                                                    style={{color: 'black'}}
                                                >
                                                  {sport?.name?.toLowerCase() === 'football' ? 'Table' : 'Standings'}
                                                </div>
                                            </NavLink>
                                        </NavItem> : null
                                }
                                {
                                    NAVBAR_ITEMS.videos.includes(sport.name.toLowerCase()) ?
                                        <NavItem
                                            className={`d-flex flex-column justify-content-center mt-2 dropdown-item text-white text-decoration-none font-14 justify-content-center `}>
                                            <NavLink to={`/${sport.name.toLowerCase()}/video`}
                                                     className={`dropdown-item text-white text-decoration-none${isMobile ?
                                                         'sfProDisplayFont font-16' :
                                                         'sfProDisplayFontBold font-16'} font-14 justify-content-center `}
                                                     onClick={handleCollapse}>
                                                <div
                                                    className={'flex-row  justify-content-start align-middle border-bottom border-gray border-opacity-50 pb-3 font-weight-600'}
                                                    style={{color: 'black'}}
                                                >
                                                  {'Videos'}
                                                </div>
                                            </NavLink>
                                        </NavItem> : null
                                }
                            </div>
                            <div className="col">
                                <Row className={`p-0 m-0`} style={{backgroundColor: '#f1f2f3'}}>
                                    {

                                        // ['ncaa', 'ncaaf', 'ncaam'].includes(sport.name.toLowerCase()) ? renderNcaa() :
                                            sport.leagues.length &&
                                            ((fanzineTeamsLeagues?.length && sport.name ==='NCAAM') ? isNcaaf ? fanzineTeamsLeagues[5]?.leagues : fanzineTeamsLeagues[6]?.leagues : sport.leagues).map(
                                                (item, index) => {
                                                    return (
                                                        <Col key={index}
                                                             // md={item.name === 'Premier League' ? 9 : item.name === 'Top European Clubs' ? 3 : 4}
                                                             md={12}
                                                             className={`text-left text-white d-flex flex-column justify-content-start p-0`}>
                                                          {index !== 0 && <div
                                                              className={'ms-auto me-auto mt-4'}
                                                              style={{
                                                            width: '723px',
                                                            height: '0',
                                                            // margin: '7.5px 0 0 127px',
                                                            border: 'solid 0.5px #bdbdbd',
                                                          }}
                                                          ></div>}
                                                          <NavItem
                                                                className={`d-flex flex-column justify-content-center mt-2 dropdown-item text-white text-decoration-none font-14`}>
                                                                <div
                                                                    // to={item.link}
                                                                    className={`dropdown-item text-white text-decoration-none${isMobile ?
                                                                        'sfProDisplayFont font-16' :
                                                                        'sfProDisplayFontBold font-16'} font-14 d-flex flex-column justify-content-center `}
                                                                    onClick={handleCollapse}>
                                                                    <div
                                                                        className={'text-nowrap text-start sfProDisplayFontBold cursor-pointer'}
                                                                        style={{
                                                                            // marginTop: '-2vh',
                                                                            zIndex: 1,
                                                                            padding: '8px 0 8px 0px',
                                                                            color: 'black',
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </div>
                                                                </div>
                                                            </NavItem>
                                                            <Row
                                                                 className={`text-left text-white justify-content-start p-0 `}>
                                                                {
                                                                    item.teams.map((team,teamIndex) => (
                                                                        <Col
                                                                            key={teamIndex}
                                                                            // md={item.name === 'Premier League' ? 4 : item.name === 'Top European Clubs' ? 12 : 12}
                                                                            md={3}
                                                                        >
                                                                            <NavItem
                                                                                className={`justify-content-center mt-2 dropdown-item text-white text-decoration-none font-14 justify-content-center col-3`}>
                                                                                <NavLink to={team.link.includes('/womens') ? `/football/${team.link?.split('/')[2]}` : team.link}
                                                                                         className={`dropdown-item text-white text-decoration-none sfProDisplayFont font-16 h-100 d-flex flex-column justify-content-center`}
                                                                                         onClick={handleCollapse}>
                                                                                    <div
                                                                                        className={'justify-content-start align-middle d-flex h-100'}
                                                                                        style={{color: '#737373'}}
                                                                                    >
                                                                                        <Image
                                                                                            src={team.icon}
                                                                                            width={25}
                                                                                            height={25}
                                                                                            style={{objectFit: 'contain'}}
                                                                                        />
                                                                                        <div className={'px-1'}>{team.name}</div>
                                                                                    </div>
                                                                                </NavLink>
                                                                            </NavItem>
                                                                        </Col>
                                                                    ))
                                                                }
                                                            </Row>
                                                        </Col>
                                                    );
                                                })
                                    }
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </NavDropdown>
        );
    }

    const renderMain = () => {
        if (renderMore) {
            return renderOther();
        }
        if (renderWithoutDropdown) {
            return renderSportWithoutDropdown();
        }

        return render();
    }

    return renderMain();
}

export default SingleSportNavItem;
