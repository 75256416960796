 import axios from "axios";
import { getHostname } from "../CustomFunctions/getHostname";
 import {DOMAIN_EXTENSION} from '../Configs/Constants';

export const get = (endPoint, data, encrypted, token) => {
  let API = "";

  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target =  domainObject.host;
  const href = window.location.host;


  // console.log('domain -', DOMAIN_EXTENSION[target])

  let forLocalhost =  `api2.${target}${DOMAIN_EXTENSION[target] ? DOMAIN_EXTENSION[target] : '.com'}`;
  // forLocalhost = `localhost:8001`;
  API = href
    .replace("www.", "api2.")
    .replace("new.", "api2.")
    .replace("rjs.", "api2.")
    .replace("matches.", "api2.")
    .replace("localhost:3000", forLocalhost)
    .replace("localhost:3001", forLocalhost)
    .replace("127.0.0.1:3000", forLocalhost)
    .replace("127.0.0.1:3001", forLocalhost)
    .replace("192.168.100.6:3000", forLocalhost)
    .replace("192.168.100.5:3000", forLocalhost)
    .replace("192.168.100.12:3000", forLocalhost)
    .replace("192.168.100.58:3000", forLocalhost)
    .replace("192.168.31.183:3000", forLocalhost)
    .replace("192.168.31.226:3000", forLocalhost)
    .replace("192.168.31.22:3000", forLocalhost)
    .replace("localhost:8001", forLocalhost)
    .replace("127.0.0.1:8001", forLocalhost)
      .replace("192.168.31.23:3000", forLocalhost)

  let headers = { Accept: "*/*" };
  let certificate = "https://";
  if (forLocalhost === "localhost:8001") {
    certificate = "http://";
  }

  let url = certificate + API + endPoint;
  // console.log('href: ', href,  API, url);
  return axios.get(url, { headers });
};
