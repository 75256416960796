import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {COLORS, COLORS_RGBA, FONT_COLOR} from '../../../Configs/Targets';
import Slider from "react-slick";
import '../../../assets/styles/styles.css';
import '../../../assets/styles/shop.css';
import ShopCategoryCard from "./ShopCategoryCard";
import {NavLink, useLoaderData, useLocation} from 'react-router-dom';
import {graphql} from "../../../Axios/graphql";
import {ShopifyGraphQlQueries} from "../../../Configs/ShopifyGraphQlQueries";
import {useDispatch, useSelector} from "react-redux";
import {Banner} from "../../../Components/ClubShop/Banner";
import {getHostname} from "../../../CustomFunctions/getHostname";
import {SCREEN_SIZE} from "../../../Configs/Constants";
import Shop from "../../Shop";
import {
  setBannerImages, setCategories,
  setContainerImages, setProductColors,
  setRandomProducts,
} from '../../../redux/actions/StoreActions';
import {
  CampaignEndPoint, ProductColors,
  ShopImagesEndpoint,
  StoreCategories,
} from '../../../Axios/EndPoints';
import {get} from '../../../Axios/get';
import CampaignPopup from "../../../Components/Campaigns/CampaignPopup";
import ShopItemCarousel from "../../../Components/Carousel/ShopItemCarousel";
import axios from 'axios';

const ShopMobilePage = () => {

  const store = useSelector(state => state);
  const MiscStore = useSelector(state => state.misc);

  const dispatch = useDispatch();
  const location = useLocation();

  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;
  const textColor = FONT_COLOR[target];
  const bgColor = COLORS[target];


  // let [categories, setCategories] = useState(store.shopify.categories);
  const [bestSelling, setBestSelling] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [campaignModalShow, setCampaignModalShow] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);


  const fetchShopImages = () => {
    const ReplacedTarget = location.pathname.split('/')[1];

    const URL = `https://api2.${ReplacedTarget}.com` + ShopImagesEndpoint;

    console.log('url -', URL);

    axios.get(URL).then((response) => {
      if (response.data.success) {
        dispatch(setContainerImages(response.data.data.containerImages));
        dispatch(setBannerImages(response.data.data.bannerImages));
      }
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchCategories = () => {

    const ReplacedTarget = location.pathname.split('/')[1];

    const URL = `https://api2.${ReplacedTarget}.com` + StoreCategories;

    console.log('url -', URL);

    axios.get(URL)
        .then((res) => {
          dispatch(setCategories(res.data));
        })
        .catch((error) => {
          console.log('error getting categories in shopDesktop =', error);
        });
  };

  const fetchProductColors = () => {

    const ReplacedTarget = location.pathname.split('/')[1];

    const URL = `https://api2.${ReplacedTarget}.com` + ProductColors;

    console.log('url -', URL);

    axios.get(URL)
        .then((res) => {
          let tempColors = {};
          res.data.map((value) => (tempColors[value.name] = value.hex));
          dispatch(setProductColors(tempColors));
        })
        .catch((error) => {
          console.log('error getting categories in shopDesktop =', error);
        });

  };

  const fetchBestSelling = () => {
    graphql(ShopifyGraphQlQueries('BEST_SELLING','BEST_SELLING'))
        .then((response) => {
          let products = response.data.data['collectionByHandle']['products'].edges;
          if (products.length) {
            let tempBestSelling = [];
            products.map((value) => {
              let product = value.node;
              tempBestSelling.push({
                id: product.id,
                name: product.title,
                src: product.images && product.images.edges[0].node.src,
                alt: product.images && product.images.edges[0].node.alt,
                handle: product.handle,
                price: product['variants'].edges[0].node.price,
                oldPrice: product['variants'].edges[0].node.compareAtPrice,
              });
              return tempBestSelling;
            });
            setBestSelling(tempBestSelling);
            dispatch(setRandomProducts(tempBestSelling));
          }
        });
  }

  const fetchCampaigns = () => {
    get(CampaignEndPoint)
        .then((response) => {
          let campaignData = response.data.data;
          console.log('campaign', campaignData.length);
          let tempCampaign = [];
          let campaign = null;
          if (campaignData.length) {
            campaignData.map((data) => {
              campaign = {
                id: data.id,
                campaign_id: data.campaign_id,
                type: data.type,
                leagueCode: data.league_code,
                image: data.image,
                title: data.title,
                description: data.description,
                questions: data.questions,
              };
              if (data.display_page === 'shop') {
                tempCampaign.push(campaign);
              }
            });
          }
          setCampaign(tempCampaign);
          setCampaignModalShow(true);
        })
        .catch((err) => {
          console.log('campaign_err', err);
        })
  }

  useEffect(() => {
    fetchCampaigns();
    fetchBestSelling();
    if (target === 'fanzine') {
      fetchShopImages()
      fetchCategories();
      fetchProductColors();
    }

  }, []);
  useEffect(() => {
    setScreenWidth(window.innerWidth);
    if (screenWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    }
  }, [screenWidth]);

  const render = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const color = COLORS[target];
    const colorRgba = COLORS_RGBA[target];

    const topBannerStyle = {
      width: '100%',
      height: '144px',
      background: `linear-gradient(to bottom, ${colorRgba}0.71) 45%, ${colorRgba}1)), url(${require('../../../assets/images/shop_top_banner.png')})`,
      backgroundSize: 'cover'
    };

    const categories = store.shopify.categories;

    const renderMobileShopView = () =>
    {
      return(
          <div className={`container-fluid`}>
            <div className={`d-inline-flex flex-row mt-3`}>
              <ShopCategoryCard name={store.shopify.bannerImages && store.shopify.bannerImages.label_mens ? store.shopify.bannerImages.label_mens : `Mens`} link={target === 'fanzine' ? 'shop/Mens' :  `Mens`}
                                src={store.shopify.bannerImages && store.shopify.bannerImages.mobile_image_mens ? store.shopify.bannerImages.mobile_image_mens : require("../../../assets/images/men_shop_category_gradient_3.png")}
                                mainCategory={true}/>
              <ShopCategoryCard name={store.shopify.bannerImages && store.shopify.bannerImages.label_womens ? store.shopify.bannerImages.label_womens : `Womens`} link={target === 'fanzine' ? 'shop/Womens' : `Womens`}
                                src={store.shopify.bannerImages && store.shopify.bannerImages.mobile_image_womens ? store.shopify.bannerImages.mobile_image_womens : require("../../../assets/images/women_shop_category_gradient_3.png")}
                                mainCategory={true}/>
              <ShopCategoryCard name={store.shopify.bannerImages && store.shopify.bannerImages.label_juniors ? store.shopify.bannerImages.label_juniors : `Juniors`} link={target === 'fanzine' ? 'shop/Children' : `Children`}
                                src={store.shopify.bannerImages && store.shopify.bannerImages.mobile_image_juniors ? store.shopify.bannerImages.mobile_image_juniors : require("../../../assets/images/kids_shop_category_gradient_3.png")}
                                mainCategory={true}/>
            </div>
          </div>
      )
    }

    const renderMobileShopItemCarousel = () =>
    {
      return(
          <div className={'container mt-3'}>
            <span className={`bebasFont h1 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0`}>Best Sellers</span>
            <ShopItemCarousel images={bestSelling}
                              showDetail={true}
                              showColors={false}
                              showPriceFirst={true}
                              priceColor={COLORS[target]}

            />
          </div>
      )
    }

    return (<>
      <Banner breadcrums={false}/>

      <CampaignPopup
          show={campaignModalShow}
          onHide={() => setCampaignModalShow(false)}
          campaign={campaign}
      />

      <div className={`container ${isMobile ? 'mt-5 pt-3' : 'mt-5'}`}>
        {!isMobile ? (<Slider>
          <div key={'shop_top_image_div'} className={'slide'}>
            <h2 className="slick-slide-title-shop">
              <div className={`row`}>
                <div className={`col`}/>
                <div className={`col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12`}>
                  <div className="d-grid gap-2">
                    <div className="input-group mb-3 rounded-custom-1">
                                                <span className="input-group-text bg-grey border-0 rounded-custom-1"
                                                      id="basic-addon1">
                                                    <FontAwesomeIcon icon={faSearch} className={'text-light-gray'}/>
                                                </span>
                      <input type="text"
                             className="form-control bg-grey-input border-0 rounded-custom-1 text-light-gray placeholder-gray p-2 sfProDisplayFont"
                             placeholder="Search for items"
                             aria-label="Search" aria-describedby="basic-addon1"/>
                    </div>
                  </div>
                </div>
                <div className={`col`}/>
              </div>
            </h2>
            <img src={store.shopify.bannerImages && store.shopify.bannerImages.web_image ? store.shopify.bannerImages.web_image : require('../../../assets/images/shop_top_image.png')}
                 alt={'Fanzine'}
                 className={`img-fluid d-none d-xxl-block d-xl-block d-lg-block d-md-block`}/>
            <img src={require('../../../assets/images/shop_top_image_mobile.png')} alt={'Fanzine'}
                 className={`img-fluid d-block d-xxl-none d-xl-none d-lg-none d-md-none pb-5`}/>
            <img src={require('../../../assets/images/shop_top_image_mobile2.png')} alt={'Fanzine'}
                 className={`img-fluid d-block d-xxl-none d-xl-none d-lg-none d-md-none pt-5 mt-5`}/>
            <div className="slick-slide-label-shop h1">
              <div className={`container`}>
                <div className={`row`}>
                  <div className={`col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12`}>
                    <div className="d-grid w-100">
                      <NavLink to={'/shop/Mens'}
                               className={'btn bg-white btn-lg text-dark h1 sfProDisplayFontBold'}>
                        {store.shopify.bannerImages && store.shopify.bannerImages.label_mens ? store.shopify.bannerImages.label_mens : `Mens`}
                      </NavLink>
                    </div>
                  </div>
                  <div className={`col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12`}>
                    <div className="d-grid w-100">
                      <NavLink to={'/shop/Womens'}
                               className={'btn bg-white btn-lg text-dark h1 sfProDisplayFontBold'}>
                        {store.shopify.bannerImages && store.shopify.bannerImages.label_womens ? store.shopify.bannerImages.label_womens : `Womens`}
                      </NavLink>
                    </div>
                  </div>
                  <div className={`col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12`}>
                    <div className="d-grid w-100">
                      <NavLink to={'/shop/Childrens'}
                               className={'btn bg-white btn-lg text-dark h1 sfProDisplayFontBold'}>
                        {store.shopify.bannerImages && store.shopify.bannerImages.label_juniors ? store.shopify.bannerImages.label_juniors : `Juniors`}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>) : (
            <div className="row mt-2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 mt-4">
                <Shop/>
              </div>
            </div>
        )}
      </div>

      {isMobile ? (
          renderMobileShopView()
      ) : (<></>)}

      {!isMobile && <div className={'container mt-3'}>
        <span className={`bebasFont h1 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0`}>Best Sellers</span>
        <ShopItemCarousel images={bestSelling}
                          showDetail={true}
                          showColors={false}
                          showPriceFirst={true}
                          priceColor={COLORS[target]}

        />
      </div>}

      <div className={'container mt-3'}>
        <span className={`bebasFont h1 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0`}>Categories</span>
        <div className={`row`}>
          {categories && categories.map((value => (<ShopCategoryCard name={value.name} link={target === 'fanzine' ? `shop/${value.shopify_name}`  : value.shopify_name} src={value.image}/>)))}
        </div>
      </div>

      {isMobile && renderMobileShopItemCarousel()}
    </>);
  }

  return render();
}

export default ShopMobilePage;
