import React, {useEffect, useMemo, useRef, useState} from 'react';
import {withRouter} from '../../CustomFunctions/withRouter';
import {get} from '../../Axios/get';
import {Card, Container} from 'react-bootstrap';
import moment from 'moment-timezone';
import {TEXT_COLOR} from '../../Configs/Targets';
import {getHostname} from '../../CustomFunctions/getHostname';
import {SCREEN_SIZE, SPORTS} from '../../Configs/Constants';
import Loader from '../../Components/Loader/Loader';
import {useSelector} from 'react-redux';
import axios from 'axios';
import FanzineNewsCard from '../../Components/Cards/FanzineNewsCard';
// import NewsCard from "../FanzineCom/Components/NewsCard";
import GoogleAd from '../../Components/Ads/GoogleAd';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import {
  CRICKET,
  FBS,
  FOOTBALL,
  MLB,
  NBA,
  NCAA,
  NFL,
} from '../../Axios/EndPoints';
import VideoCards from '../FootballVideos/VideoCardComponent';
import {useLocation, useSearchParams} from 'react-router-dom';
import RightNewsAds from '../../Components/Layout/News/RightNewsAds';
import {useOnScreen} from '../../CustomFunctions/UseOnScreen'

const SingleVideoPage = (props) => {
  let newsId = props.match.params.id;
  let sport = props.match.params.sport;
  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;
  let textColor = TEXT_COLOR[target];

  const store = useSelector((state) => state);
  const {data, leagueId} = props;

  let [latestNews, setLatestNews] = useState([]);
  let [relatedStories, setRelatedStories] = useState([]);
  let [news, setNews] = useState(null);
  let [video, setVideo] = useState([]);
  let [loading, setLoading] = useState(true);
  let [isMobile, setIsMobile] = useState(false);

  let [moreLikeThisVideos, setMoreLikeThisVideos] = useState(null);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isClub, setIsClub] = useState(target !== 'fanzine');
  const [isStickyVideo,setIsStickyVideo]= useState(true);
  const [selectedVideo,setSelectedVideo] = useState(null)

  const ref = useRef(null)
  const isVisible = useOnScreen(ref);

  let [apiPage, setApiPage] = useState(1);
  let [restrictApiCall, setRestrictApiCall] = useState(true);


  const handleScroll = (e) => {
    const pageBottom = document.body.scrollHeight -
        document.documentElement.clientHeight - 10 <=
        document.documentElement.scrollTop;
    if (pageBottom) {
      setApiPage((prevState) => {
        return prevState + 1;
      });
      setRestrictApiCall(false);
    }
  };

  function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])


    useEffect(() => {
      if(!ref?.current)
      {
        return false
      }
      else
      {
        observer.observe(ref.current)
      }

      return () => observer.disconnect()
    }, [])

    return isIntersecting
  }
  const addDefaultSrc = (ev) => {
    ev.target.src = require(
        '../../assets/images/football-shots-you-need-to-know.jpg');
  };

  const fetchLatestVideos = (endpoint = null,isNew) => {

    // let league_id = (leagueId || searchParams.get('team_id') || searchParams.get('league_id') || data?.clubId || '');
    // let parameters = leagueId ? '&league_id='+ leagueId : searchParams.get('league_id') ?
    //     '&league_id=' + league_id : searchParams.get('team_id') || data?.clubId ?
    //         '&team_id=' + league_id :
    //         '&main_site=1';
    // console.log('endpoint =',endpoint)
    // if(endpoint?.includes('undefined'))
    // {
    //   endpoint = endpoint.replace('?team_id=undefined','')
    // }
    //
    // let url = endpoint ? endpoint : FOOTBALL.LatestFanzineVideosEndPoint + parameters

    let id = (leagueId || searchParams.get('team_id') ||
        searchParams.get('league_id') ||
        data?.clubId ||
        data?.id || '');

    let parameters = leagueId ?
        '?league_id=' + id :
        searchParams.get('league_id') ?
            '?league_id=' + id :
            '?team_id=' + id;

    let URL = '';

    switch (location.pathname.split('/')[1]) {
      case 'football':
        URL += FOOTBALL.LatestFanzineVideosEndPoint;
        break;
      case 'cricket':
        URL += CRICKET.VideoEndPoint;
        break;
      case 'nfl':
        URL += NFL.VideoEndPoint;
        break;
      case 'nba':
        URL += NBA.VideoEndPoint;
        break;
      case 'ncaam':
        URL += NCAA.VideoEndPoint;
        break;
      case 'ncaaf':
        URL += FBS.VideoEndPoint;
        break;
      case 'mlb':
        URL += MLB.VideoEndPoint;
        break;
      default:
        URL += (isClub ?
            FOOTBALL.LatestClubVideosEndPoint :
            FOOTBALL.LatestFanzineVideosEndPoint);
        break;

    }

    if (id !== '') {
      URL += parameters;
    }

    get(URL).then((response) => {
      // eslint-disable-next-line array-callback-return
      let tempData = response.data;
      tempData.sort(function(a, b) {
        let keyA = new Date(a.published_at.date),
            keyB = new Date(b.published_at.date);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      if (isNew) {
        setMoreLikeThisVideos(tempData);
      } else {
        setMoreLikeThisVideos([...moreLikeThisVideos, ...tempData]);
      }


      // setMoreLikeThisVideos(tempData);
      setLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchSingleNews = () => {

    let url = isClub ?
        `/api-almet/v2.0/Football/videos/${newsId}` :
        `/api-almet/v2.0/${SPORTS[sport]}/videos/${newsId}`;

    get(url)
        .then((response) => {
          let data = response.data;
          setNews({
            id: data.id,
            title: data.title,
            url: data.url,
            preview_image: data.preview_image,
            date: moment(data.datetime).format('D MMMM YYYY'),
            time: moment(data.datetime).format('HH:mm'),
            video_time: moment.utc(data.datetime)
                .local()
                .startOf('seconds')
                .fromNow(true),
            publisher: {
              icon: data.publisher.icon,
              name: data.publisher.name,
            },
          });

          setSelectedVideo({
            id: data.id,
            title: data.title,
            url: data.url,
            preview_image: data.preview_image,
            date: moment(data.datetime).format('D MMMM YYYY'),
            time: moment(data.datetime).format('HH:mm'),
            video_time: moment.utc(data.datetime)
                .local()
                .startOf('seconds')
                .fromNow(true),
            publisher: {
              icon: data.publisher.icon,
              name: data.publisher.name,
            },
          });
        });
  setLoading(false);

  };

  const fetchLatestNews = (isNew) => {
    let searchParams = '?main_site=1';
    let limit = `&limit=27`;

    let newsEndpoint = isClub ?
        `/api-almet/v2.0/Football/news` + searchParams +
        limit + 'page=' + apiPage
        : `/api-almet/v2.0/${SPORTS[sport]}/news` + searchParams +
        limit + 'page='+apiPage;
    let videosEndpoint = isClub ?
        `/api-almet/v2.0/Football/videos?page=`+apiPage :
        `/api-almet/v2.0/${SPORTS[sport]}/videos` +
        searchParams + limit + 'page='+apiPage;

    let newsRes = get(newsEndpoint);
    let videosRes = get(videosEndpoint);

    axios.all([newsRes, videosRes])
        .then(axios.spread((responseNews, responseVideos) => {
          let tempNewsData = [];
          let tempVideosData = [];
          let tempData = [];
          responseNews.data.map((data) => {
            let news = {
              news_id: data.news_id,
              slug: data.slug,
              news_image: data.image,
              news_title: data.title,
              news_url: data.url,
              news_time: moment.tz(data.datetime, 'Europe/London')
                  .local()
                  .startOf('seconds')
                  .fromNow(),
              news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
              news_publisher: {
                publisher_name: data.publisher.name,
                publisher_icon: data.publisher.icon,
              },
              is_snack: data.is_snack,
              type: 'news',
            };
            tempNewsData.push(news);
          });
          responseVideos.data.map((data) => {
            let news = {
              news_id: data.news_id,
              slug: data.id,
              // slug: slugify(data.title),
              news_image: data.preview_image,
              news_title: data.title,
              news_url: data.url,
              news_time: moment.tz(data.published_at.date, 'Europe/London')
                  .local()
                  .startOf('seconds')
                  .fromNow(),
              news_date: moment(data.published_at.date)
                  .format('D MMM YYYY, H:mm'),
              news_publisher: {
                publisher_name: data.publisher.name,
                publisher_icon: data.publisher.icon,
              },
              is_snack: data.is_snack,
              type: 'videos',
            };
            tempVideosData.push(news);
          });

          for (let i = 0; i < tempNewsData.length; i++) {
            let ctr = i + 1;
            if (ctr % 3 === 0) {
              tempData.push(tempVideosData[i]);
            } else {
              tempData.push(tempNewsData[i]);
            }
          }
          let tempLatestNews = tempData.slice(0, 3);
          let tempRelatedStories = tempData.slice(3, 27);

          if (isNew) {
            setLatestNews(tempLatestNews);
            setRelatedStories(tempRelatedStories);
          } else {
            setLatestNews([...latestNews, ...tempLatestNews]);
            setRelatedStories([...relatedStories, ...tempRelatedStories]);
          }

          // setLatestNews(tempLatestNews);
          // setRelatedStories(tempRelatedStories);
          setLoading(false);
        }))
        .catch(error => console.log(error));
    /*get(NewsEndPoint + `?limit=31`)
        .then((response) => {
            let prepareLatestNews = response.data.slice(0, 3);
            let prepareRelatedStories = response.data.slice(3, 31);
            let tempLatestNews = [], tempRelatedStories = [];
            prepareLatestNews.map((data) => {
                let timeDifference = moment.utc(data.datetime).local().startOf('seconds').fromNow();
                let news = {
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: timeDifference,
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                    is_snack: data.is_snack,
                };
                return tempLatestNews.push(news);
            });
            prepareRelatedStories.map((data) => {
                let timeDifference = moment.utc(data.datetime).local().startOf('seconds').fromNow();
                let news = {
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: timeDifference,
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                    is_snack: data.is_snack,
                };
                return tempRelatedStories.push(news);
            });
            setLatestNews(tempLatestNews);
            setRelatedStories(tempRelatedStories);
        });*/
  };


  useEffect(()=>{
    setIsStickyVideo(isVisible);
  },[isVisible])

  useEffect(() => {
    setLoading(true);
    fetchLatestVideos(props.videosEndpoint,true);
  }, [props.videosEndpoint]);

  useEffect(() => {
    setRestrictApiCall(true);
    window.addEventListener('scroll', handleScroll);
    setApiPage(1);
    setLoading(true);
    fetchLatestNews(true);
    fetchSingleNews();
  }, [newsId]);

  useEffect(() => {
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.screen.width, isMobile]);


  useEffect(() => {
    setLoading(true);
    !restrictApiCall && fetchLatestNews(false);
    !restrictApiCall && fetchLatestVideos(props.videosEndpoint,false);
  }, [apiPage, restrictApiCall]);


  const renderBillboardBanner = () => {
    return (
        <div className="d-flex justify-content-center">
          <GoogleAd id={'4009154580'}/>
        </div>
    );
  };

  function renderRecommendationUnits() {
    return (<GoogleAd id={'9014053341'}/>);
  }
  const renderFanzineVideosMobile = () => {
    return (
        <div className={'container'}>
          <div className={'row justify-content-center'}>
            <div className={'col-11'}>
              <GoogleAd id={'4009154580'}/>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <p className={`font-20 pt-4 pb-3 m-0 text-start robotoCondensedBold ${'text-black'}`}>
                {'MORE LIKE THIS'}
                <hr
                    className={'w-50 m-0 p-0'}
                    style={{
                      borderBottom: '3px solid #000',
                      background: '#000',
                    }}
                />
                <hr className={'m-0 p-0'}
                    style={{
                      borderBottom: 'solid 1px #9c9c9c',
                    }}
                />
              </p>
            </div>
            <div className={'col-12'}>
              {moreLikeThisVideos?.map((items, index) => {

                return (index !== 0 && index % 4 === 0) ? (
                    <div className={'d-flex justify-content-center'}>
                      <GoogleAd
                          height={'278px'}
                          minHeight={'380px'}
                          width={'380px'}
                          id={'4009154580'}/>
                    </div>
                ) : (
                    <VideoCards setSelectedVideo={setSelectedVideo} selectedVideo={selectedVideo} data={items}/>
                );
              })}
            </div>
          </div>
        </div>
    );
  };

  function renderRelatedStories() {
    return isMobile ?
        renderFanzineVideosMobile()
        : (
            <div className="row">
          <span
              className={'bebasFont font-weight-bold h3'}> <p
              className={`${isMobile ?
                  'font-20' :
                  'font-30'} pt-4 pb-3 m-0 text-start robotoCondensedBold ${'text-black'}`}>
                {'MORE LIKE THIS'}
            <hr
                className={`${isMobile ? 'w-50' : 'w-25'} m-0 p-0`}
                style={{
                  borderBottom: '3px solid #000',
                  background: '#000',
                }}
            />
                <hr className={'m-0 p-0'}
                    style={{
                      borderBottom: 'solid 1px #9c9c9c',
                    }}
                />
              </p></span>

              {
                relatedStories.length ?
                    (
                        relatedStories.map((value, index) => (
                            <div className={'col-6'}>
                              <FanzineNewsCard
                                  showNewsBelow={true}
                                  news={value} sport={sport}
                                  classProps={'col-6 mb-3'}
                                  isNewsPage={false}/>
                              {(index + 1) % 4 === 0 ?
                                  (<div
                                      className={'single-mpu d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-10 col-xl-10 col-lg-10' +
                                          ' col-md-10 col-sm-10 offset-xxl-1 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1'}>
                                    <GoogleAd id={'4009154580'}/>
                                  </div>) : null}
                            </div>
                        ))
                    )
                    : null
              }
            </div>
        );
  }

  const renderVideo = (videoData, extraClass, videoHeight) => {

    return (
        <div  className={extraClass}>
          <Card
              style={{zIndex:10}}
              className={`border-0 bg-transparent ${isMobile ? 'p-0' : ''} ${isMobile ? isStickyVideo ? '' : target !== 'fanzine' ?'fixed-top pt-5 mt-3' : 'fixed-top pt-5 mt-5' : ''} `}>
            <div className="video-responsive">
              <iframe
                  style={{width: '100%'}}
                  height={videoHeight}
                  src={videoData.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
              />
            </div>
          </Card>
          <div  className={` ${isMobile ?
              'font-16' :
              'h1'} robotoCondensedBold mt-3`}
               dangerouslySetInnerHTML={{__html: videoData.title}}/>

          {/*<span className={`sfProDisplayFontBold h3 w-100 d-inline-block mt-3 mb-5`}
                      dangerouslySetInnerHTML={{__html: videoData.title}}/>*/}
          <hr/>
          <>
            <div
                className={'d-flex align-items-center justify-content-between'}>
                        <span
                            className={`${isMobile ?
                                'font-12 font-weight-600' :
                                'ms-2'} sfProDisplayFont  me-2`}>
                        <img onError={addDefaultSrc} src={videoData.publisher.icon}
                             alt="News"
                             height={30}/>
                          &nbsp;
                          &nbsp;
                          {videoData.publisher.name}</span>
              {!isMobile && <div>
                <img src={require('../../assets/images/twitter_logo.png')}
                     alt={'twitter icon'} height={25} width={31}
                     style={{
                       margin: '32px 16.4px 32px 6px',
                     }}
                />
                <img src={require('../../assets/images/facebook_icon.png')}
                     alt={'facebook icon'} height={31} width={31}
                     style={{
                       margin: '28.5px 0.4px 32px 16.1px',
                     }}
                />
                <img src={require('../../assets/images/instagram_icon.png')}
                     alt={'instagram icon'} height={31} width={31}
                     style={{
                       margin: '28.7px 15.8px 32px 16.4px',
                     }}
                />
                <img src={require('../../assets/images/whatsapp_icon.webp')}
                     alt={'whatsapp icon'} height={31} width={31}
                     style={{
                       margin: '27.6px 15.8px 32px 15.8px',
                     }}
                />
                <img src={require('../../assets/images/copy_link_icon.png')}
                     alt={'copy link icon'} height={31} width={31}
                     style={{
                       margin: '27.6px 15.8px 32px 15.8px',
                     }}
                />
              </div>
              }
              <span className={`sfProDisplayFont ${isMobile ?
                  'font-12 font-weight-600' :
                  ''}`}>
                            {!isMobile &&
                                <span className={'me-2'}>{moment(videoData.datetime)
                                    .format('hh:mm')}
                            </span>}
                {isMobile && <span className={'me-2'}>
                   {moment.utc(videoData.datetime)
                       .local()
                       .startOf('seconds')
                       .fromNow()}
                            </span>
                } {!isMobile &&
                  <span className={'ms-5'}>{moment(videoData.datetime)
                      .format('D MMMM YYYY')}
                </span>
              }                        </span>
            </div>
          </>
        </div>
    );
  };

  function elementInViewport2(eli) {

    let el =document.getElementById('div-ref-video-sticky')
    if(el)
    {
      let rect = el.getBoundingClientRect();

      return rect.bottom > 0 &&
          rect.right > 0 &&
          rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
          rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;

    }
    return  false;
  }

  const render = () => {

    return (
        <>
          {!isClub && <SubNavbar isMobile={isMobile}/>}
          <Container
              className={`${!isMobile ?
                  'mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 d-flex flex-row justify-content-center' :
                  ''}`}>
            <div className={'customContainer'}>
              {renderBillboardBanner()}
              <div className="row" >
                <div
                    className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 mt-3 ">
                  {/*{renderNewsHead()}*/}
                  {renderVideo(selectedVideo, 'row', '480')}
                  <div  id={'div-ref-video-sticky'} className={`single-mpu`}>
                    {/*<VideoAd/>*/}
                    <GoogleAd  id={'4009154580'}/>
                  </div>

                  {renderRecommendationUnits()}

                  {renderRelatedStories()}
                </div>
                {!isMobile && <RightNewsAds limit={22}/>
                } {/*{renderRightContent()}*/}
              </div>
            </div>
          </Container>
        </>
    );
  };

  return (
      <>
        <Loader loading={loading}/>
        {
            selectedVideo && render()
        }
        <div id={'htmlDiv'}/>
      </>
  );


};

export default withRouter(SingleVideoPage);
