export const capitalizeWords = (word) => {
  return word
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}

// Example usage:
//const word = "hello world! how are you today?";
//const capitalizedSentence = capitalizeWords(word);
//console.log(capitalizedSentence);
