import Loader from "../Components/Loader/Loader";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollToTop from "../Components/ScrollToTop";
import NavbarHeader from "../Components/Navbar/NavbarHeader";
import Footer from "../Components/Footer";
import Homepage from "../Pages/Clubs/Homepage";
import SingleNewsPage from "../Pages/Football/SingleNewsPage";
import SingleVideoPage from "../Pages/Football/SingleVideoPage";
import NewsAndSocials from '../Pages/Clubs/NewsAndSocials';
import {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../Configs/Constants';
import ShopMobile from '../Pages/Clubs/Shop/ShopMobile';
import ProductsListPage from "../Pages/Clubs/Shop/ProductsListPage";
import ProductDetailPage from "../Pages/Clubs/Shop/ProductDetailPage";
import Checkout from "../Pages/Clubs/Shop/Checkout";
import {OneLink} from "../Pages/OneLink";
import ShopDesktop from '../Pages/Clubs/Shop/ShopDesktop';
import NewProductListPage from '../Pages/Clubs/Shop/NewProductListPage/NewProductListPage';
import NewSingleProductPage
    from '../Pages/Clubs/Shop/NewSingleProductPage/NewSingleProductPage';
import NewShopBag from '../Pages/Clubs/Shop/NewShopBag/NewShopBag';
import NewProductListPageWithParams
    from '../Pages/Clubs/Shop/NewProductListPage/NewProductListPageWithParams';
import OnSaleProductListPage
    from '../Pages/Clubs/Shop/NewProductListPage/OnSaleProductListPage';
import Standings from '../Components/Standings/Standings';
import VideosMain from '../Pages/FootballVideos/VideosMain';
import FanzineAllFootballMatches
    from '../Pages/Football/FanzineAllFootballMatches';
import FanzineClubMatches from '../Pages/Football/FanzineClubMatches';
import FanzineAllFootballSingleMatch
    from '../Pages/Football/FanzineAllFootballSingleMatch';

const ClubRoutes = (props) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);



    return (
        <>
            <Loader loading={props.loading}/>
            {props.reduxStore && (
                <>
                    <BrowserRouter>
                        <ScrollToTop>
                            <NavbarHeader/>
                            <Routes>
                                <Route exact path="/" element={<Homepage/>}/>
                                <Route path="/news" element={<NewsAndSocials/>}/>
                                <Route path="/table" element={<Standings/>}/>
                                <Route path="/video" element={<VideosMain/>}/>
                                <Route exact path="/matches"
                                       element={<FanzineClubMatches/>}/>
                                <Route path="/newest" element={<NewProductListPageWithParams/>}/>
                                <Route path="/sale" element={<OnSaleProductListPage/>}/>
                                <Route exact path="/shop">
                                    <Route path=":filter" element={isMobile ? <ProductsListPage /> : <NewProductListPage/>}>
                                        <Route path=":filter1" element={isMobile ? <ProductsListPage /> : <NewProductListPage/>}>
                                            <Route path=":filter2" element={isMobile ? <ProductsListPage /> : <NewProductListPage/>}/>
                                        </Route>
                                    </Route>
                                    <Route exact path="product/:id" element={isMobile ? <ProductDetailPage /> : <NewSingleProductPage/>}
                                    />
                                    <Route exact path="" element={isMobile ? <ShopMobile/> : <ShopDesktop/> }/>
                                    {/*<Route exact path="" element={<ShopMobile/>  }/>*/}
                                </Route>
                                {/*<Route exact path={"/NewSingleProductPage"} element = {<NewSingleProductPage/>} />*/}
                                {/*<Route exact path={"/newShopBag"} element = {<NewShopBag/>} />*/}
                                <Route exact path="/checkout" element={isMobile ? <Checkout/> : <NewShopBag/>}/>
                                <Route exact path="/onelink" element={<OneLink />} />
                                <Route path="/news/:id" element={<SingleNewsPage />} />
                                <Route path="/video/:id" element={<SingleVideoPage/>}/>
                                <Route path="/:sport/:team/:id" element={<SingleNewsPage/>}/>
                                <Route path="/:sport/news/:id" element={<SingleNewsPage/>}/>
                                <Route path="/:sport/video/:id" element={<SingleVideoPage/>}/>




                                <Route
                                    exact
                                    path="/football/matches/:id"
                                    element={<FanzineAllFootballSingleMatch/>}
                                />
                                <Route
                                    exact
                                    path="/football/matches/:id/preview"
                                    element={<FanzineAllFootballSingleMatch/>}
                                />
                                <Route
                                    exact
                                    path="/football/matches/:id/h2h"
                                    element={<FanzineAllFootballSingleMatch/>}
                                />
                                <Route
                                    exact
                                    path="/football/matches/:id/social"
                                    element={<FanzineAllFootballSingleMatch/>}
                                />
                                <Route
                                    exact
                                    path="/football/matches/:id/overview"
                                    element={<FanzineAllFootballSingleMatch/>}
                                />
                                <Route
                                    exact
                                    path="/football/matches/:id/stats"
                                    element={<FanzineAllFootballSingleMatch/>}
                                />
                                <Route
                                    exact
                                    path="/football/matches/:id/stats"
                                    element={<FanzineAllFootballSingleMatch/>}
                                />
                                <Route
                                    exact
                                    path="/football/matches/:id/lineups"
                                    element={<FanzineAllFootballSingleMatch/>}
                                />
                                <Route
                                    exact
                                    path="/football/matches/:id/commentary"
                                    element={<FanzineAllFootballSingleMatch/>}
                                />




                            </Routes>
                            <Footer/>
                        </ScrollToTop>
                    </BrowserRouter>
                </>
            )}
        </>
    );
}

export default ClubRoutes;
