import FanzineNewsCard from '../Cards/FanzineNewsCard';
import React, {useEffect, useState} from 'react';
import {FOOTBALL} from '../../Axios/EndPoints';
import {get} from '../../Axios/get';
import moment from 'moment-timezone';
import {useLocation, useSearchParams} from 'react-router-dom';
import Loader from '../Loader/Loader';
import GoogleAd from '../Ads/GoogleAd';
import {SCREEN_SIZE} from '../../Configs/Constants';
import NewsSectionHeading from "../NewsSectionHeading";
import {COLORS, FONT_COLOR} from "../../Configs/Targets";
import RightNewsAds from "./News/RightNewsAds";
import ItemLoader from "../Loader/ItemLoader";
import FanzineSportsNewsCard
	from '../FanzineSports/FanzineSportsNewsCard/FanzineSportsNewsCard';

const MoreStories = (props) => {
	const {setPage, data, target, pageTitle, page, limitRight} = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const [moreStories, setMoreStories] = useState([]);
	let [apiPage, setApiPage] = useState(page || 3);
	let [restrictApiCall, setRestrictApiCall] = useState(true);
	const [isMobile, setIsMobile] = useState(false);
	const [loading, setLoading] = useState(true);

	const location = useLocation();

	useEffect(() => {
		if (window.screen.width <= SCREEN_SIZE.MOBILE) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [window.screen.width, isMobile]);

	const fetchMoreStories = (isNew) => {
		let page = '?page=' + apiPage;
		let limit = '&limit=36';

		let id = (searchParams.get('team_id') || searchParams.get('league_id') ||
			data?.clubId || '');
		let parameters = page;
		parameters += searchParams.get('league_id') ?
			'&league_id=' + id :
			data?.clubId ? '&team_id=' + data?.clubId :
				'';

		parameters += searchParams.get('teamType') ? '&teamType=' + searchParams.get('teamType') : '';

		parameters += limit;

		let URL = FOOTBALL.LatestNewsEndPoint + parameters;


		get(URL).then((responseNews) => {
			let tempNewsData = [];
			responseNews?.data?.map((data) => {
				let news = {
					news_id: data.news_id,
					slug: data.slug,
					news_image: data.image,
					news_title: data.title,
					news_url: data.url,
					news_time: moment.tz(data.datetime, 'Europe/London')
						.local()
						.startOf('seconds')
						.fromNow(),
					news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
					datetime: data.datetime,
					news_publisher: {
						publisher_name: data.publisher.name,
						publisher_icon: data.publisher.icon,
					},
					is_snack: data.is_snack,
					main_team: data.main_team,
					type: 'news',
				};
				tempNewsData.push(news);
			});
			let tempData = tempNewsData;
            tempData.sort(function (a, b) {
                let keyA = new Date(a.datetime),
                    keyB = new Date(b.datetime);
							if(moment(a.datetime).diff(moment(b.datetime),'hour') > 0)
							{
								return -1
							}
							else if(moment(a.datetime).diff(moment(b.datetime),'hour') < 0)
							{
								return 1
							}
                // Compare the 2 dates
                // if (keyA < keyB) return -1;
                // if (keyA > keyB) return 1;
                return 0;
            });

			if (isNew) {
				setMoreStories(tempData);
			} else {
				setMoreStories([...moreStories, ...tempData]);
			}
			setLoading(false);
		})?.catch((error) => {
			console?.log(error);
			setLoading(false);
		});
		setLoading(false);
	};

	const handleScroll = (e) => {
		const pageBottom = document.body.scrollHeight -
			document.documentElement.clientHeight - 10 <=
			document.documentElement.scrollTop;
		if (pageBottom) {
			setApiPage((prevState) => {
				return prevState + 1;
			});
			setPage((prevState) => {
				return prevState + 1;
			});
			setRestrictApiCall(false);
		}
	};

	useEffect(() => {
		setRestrictApiCall(true);
		window.addEventListener('scroll', handleScroll);
		setApiPage(1);
		setPage(1);
		setLoading(true);
		fetchMoreStories(true);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [location.pathname, searchParams]);

	useEffect(() => {
		setLoading(true);
		!restrictApiCall && fetchMoreStories(false);
	}, [apiPage, restrictApiCall]);

	const renderMoreStories = () => {
		return (
			<div className="row ms-auto me-auto">
				<NewsSectionHeading title={pageTitle || 'More Stories'} bgColor={COLORS[target]} fontColor={FONT_COLOR[target]}/>
				{moreStories.length > 0 ?
					(
						moreStories.map((value, index) => {
							return (index === 0) ?
								(
									<div
										className={'col-lg-12 pt-2 col-md-12 col-sm-12 col-xs-12'}>
										{isMobile && <FanzineSportsNewsCard
												imageHeight={'123px'}
												news={value}
												showNewsSide={true}
										/>}
										{!isMobile && <FanzineNewsCard
												news={value}
												imageHeight={isMobile ? '277px' : '500px'}
												cardTextSize={''}
												isNewsPage={''}
												publisherIconSize={''}
												isMain={''}
										/>
										}									</div>
								)
								:
								(index % 11 === 0) ?
									(
										<div
											className={` ${'col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12'}`}
										>
											<GoogleAd id={'4009154580'}/>
											{isMobile && <FanzineSportsNewsCard
													imageHeight={'123px'}
													news={value}
													showNewsSide={true}
											/>}
											{!isMobile && <FanzineNewsCard
													news={value}
													imageHeight={isMobile ? '277px' : '500px'}
													cardTextSize={''}
													isNewsPage={''}
													publisherIconSize={''}
													isMain={''}
											/>}
										</div>
									)
									:
									(
										<div
											className={'col-lg-6 pt-2 col-md-6 col-sm-12 col-xs-12'}>
											{isMobile && <FanzineSportsNewsCard
													imageHeight={'123px'}
													news={value}
													showNewsSide={true}
											/>}
											{!isMobile && <FanzineNewsCard
													news={value}
											/>}
										</div>
									);
						})
					)
					: null}
			</div>
		);
	};

	const renderRightAds = (isUnlimited) => {

		return (
			<div className={'col-lg-4 col-md-4 col-sm-12 col-xs-12 '}>
				<GoogleAd id={'4009154580'}/>
				{
					moreStories.map((value, index) => {
						if (apiPage < 2 && !isUnlimited && index > (4 * (apiPage))) {
							return '';
						} else if (!isUnlimited && index > (4 * (apiPage))) {
							return '';
						}

						return (index % 3 !== 0) ? (
								<div
									className={`col-12 ${index === 0 ? 'mt-n1' : 'mt-3'}`}
								>
									<FanzineNewsCard
										news={value}
										imageHeight={'277px'}
										cardTextSize={''}
										isNewsPage={false}
										showNewsBelow={true}
										publisherIconSize={''}
										isMain={''}
									/>
								</div>
							) :
							(
								<div className={'col-10'}>
									<GoogleAd id={'4009154580'}/>
								</div>
							);
					})
				}
			</div>
		);
	};

	const renderStories = () => {
		return (
			<div
				className={'col-xxl-8 col-xl-8 pb-2  col-lg-8 col-md-8 mt-4 p-0  col-sm-12'}>
				{renderMoreStories()}
			</div>
		);
	};
	const render = () => {
		return (
			<div className={'container'}>
				<div
					className={'row'}>
					{renderStories()}
					<RightNewsAds isMobile={isMobile} limit={limitRight ? limitRight : 10} apiPage={apiPage} />
					{/*{renderRightAds(true)}*/}
				</div>
			</div>
		);
	};

	return loading ? <ItemLoader loading={loading}/> : render();

};

export default MoreStories;
