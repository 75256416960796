import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import NewsSectionHeading from '../../../Components/NewsSectionHeading';
import {COLORS, FONT_COLOR, TEXT_COLORS} from '../../../Configs/Targets';
import {getHostname} from '../../../CustomFunctions/getHostname';
import NewShopCategoryCard from './NewShopCategoryCard/NewShopCategoryCard';
import ShopItemCarousel from '../../../Components/Carousel/ShopItemCarousel';
import NewNavbar from '../../../Components/NewNavbar/NewNavbar';
import {useDispatch, useSelector} from 'react-redux';
import {graphql} from '../../../Axios/graphql';
import {ShopifyGraphQlQueries} from '../../../Configs/ShopifyGraphQlQueries';
import {
  setBannerImages,
  setCategories, setContainerImages,
  setProductColors,
  setRandomProducts,
} from '../../../redux/actions/StoreActions';
import {get} from '../../../Axios/get';
import {
  CampaignEndPoint,
  ProductColors, ShopImagesEndpoint,
  StoreCategories,
} from '../../../Axios/EndPoints';
import {PRODUCT_COLORS, SCREEN_SIZE} from '../../../Configs/Constants';
import CampaignPopup from '../../../Components/Campaigns/CampaignPopup';
import axios from 'axios';
import {useLocation} from 'react-router-dom';

const renderFullScreenImage = (shopData, textColor) => {

  return (
      <div className={'w-100'}>
        <Card className="border-0 bg-transparent m-1">
          <Card.Img
              className={'img-fluid'}
              src={shopData.bannerImages && shopData.bannerImages.web_image ?
                  shopData.bannerImages.web_image :
                  require('../../../assets/images/fullscreenBackground.png')}
              alt="Card image"
          />
          <Card.ImgOverlay
              className={`d-flex align-items-center justify-content-center flex-column news-overlay`}>
            <Card.Text className={`${textColor ?
                textColor :
                'text-white'} sfProDisplayFont text-center`}>
                        <span className={'bebasFont'}
                              style={{fontSize: '75px'}}>
                            FAN MADE
                        </span>
              <br/>
              <span className={`bebasFont ${textColor ?
                  textColor :
                  'text-white'} `} style={{fontSize: '75px'}}>
                            MERCHANDISE
                        </span>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
  );
};

const renderCategories = (shopData) => {

  const Categories = shopData?.categories ? shopData.categories : [];

  return (
      <div className={'container mt-5'}>
        <div className={'row'}>
          {Categories?.map((categoryValue, categoryIndex) => {
            return (
                <div
                    key={categoryIndex}
                    className={'col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'}>
                  <NewShopCategoryCard
                      name={categoryValue?.name || `T-SHIRTS`}
                      link={`${categoryValue?.shopify_name}` || `t-shirts`}
                      src={categoryValue?.image ||
                          require('../../../assets/images/messi.webp')}
                      mainCategory={true}
                      categories={true}
                  />
                </div>
            );
          })
          }
        </div>
      </div>
  );
};

const renderWhoAreYouBuyingItFor = (shopData) => {
  return (
      <div className={'container mt-5'}>
        <div className={'row'}>
          <div
              className={'col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
            <NewShopCategoryCard
                name={shopData?.bannerImages?.label_mens || `MENS`}
                link={`Mens`}
                src={shopData?.bannerImages?.mobile_image_mens ||
                    require('../../../assets/images/messi.webp')}
                mainCategory={true}
                whoAreYouBuyingFor={true}
            />
          </div>
          <div
              className={'col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
            <NewShopCategoryCard
                name={shopData?.bannerImages?.label_womens || `WOMENS`}
                link={`Womens`}
                src={shopData?.bannerImages?.mobile_image_womens ||
                    require('../../../assets/images/messi.webp')}
                mainCategory={true}
                whoAreYouBuyingFor={true}
            />
          </div>
          <div
              className={'col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
            <NewShopCategoryCard
                name={shopData?.bannerImages?.label_juniors || `CHILDRENS`}
                link={`Childrens`}
                src={shopData?.bannerImages?.mobile_image_juniors ||
                    require('../../../assets/images/messi.webp')}
                mainCategory={true}
                whoAreYouBuyingFor={true}
            />
          </div>
        </div>
      </div>
  );
};

const ShopDesktop = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  const ShopStore = useSelector(state => state.shopify);
  const MiscStore = useSelector(state => state.misc);

  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;
  const textColor = FONT_COLOR[target];
  const bgColor = COLORS[target];

  const [bestSelling, setBestSelling] = useState([]);
  const [sortKey, setSortKey] = useState('BEST_SELLING');
  const [campaign, setCampaign] = useState([]);
  const [campaignModalShow, setCampaignModalShow] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);


  const fetchShopImages = () => {
    const ReplacedTarget = location.pathname.split('/')[1];
    const URL = `https://api2.${ReplacedTarget}.com` + ShopImagesEndpoint;

    console.log('url -', URL);

    axios.get(URL).then((response) => {
      if (response.data.success) {
        dispatch(setContainerImages(response.data.data.containerImages));
        dispatch(setBannerImages(response.data.data.bannerImages));
      }
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchCategories = () => {
    const ReplacedTarget = location.pathname.split('/')[1];

    const URL = `https://api2.${ReplacedTarget}.com` + StoreCategories;

    console.log('url -', URL);

    axios.get(URL)
        .then((res) => {
          dispatch(setCategories(res.data));
        })
        .catch((error) => {
          console.log('error getting categories in shopDesktop =', error);
        });
  };

  const fetchProductColors = () => {

    const ReplacedTarget = location.pathname.split('/')[1];

    const URL = `https://api2.${ReplacedTarget}.com` + ProductColors;

    console.log('url -', URL);

    axios.get(URL)
        .then((res) => {
          let tempColors = {};
          res.data.map((value) => (tempColors[value.name] = value.hex));
          dispatch(setProductColors(tempColors));
        })
        .catch((error) => {
          console.log('error getting categories in shopDesktop =', error);
        });

  };

  const fetchBestSelling = () => {
    graphql(ShopifyGraphQlQueries('BEST_SELLING', sortKey))
        .then((response) => {
          let products = response.data.data['collectionByHandle']['products'].edges;
          if (products.length) {
            let tempBestSelling = [];
            products?.map((value) => {
              let product = value.node;
              let variants = [];
              PRODUCT_COLORS.map((colors, index) => {
                let tmp = product.variants.edges.filter((val) => {
                  return val.node.title.includes(colors) ? val.node : '';
                });
                if (tmp !== '' && tmp?.length > 0) {
                  variants.push(tmp);
                }
              });
              // console.log('res.data.data =', product['variants'].edges[0].node.price)
              tempBestSelling.push({
                id: product?.id,
                name: product?.title,
                src: product?.images && product?.images?.edges[0]?.node?.src,
                alt: product?.images && product?.images?.edges[0]?.node?.alt,
                handle: product?.handle,
                price: product['variants']?.edges[0]?.node?.price?.amount,
                oldPrice: product['variants']?.edges[0]?.node?.compareAtPrice?.amount,
                variants: variants,
              });
              return tempBestSelling;
            });
            setBestSelling(tempBestSelling);
            dispatch(setRandomProducts(tempBestSelling));
          }
        });
  };

  const fetchCampaigns = () => {
    get(CampaignEndPoint)
        .then((response) => {
          let campaignData = response.data.data;
          console.log('campaign', campaignData.length);
          let tempCampaign = [];
          let campaign = null;
          if (campaignData.length) {
            campaignData.map((data) => {
              campaign = {
                id: data.id,
                campaign_id: data.campaign_id,
                type: data.type,
                leagueCode: data.league_code,
                image: data.image,
                title: data.title,
                description: data.description,
                questions: data.questions,
              };
              if (data.display_page === 'shop') {
                tempCampaign.push(campaign);
              }
            });
          }
          setCampaign(tempCampaign);
          setCampaignModalShow(true);
        })
        .catch((err) => {
          console.log('campaign_err', err);
        });
  };

  useEffect(() => {
    fetchCampaigns();
    fetchBestSelling();
    if (target === 'fanzine') {
          fetchShopImages()
          fetchCategories();
          fetchProductColors();
    }

  }, []);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    if (screenWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    }
  }, [screenWidth]);

  useEffect(() => {
  }, [ShopStore]);

  const renderMain = (shopData) => {
    return (
        <>
          <NewNavbar/>

          <CampaignPopup
              show={false}
              onHide={() => setCampaignModalShow(false)}
              campaign={campaign}
          />

          {renderFullScreenImage(shopData, textColor)}
          <div className={'container mt-2'}>
            <NewsSectionHeading title={'Categories'}
                                bgColor={['theyellows', 'wearewolves'].includes(
                                    target) ? TEXT_COLORS[target] : bgColor}
                                fontColor={'white'} isShop={true}/>
          </div>
          {renderCategories(shopData)}
          <div className={'container mt-2'}>
            <NewsSectionHeading title={'Who are you buying for?'}
                                bgColor={['theyellows', 'wearewolves'].includes(
                                    target) ? TEXT_COLORS[target] : bgColor}
                                fontColor={'white'} isShop={true}/>
          </div>
          {renderWhoAreYouBuyingItFor(shopData)}
          {bestSelling?.length > 0 && <div className={'container mt-2'}>
            <NewsSectionHeading title={'Best Sellers'}
                                bgColor={['theyellows', 'wearewolves'].includes(
                                    target) ? TEXT_COLORS[target] : bgColor}
                                fontColor={'white'} isShop={true}/>
          </div>
          }          <div className={'container mt-5 pb-5'}>
            {bestSelling?.length > 0 && <ShopItemCarousel
                images={bestSelling}
                showDetail={false}
                showColors={true}
                showPriceFirst={false}
                priceColor={COLORS[target]}
                newShopItems={true}
            />}
          </div>
        </>
    );
  };

  return renderMain(ShopStore);

};

export default ShopDesktop;
