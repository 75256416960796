import Loader from '../Loader/Loader';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import {get} from '../../Axios/get';
import {GossipEndPoint} from '../../Axios/EndPoints';
import GossipCard from '../Cards/Clubs/GossipCard';
import {FONT_COLOR} from "../../Configs/Targets";

const GossipSlider = (props) => {
  const {id, matchData, title, bgColor, fontColor, isMobile, loading, allLeagues} = props;

  const [gossip, setGossip] = useState([]);

  const fetchGossip = () => {
    get(GossipEndPoint)
        .then((response) => {
          if (response.data.length <=0){
            // setShowGossip(false);
          }
          setGossip(response.data);
        })
        .catch((err) => {
          console.error(err);
        })
  }

  useEffect(() => {
    fetchGossip();


  }, []);

  const renderLeftButton = (id) => {

    let style =
        {
          width: '63px',
          height: '68px',
          backgroundColor: '#fff',
          color: 'black',
          border: '0px',
          borderRadius: '0px'
        };

    const handleLeftClick = () => {
      document.getElementById('NewsSliderContainer' + id).scrollLeft -= 200;

    }


    return (
        <div
            style={{
              marginLeft: '-3em',
              marginRight: '-2em',
              zIndex: '1',
              marginTop: '-4em'
            }}

            onClick={handleLeftClick}
        >
          <Button className={''} style={style}>
            <AiOutlineLeft/>
          </Button>
        </div>
    );
  };

  const renderRightButton = (id) => {

    let style =
        {
          width: '63px',
          height: '68px',
          backgroundColor: '#fff',
          color: 'black',
          border: '0px',
          borderRadius: '0px'
        };

    const handleRightClick = () => {
      document.getElementById('NewsSliderContainer' + id).scrollLeft += 200;

    }

    return (
        <div
            style={{
              marginLeft: '-3em',
              zIndex: '1',
              marginTop: '-4em'

            }}

            onClick={handleRightClick}
        >
          <Button
              style={style}
          > <AiOutlineRight/>
          </Button>
        </div>
    );
  };

  const renderNews = (matchData, league_name, id) => {
    let title = league_name;
    let style = {
      background:bgColor || 'transparent',
      color: fontColor || '#ffffff',
    };
    for (let i = 0; i < allLeagues?.length; i++) {
      if (allLeagues && allLeagues[i]?.name.replaceAll(' ', '-') == title) {
        style.background = allLeagues[i]?.bg_color
        style.color = allLeagues[i]?.font_color
      }
    }
    return (
        <div className={'w-100'}
             style={style}
        >
          <div className={`${isMobile ? 'w-100' : 'container'} p-5`}>
            <p className={`font-30 text-white sfProDisplayFontBold ${fontColor}`}>
              {title ? title : 'Gossip'}
            </p>
            <div className={'d-flex justify-content-center align-items-center'}>
              {!isMobile && renderLeftButton(id)}
              <div
                  id={'NewsSliderContainer' + id}
                  className={`slider-mobile-container ${matchData?.length <= 3 ?
                      'justify-content-center' :
                      'overflow-scroll-x'}`}>
                {
                  gossip?.map((gossipItem,index) => (
                            <div
                                key={index}
                                // onClick={handleClick}
                                className={`slider-mobile-news-menu p-3 text-left d-flex flex-column justify-content-center ${isMobile ?
                                    'pt-1 mt-4' :
                                    ''} `}
                            >
                              <GossipCard
                                  index={index}
                                  gossip={gossipItem}
                                  gossipOnSlider={true}
                                  fontColor={fontColor}
                                  // isNewsPage={false }
                                  isMobile={isMobile}
                                  // imageHeight={'300px'}
                              />
                            </div>
                      ),
                  )
                }
              </div>
              {!isMobile && renderRightButton(id)}
            </div>
          </div>
          <Loader loading={loading}/>
        </div>
    );
  };


  const renderMain = () => {

    return (
        <div>
          {gossip?.length !== 0 && renderNews(matchData, title, id)}
        </div>
    )
  }

  return renderMain()

}


GossipSlider.propTypes = {
  title: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};


export default GossipSlider;
