import moment from "moment";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";

const CalendarContainerItem = (props) => {
    const queryParams = useLocation();
    const {date, setSelectedDate, isMobile, sport,isFanzineClubMatches,team} = props;
    const [paramsDate, setParamsDate] = useState(queryParams.search.slice(6))
    const navigate = useNavigate();

    const handleDateSelect = useCallback((e) => {
        // console.log('e.target.value =', e.target.value, selectedDate)
        setSelectedDate(e.target.value)
        window.location.replace(isFanzineClubMatches ?
            `/${sport}/${team}/matches?date=${moment(e.target.value).format('YYYY-MM-DD')}`
            : `/${sport}/matches?date=${moment(e.target.value).format('YYYY-MM-DD')}`)
    }, [navigate])

    const handleClickOnToday = () => {

        let dropdownCalendar = document.getElementById('calendar');
        if (dropdownCalendar) {
            dropdownCalendar.addEventListener("focus", function (evt) {
                this.showPicker();
            });
            dropdownCalendar.focus();
        }
    }

    useEffect(() => {
        setParamsDate(queryParams?.search?.slice(6))
    }, [date, queryParams?.search]);


    return (
        <div
            className={
                moment(date).format("YYYY-MM-DD") ===
                moment(paramsDate || Date.now()).format("YYYY-MM-DD")
                    ?
                    `col-2 calendar-item ${isMobile ? 'border-bottom-5 slider-mobile-news-menu' : 'bg-background'}`
                    :
                    "col-2 calendar-item"}
            style={{marginLeft: "auto", marginRight: "auto", width: 'max-content', textAlign: '-webkit-center'}}
        >
            {date && (
                <a
                    onClick={
                        moment(date).format('YYYY-MM-DD') === moment(Date.now()).format('YYYY-MM-DD') &&
                        handleClickOnToday}
                    href={
                        moment(date).format('YYYY-MM-DD') !== moment(Date.now()).format('YYYY-MM-DD') &&
                        (isFanzineClubMatches ?
                            `/${sport}/${team}/matches?date=${moment(date).format('YYYY-MM-DD')}`
                            : `/${sport}/matches?date=${moment(date).format('YYYY-MM-DD')}`)}
                    className={`row text-decoration-none ${isMobile ? 'text-white' : 'text-black'}`}
                    style={{width: 'max-content'}}
                >
                    <div
                        className={
                            "text-center calendar-item-day text-uppercase"
                        }
                    >
                        {
                            moment(date).format('YYYY-MM-DD') === moment(Date.now()).format('YYYY-MM-DD') ?
                                (<div className={`text-end sfProDisplayFontBold font-16`}> Today
                                        <input style={{
                                            pointerEvents: 'none',
                                            width: isMobile ? '8px' : '14px',
                                            border: '0px',
                                            background: 'transparent',
                                            color: isMobile ? '#fff' : 'transparent'
                                        }}
                                               id={'calendar'}
                                               className={'icon-change-date'}
                                               onChange={(e) => {
                                                   handleDateSelect(e)
                                               }}
                                               type={'date'}/>
                                    </div>
                                )
                                : <div className={'font-16'}> {moment(date).format("ddd")} </div>
                        }
                    </div>
                </a>
            )}{" "}
            {date && (
                <a
                    onClick={
                        moment(date).format('YYYY-MM-DD') === moment(Date.now()).format('YYYY-MM-DD') &&
                        handleClickOnToday}
                    href={isFanzineClubMatches ?
                        `/${sport}/${team}/matches?date=${moment(date).format('YYYY-MM-DD')}`
                        :
                        `/${sport}/matches?date=${moment(date).format('YYYY-MM-DD')}`}
                    className={`row text-decoration-none ${isMobile ? 'text-white' : 'text-black'}`}
                >
                    <div
                        className={
                            `text-center ${isMobile ? 'font-10' : 'font-16'} font-weight-500`
                        }
                    >
                        {
                            moment(date).format('YYYY-MM-DD') === moment(Date.now()).format('YYYY-MM-DD') ?
                                isMobile ? <></> : <>
                                    {moment(date).date()} {"\t"}
                                    {moment(date).format("MMM")}
                                </>
                                : <>
                                    {moment(date).date()} {"\t"}
                                    {moment(date).format("MMM")}
                                </>
                        }
                    </div>
                </a>
            )}
        </div>
    );
};

export default CalendarContainerItem;
