import React, {useEffect, useState} from 'react';
import Card from "react-bootstrap/Card";
import Button from 'react-bootstrap/Button';
import './styles/NavBarCard.css';
import BlueShirt from '../../../assets/images/blue-shirt.png';
import {get} from '../../../Axios/get';
import moment from 'moment-timezone';
import * as searchParams from '../../../Axios/get';
import {FOOTBALL} from '../../../Axios/EndPoints';
import upcomingMatchBG from '../../../assets/images/fanzine-new-app-design/upComingMatchBG.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {Link} from 'react-router-dom';

const UpComingMatch = () => {

  const [upComingMatchLoading, setUpComingMatchLoading] = useState(true);
  const [upComingMatchData, setUpComingMatchData] = useState(null);

  const fetchMatches = async () => {

    let  fetchData =[];

    let URL = FOOTBALL.HomepageMatchesEndPoint;
     await get(URL)
        .then((res) => {

          setUpComingMatchData(res.data.data);
          setUpComingMatchLoading(false);
        })
        .catch((error) => console.error('error while getting matchesData =', error));

  };


  useEffect(()=>{
    fetchMatches().then(()=>{});
  },[])

// console.log('upComingMatchData =',upComingMatchData)

    return (
        <>
          {upComingMatchData?.length > 0 && <Card className="navbar-card"
          style={{
            marginTop: '5%',
            backgroundImage: `url(${upComingMatchData[0]?.next_match?.venue?.stadium_image})`,
            backgroundSize:'cover',
            // background:`linear-gradient(0deg, rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3)), url(${upComingMatchData[0]?.next_match?.venue?.stadium_image}), cover `,
          }}
          >
            <Card.Body
            >
              <div className="mb-auto">
                <Card.Title id={'next-match-title'}
                            className={'next-match-text-style text-nowrap'}>NEXT
                  MATCH</Card.Title>
                <Card.Subtitle
                    className="mb-3 mt-1 text-center next-match-date-text-style ps-3">{moment(
                    upComingMatchData[0]?.next_match?.datetime)
                    .format('ddd DD MMM')}</Card.Subtitle>
                <Card.Text className="text-center d-flex">
                  <p className={'next-match-home-team-text'}>
                    {upComingMatchData[0]?.next_match?.home_team?.name}
                  </p>
                  <img src={upComingMatchData[0]?.next_match?.home_team?.club_logo || BlueShirt} width="50" alt={'home team icon'}/>
                  <p className={'next-match-center-team-text text-nowrap'}>
                    {moment(
                        upComingMatchData[0]?.next_match?.datetime)
                        .format('HH:mm')}
                  </p>
                  <img src={upComingMatchData[0]?.next_match?.guest_team?.club_logo || BlueShirt} width="50" alt={'away team icon'}/>
                  <p className={'next-match-away-team-text'}>
                    {upComingMatchData[0]?.next_match?.guest_team?.name}
                  </p>
                </Card.Text>
                <Card.Subtitle
                    className="text-center justify-content-center d-flex"><p
                    className={'pt-2 next-match-league-text'}>{upComingMatchData[0]?.clean_name}</p>
                </Card.Subtitle>
              </div>
            </Card.Body>
            <Card.Footer
                className={'w-100 d-flex align-items-center justify-content-end ms-4'}>
              <div
                  className="text-center d-flex justify-content-end align-items-center">
                <Link
                    to={'/'}
                    // to={`/football/matches/${upComingMatchData[0]?.id}/preview`}
                    className="gameAnalysisButton d-flex align-items-center text-decoration-none">
                  <p className={'next-match-preview-text'}>Preview
                    <span className={'ms-2'}>
                    <FontAwesomeIcon size={'sm'} beat icon={faChevronRight}/>
                    </span>
                  </p>
                </Link>
              </div>
            </Card.Footer>
          </Card>
          }        </>
    );
  }

export default UpComingMatch;
