import axios from "axios";
import {getHostname} from "../CustomFunctions/getHostname";

export const post = (endPoint, data, encrypted = false, token) => {
    let headers;
    let API = '';
    if (encrypted) {
        headers = {
            'Accept': '*/*',
            'Authorization': 'Bearer ' + token,
            // 'Content-Type': "application/x-www-form-urlencoded",
            'Content-Type': "application/json",
        }
    } else {
        headers = {
            withCredentials: true,
            'Accept': '*/*',
            // 'Content-Type': "application/x-www-form-urlencoded",
            'Content-Type': "application/json",
        }

    }
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const href = window.location.host;

    let forLocalhost = `api2.${target}.com`;
    // let forLocalhost = `localhost:8001`;
    API = href
        .replace("www.", "api2.")
        .replace("new.", "api2.")
        .replace("matches.", "api2.")
        .replace("localhost:3000", forLocalhost)
        .replace("127.0.0.1:3000", forLocalhost)
        .replace("192.168.100.58:3000", forLocalhost)
        .replace("192.168.31.226:3000", forLocalhost)
        .replace("192.168.31.22:3000", forLocalhost)
        .replace("192.168.31.23:3000", forLocalhost)
        .replace("localhost:8001", forLocalhost)
        .replace("127.0.0.1:8001", forLocalhost);


    let certificate = 'https://'
    if (forLocalhost === 'localhost:8001') {
        certificate = 'http://';
    }
    let url = certificate + API + endPoint;
    console.log("url: ", url);
    return axios.post(url, data,
        {headers});
}
