import Loader from '../../../Components/Loader/Loader';
import React, {useEffect, useState} from 'react';
import {get} from '../../../Axios/get';
import {FOOTBALL} from '../../../Axios/EndPoints';
import moment from 'moment-timezone';
import {SCREEN_SIZE} from '../../../Configs/Constants';
import {chunk} from '../../../Components/Helper/chunk';
import FanzineSportsNewsLayout
  from '../../../Components/FanzineSports/NewsLayout/FanzineSportsNewsLayout';
import FanzineSportsVideosOnSlider
  from '../../../Components/FanzineSports/FanzineSportsVideosOnSlider/FanzineSportsVideosOnSlider';
import {getHostname} from '../../../CustomFunctions/getHostname';
import GoogleAd from '../../../Components/Ads/GoogleAd';
import FanzineSportsNewsCard
  from '../../../Components/FanzineSports/FanzineSportsNewsCard/FanzineSportsNewsCard';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import Footer from '../../../Components/Footer';
import FanzineSportsNavBar
  from '../../../Components/Navbar/FanzineSportsNavBar';
import FanzineSportsGoalHighlights from './FanzineSportsGoalHighlights';
import TopStories from '../../../Components/Layout/TopStories';
import FanzineNewsCard from '../../../Components/Cards/FanzineNewsCard';
import FanzineSportsSliderNoBackground from './FanzineSportsSliderNoBackground';
import FanzineSportsHomeSubNavBar from './FanzineSportsHomeSubNavBar';
import {
  FANZINESPORTS_HOMEPAGE_LEAGUES,
} from '../../../Constants/JS/FanzineSportsLeague';

const FanzineSportsHome = () => {
  const LoginStore = useSelector(state => state);
  const navigate = useNavigate();
  const location = useLocation();

  let [isMobile, setIsMobile] = useState(false);
  let [loading, setLoading] = useState(true);

  let [latestNews, setLatestNews] = useState([]);
  let [moreNews, setMoreNews] = useState([]);
  let [latestNewsLoading, setLatestNewsLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [leagueData, setLeagueData] = useState({
    leagueName: 'Premier League',
    leagueID: 1204,
    slug: 'premier-league',
    main: true,
  });

  const fetchLatestNews = (league_id) => {
    let page = '&page=2';
    let limit = '?limit=6';

    get(FOOTBALL.LatestNewsEndPoint + limit + '&league_id=' + league_id + page)
        .then((response) => {
          let tempNewsData = [];
          // eslint-disable-next-line array-callback-return
          response.data.map((data) => {
            let news = {
              is_snack: data.is_snack,
              news_id: data.news_id,
              slug: data.slug,
              news_image: data.image,
              news_title: data.title,
              news_url: data.url,
              news_time: moment.tz(data.datetime, 'Europe/London')
                  .local()
                  .startOf('seconds')
                  .fromNow(),
              news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
              news_publisher: {
                publisher_name: data.publisher.name,
                publisher_icon: data.publisher.icon,
              },
            };
            tempNewsData.push(news);
          });
          let tmp = chunk(tempNewsData, 18);
          console.log('tmp', tmp);
          setLatestNews(tmp);
          // setLoading(false);
          setLatestNewsLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    setLoading(false);
  };
  const fetchMoreNews = (league_id) => {
    let page = '&page=1';
    let limit = '?main_site=0&limit=35';

    get(FOOTBALL.LatestNewsEndPoint + limit + '&league_id=' + league_id)
        .then((response) => {
          let tempNewsData = [];
          // eslint-disable-next-line array-callback-return
          response.data.map((data) => {
            let news = {
              is_snack: data.is_snack,
              news_id: data.news_id,
              slug: data.slug,
              news_image: data.image,
              news_title: data.title,
              news_url: data.url,
              news_time: moment.utc(data.datetime)
                  .local()
                  .startOf('seconds')
                  .fromNow(),
              news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
              news_publisher: {
                publisher_name: data.publisher.name,
                publisher_icon: data.publisher.icon,
              },
            };
            tempNewsData.push(news);
          });
          setMoreNews(tempNewsData);
        })
        .catch((err) => {
          console.error(err);
        });
    setLoading(false);
  };

  useEffect(() => {
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.screen.width, isMobile]);

  useEffect(() => {
    if (!LoginStore?.fanzineSports?.isLogin) {
      navigate('login');
    }

  }, [LoginStore]);

  useEffect(() => {

    const leagueSlug = location.pathname.split('/')[1];
    let league_id = 1204;
    let league_data_temp = null;
    for (let i = 0; i < FANZINESPORTS_HOMEPAGE_LEAGUES?.length; i++) {
      if (leagueSlug === FANZINESPORTS_HOMEPAGE_LEAGUES[i]?.slug) {
        league_id = FANZINESPORTS_HOMEPAGE_LEAGUES[i]?.leagueID;
        league_data_temp = FANZINESPORTS_HOMEPAGE_LEAGUES[i];
      }
    }

    setLeagueData(league_data_temp);

    LoginStore?.fanzineSports?.isLogin && fetchLatestNews(league_id);
    LoginStore?.fanzineSports?.isLogin && fetchMoreNews(league_id);
  }, [location.pathname]);

  const renderMoreNews = () => {
    if (moreNews.length <= 0) {
      return <></>;
    }

    return (
        <div className={'container'}>
          <div className={'row'}>
            {
              moreNews?.map((item, index) => (
                  <div
                      className={`col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3`}>
                    {!isMobile && <FanzineNewsCard
                        news={item}
                        imageHeight={'200px'}
                        cardTextSize={''}
                        isNewsPage={false}
                        showNewsBelow={true}
                        publisherIconSize={''}
                        isRightAds={true}
                        isMain={''}
                    />}
                    {isMobile &&
                        <FanzineSportsNewsCard
                            imageHeight={index === 0 ? '405px' : '123px'}
                            news={item}
                            showNewsSide={index !== 0}
                        />
                    }
                    {
                        index % 5 === 0 &&
                        <GoogleAd id={'4009154580'} height={'50px'}
                                  minHeight={'50px'}/>
                    }
                  </div>
              ))
            }
          </div>
        </div>
    );
  };

  const renderMain = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    return (
        <div>

          {
              loading && <Loader loading={loading}/>
          }

          <div className={'bg-white '}>
            {/*<div className="d-flex justify-content-center align-items-center">*/}

            {/*  {!isMobile && <GoogleAd*/}
            {/*      height={'90px'}*/}
            {/*      width={'720px'}*/}
            {/*      minWidth={'720px'}*/}
            {/*      minHeight={'90px'}*/}
            {/*      id={'3349434796'}/>}*/}

            {/*</div>*/}

            {isMobile ?
                <FanzineSportsVideosOnSlider
                    endpoint={FOOTBALL.HighlightsFanzineVideosEndPoint +
                        '&league_id=' + (leagueData?.leagueID || '1204')}
                    isMobile={isMobile} title={'Match Highlights'}
                />
                : <FanzineSportsGoalHighlights
                    endpoint={FOOTBALL.GOALFanzineVideosEndPoint +
                        '&league_id=' + (leagueData?.leagueID || '1204')}
                    setSelectedVideo={setSelectedVideo}
                    selectedVideo={selectedVideo}
                    isMobile={isMobile}
                />}

            {isMobile ?
                <FanzineSportsVideosOnSlider
                    isTypeGoal={true}
                    endpoint={FOOTBALL.GOALFanzineVideosEndPoint +
                        '&league_id=' + (leagueData?.leagueID || '1204')}
                    isMobile={isMobile} title={'Goal Highlights'}
                />

                : <FanzineSportsSliderNoBackground
                    setSelectedVideo={setSelectedVideo}
                    endpoint={FOOTBALL.HighlightsFanzineVideosEndPoint +
                        '&league_id=' + (leagueData?.leagueID || '1204')}
                    leagueName={'MATCH HIGHLIGHTS'}
                    leagueId={'1204'}/>}

            {!isMobile && <TopStories isMobile={isMobile}/>}

            {
                isMobile && !latestNewsLoading &&
                <FanzineSportsNewsLayout news={latestNews[0]}
                                         title={'Latest News'} isMobile={true}/>
            }

            <div className={`bg-white pb-3`}>
              <GoogleAd id={'4009154580'} height={'50px'} minHeight={'50px'}/>

              {<div
                  className={'container pb-3  text-black sfProDisplayFontBold font-20'}
              >
                <img src={require(
                    '../../../assets/images/fanzineSports/clock-fill_black.png')}
                     alt={'icon'} height={17} width={17}
                     className={'me-2'}/>
                {'More News'}
              </div>
              }

              {renderMoreNews()}

              <GoogleAd id={'4009154580'} height={'50px'} minHeight={'50px'}/>
            </div>
          </div>
        </div>
    );

  };

  return <>
    <FanzineSportsNavBar/>
    <FanzineSportsHomeSubNavBar isMobile={isMobile}/>
    {renderMain()}
    <Footer/>
  </>;

};

export default FanzineSportsHome;
