import Skeleton from 'react-loading-skeleton';

const LeftNewsSkeleton = () => {

  return (
      <>
      <div
          className={'position-absolute mt-n5 bg-fanzine-dark robotoCondensedBold font-28 container'}
          style={{
            zIndex: 1,
            width: '250px',
            transform: 'skew(-10deg)',
          }}
      >
        <Skeleton count={1}/>
      </div>
  <hr style={{height: '3px'}}/>
  <div className={`col-xxl-8 col-xl-8 mt-2 col-lg-8 col-md-8 col-sm-12`}>
    <div className={`w-100`}>
      <div className={'row '}>
        <div
            className={`col-xl-6 m-0 p-1 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
        >
          <Skeleton height={277} count={2}/>
        </div>
        <div
            className={`col-xl-6 m-0 p-1 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
        >
          <Skeleton height={277} count={2}/>
        </div>
      </div>
    </div>
  </div>
      </>
)

};

export default LeftNewsSkeleton;
