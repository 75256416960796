export const TeamNewsEndPoint = "/api-almet/v2.0/Football/news?team_type=team";
export const NewsEndPoint = "/api-almet/v2.0/Football/news";
export const SocialEndPoint = "/api-almet/v2.0/Football/news/social";
export const GossipEndPoint = "/api-almet/v2.0/Football/news/gossips";
export const PodcastEndPoint = "/api-almet/v2.0/Football/podcasts";
export const HomePageVideoEndPoint = "/api-almet/v2.0/Football/videos";
export const FootballLeague = "/api-almet/v2.0/Football/news/leagues";
export const SportsList = "/api-almet/v2.0/sports/list";
export const AppsList = "/api-almet/v2.0/apps";
export const HomepageFixtures = '/api-almet/v2.0/Football/homePageMatches';
export const HomepageStandings = '/api-almet/v2.0/Football/match/standings';
export const CampaignEndPoint = "/api-almet/v2.0/Football/campaigns1";
export const CampaignStoreResponseEndPoint = "/api-almet/v2.0/Football/campaigns1";

export const StoreCategories = '/api-almet/v2.0/Football/product/categories';
export const ProductColors = '/api-almet/v2.0/Football/product/colors';
export const ProductSizeChart = '/api-almet/v2.0/Football/product/sizes/';
export const ShippingZonesEndPoint = "/api-almet/v2.0/proxy/myshopify/shipping_zones.json";
export const ShopImagesEndpoint = '/api-almet/v2.0/Football/shop/images/';
export const OneLinkEndPoint = '/api-almet/v2.0/onelink';
export const WebLinks = '/api-almet/v2.0/app/init';
export const ContactUsEndpoint = '/api-almet/v2.0/contact-us/send-email';


export const FANZINE_SPORTS = {
    HIGHLIGHTS: '/api-almet/v2.0/Football/highlights',
    LOGIN: '/api-almet/v2.0/telecom/user/login'
}

export const FANZINE = {

    NavBarEndPoint: '/api-almet/v2.0/sports/teams/list1',
    AllTeamsEndPoint: '/api-almet/v2.0/Football/allTeams',

    BannersEndPoint: "/api-almet/v2.0/Football/fanzine/banners",
    LeaguesEndPoint: "/api-almet/v2.0/Football/leagues",
    MatchesEndPoint: "/api-almet/v2.0/Football/matches",
    MatchesLiveEndPoint: "/api-almet/v2.0/Football/matches/live",
    MatchesByDateEndPoint: "/api-almet/v2.0/Football/leagues/",

    //standings
    SportsStandingsEndPoint: '/api-almet/v2.0/__sport__/matches/standings',

    //NFT
    // modal teams dropdown
    TeamsEndPoint: "/api-almet/v2.0/wc/teams",
    // modal submit selection
    NFTINTRESTEndPoint: "/api-almet/v2.0/Football/campaign/NFT_INTERESTS",

    //Single Match Endpoints
    SingleMatchEndPoint: "/api-almet/v2.0/Football/matches/",

    //Single Match Sub Endpoints
    MatchesLineupsSubEndPoint: "/lineups",
    MatchesSideLinedSubEndPoint: "/sidelined",
    MatchesPreviewSubEndPoint: "/preview",
    MatchesOverviewSubEndPoint: "/overview",
    MatchesAnalysisSubEndPoint: "/analysis",
    MatchesWidgetsSubEndPoint: "/widgets",
    MatchPlayerStatsMainEndPoint: '/player/',
    MatchesPlayerStatsSubEndPoint: "/stats",
    //SingleMatch
    MatchStats: '/api-almet/v2.0/Football/match/stats',
    MatchCommentary: '/api-almet/v2.0/match/commentaries',
    MatchOverviewEvents: '/api-almet/v2.0/match/events',
    MatchSocial: '/api-almet/v2.0/news/match/social/',
    MatchEndpoint: '/api-almet/v2.0/Football/match/',

    //Fanatics
    FanaticsShopNowEndPoint: "/api-almet/v2.0/wc/fanatics/banners",
    FanaticsDetailEndPoint: "/api-almet/v2.0/wc/fanatics/products/",

    NFTEndPoint: "/api-almet/v2.0/wc/nft/",

};

// design v2
export const FOOTBALL = {
    // NewsEndPoint: '',
    LatestVideosEndPoint:'/api-almet/v2.0/Football/videos?limit=15',
    LatestClubVideosEndPoint:'/api-almet/v2.0/Football/videos?main_site=0&limit=15',
    LatestFanzineVideosEndPoint:'/api-almet/v2.0/Football/videos?main_site=1&limit=15', // latest videos default
    HighlightsFanzineVideosEndPoint:'/api-almet/v2.0/Football/videos/highlights?limit=15',// default prem || league_id league highlights  // goal highlights
    GOALFanzineVideosEndPoint:'/api-almet/v2.0/Football/videos/highlights?limit=15&type=goal',// default prem || league_id league highlights  // goal highlights
    VideoEndPoint: '/api-almet/v2.0/Football/videos',
    AllLeaguesEndPoint: '/api-almet/v2.0/Football/news/leagues',
    StaticPageEndPoint:'/api-almet/v2.0/Football/explore',
    TransferNewsEndPoint: '/api-almet/v2.0/Football/news?league_id=11',
    LatestNewsEndPoint: '/api-almet/v2.0/Football/news',
    TopStoresEndPoint: '/api-almet/v2.0/Football/news?top_stories=1',
    HomepageMatchesEndPoint:'/api-almet/v2.0/Football/homePageMatches'
}

// end design v2

export const CRICKET = {
    NewsEndPoint: "/api-almet/v2.0/Cricket/news",
    VideoEndPoint: "/api-almet/v2.0/Cricket/videos",
    HomepageMatchesEndPoint: "/api-almet/v2.0/Cricket/homePageMatches",
    MatchesEndPoint: "/api-almet/v2.0/Cricket/matches",
};

export const MLB = {
    NewsEndPoint: "/api-almet/v2.0/MLB/news",
    VideoEndPoint: "/api-almet/v2.0/MLB/videos",
    HomepageMatchesEndPoint: "/api-almet/v2.0/MLB/homePageMatches",
    MatchesEndPoint: "/api-almet/v2.0/MLB/matches",
};

export const NBA = {
    NewsEndPoint: "/api-almet/v2.0/NBA/news",
    VideoEndPoint: "/api-almet/v2.0/NBA/videos",
    HomepageMatchesEndPoint: "/api-almet/v2.0/NBA/homePageMatches",
    MatchesEndPoint: "/api-almet/v2.0/NBA/matches",
};

export const NCAA = {
    NewsEndPoint: "/api-almet/v2.0/NCAA/news",
    VideoEndPoint: "/api-almet/v2.0/NCAA/videos",
    HomepageMatchesEndPoint: "/api-almet/v2.0/NCAA/homePageMatches",
    MatchesEndPoint: "/api-almet/v2.0/NCAA/matches",
};

export const NFL = {
    NewsEndPoint: "/api-almet/v2.0/NFL/news",
    VideoEndPoint: "/api-almet/v2.0/NFL/videos",
    HomepageMatchesEndPoint: "/api-almet/v2.0/NFL/homePageMatches",
    MatchesEndPoint: "/api-almet/v2.0/NFL/matches",
    MatchesWeeks: "/api-almet/v2.0/NFL/matches/weeks",
};

export const FBS = {
    NewsEndPoint: "/api-almet/v2.0/FBS/news",
    VideoEndPoint: "/api-almet/v2.0/FBS/videos",
    HomepageMatchesEndPoint: "/api-almet/v2.0/FBS/homePageMatches",
    MatchesEndPoint: "/api-almet/v2.0/FBS/matches",
    MatchesWeeks: "/api-almet/v2.0/FBS/matches/weeks",
};

/*
*
* Shopify Urls
*
* */
export const STORE_BASE_URL = 'https://fanzine-store.myshopify.com/api/graphql';
