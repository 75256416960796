import MobileBackground from '../../../assets/images/LoginBG.png';
import DesktopBackground from '../../../assets/images/fanzineSportsHomeBG.png';
import moment from 'moment-timezone';
import {useEffect, useState} from 'react';
import {get} from '../../../Axios/get';
import {
  CRICKET,
  FANZINE, FBS,
  FOOTBALL, MLB,
  NBA,
  NCAA,
  NFL,
} from '../../../Axios/EndPoints';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

const ClubOverview = (props) => {
  const {isMobile,teamData} = props;

  const location = useLocation();
  const [sidelined,setSidelined] =useState([]);
  const [nextMatchData,setNextMatchData] = useState(null);

  const addDefaultSrc = (ev) => {
    ev.target.src = require("../../../assets/images/default-league.png");
  };
  const getPlayerData = (value) => {
    console.log('value in player data=', value);
    // let player_id = value?.id || value?.on_id;
    // get(FANZINE.SingleMatchEndPoint + params?.id +
    //     FANZINE.MatchPlayerStatsMainEndPoint + player_id +
    //     FANZINE.MatchesPlayerStatsSubEndPoint).then((res) => {
    //   console.log('res =', res.data.data);
    //   setPlayerData(res.data.data);
    //   setShow(!show);
    // }).catch((error) => console.error(error));
  };

  const fetchLineups = (data) => {
    get(FANZINE.SingleMatchEndPoint + data.id + FANZINE.MatchesLineupsSubEndPoint).then((res) => {
      // setLineupsData(res.data);
      setSidelined(res.data);
    }).catch((error) => {
      console.error(error);
    });
  };

  const fetchMatches = (data) => {
    let id = (data?.id || '');
    let parameters = '?team_id=' + id;

    let URL = FOOTBALL.HomepageMatchesEndPoint;

    URL += parameters;

     get(URL)
        .then((res) => {
          setNextMatchData(res?.data?.data);
        })
        .catch((error) => console.error('error while getting matchesData =',
            error));

  };

  useEffect(()=>{

    if(teamData)
    {
      fetchLineups(teamData);
      fetchMatches(teamData);
    }

  },[teamData])

  const RenderNextMatch = (props) => {

    const  {matchData} = props;

    console.log('matchData =', matchData)

    return (
        <div
            style={{
              width: '355px',
              height: '160px',
              padding: '2px 20px 0 15px',
              // opacity: 0.7,
              // backgroundColor: '#000',
              color: 'white',
              borderRadius: '15px',
              border: 'solid 1px #fff',
              backgroundImage: `url(${matchData[0]?.next_match?.venue?.stadium_image || isMobile ?
                  MobileBackground :
                  DesktopBackground})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            className={'container ms-auto me-auto mt-3 mb-3'}
        >
          <div className={'row'}>
            <div
                className={'col-6 d-flex align-items-center justify-content-start'}>
              <p
                  className={'font-20 sfProDisplayFontBold pt-1'}
                  style={{
                    fontWeight: 800,
                  }}
              >
                Next Match
              </p>
            </div>
            <div
                className={'col-6 d-flex align-items-center justify-content-end'}>
              <p
                  className={'font-11 sfProDisplayFont pe-2 pt-1'}
              >
                { matchData[0]?.next_match?.league_name || 'Premier League'}
              </p>
            </div>
            <div className={'col-4'}>
              <div className={'d-flex justify-content-end'}>
                <img src={matchData[0]?.next_match?.home_team?.club_logo || require(
                    '../../../assets/images/team_icons/cfc-blues/cfc-blues.png')}
                     alt={'hometeam-icon'}
                     style={{
                       maxWidth: 47,
                       maxHeight: 47,
                       objectFit: 'cover',
                     }}
                     className={'img-fluid'}/>
              </div>
              <div className={'d-flex justify-content-end'}>
                <p
                    className={'font-14 sfProDisplayFont'}
                >
                  {matchData[0]?.next_match?.home_team?.name}
                </p>
              </div>
            </div>
            <div className={'col-4'}>
              <div className={'row'}>
                <div className={'col-12 d-flex justify-content-center'}>
                  <p className={'font-11 sfProDisplayFont p-0 m-0'}>{moment(
                      new Date()).format('ddd DD MMMM')}</p>
                </div>
                <div className={'col-12 d-flex justify-content-center'}>
                  <p className={'sfProDisplayFont font-30 p-0 m-0'}>{moment(
                      new Date()).format('HH:mm')}</p>
                </div>
                <div className={'col-12 d-flex justify-content-center'}>
                  <div
                      style={{
                        width: 72,
                        height: 25,
                        borderRadius: 13,
                        border: 'solid 1px #fff',
                      }}
                      className={'d-flex align-items-center  justify-content-center mt-2'}
                  >
                    <p className={'p-0 m-0 sfProDisplayFont font-12'}>
                      Preview
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={'col-4'}>
              <div className={'d-flex justify-content-start'}>
                <img src={matchData[0]?.next_match?.guest_team?.club_logo || require(
                    '../../../assets/images/team_icons/cfc-blues/cfc-blues.png')}
                     style={{
                       maxWidth: 47,
                       maxHeight: 47,
                       objectFit: 'cover',
                     }}
                     alt={'awayteam-icon'} className={'img-fluid'}/>
              </div>
              <div className={'d-flex justify-content-start'}>
                <p
                    className={'font-14 sfProDisplayFont'}
                >
                  {matchData[0]?.next_match?.guest_team?.name}
                </p>
              </div>

            </div>
            <div className={'col-12'}></div>
          </div>

        </div>
    );
  };
  const RenderRecentForm = () => {
    return (
        <div
            style={{
              width: '355px',
              height: '143px',
              margin: '15px 10px 14.7px',
              padding: '15px 14.9px 20px 15px',
              borderRadius: '15px',
              backgroundColor: '#fff',
            }}
            className={'container ms-auto me-auto'}
        >
          <div className={'row'}>
            <div className={'col-12'}>
              <p className={'sfProDisplayFontBold font-20'}>
                Recent Form
              </p>
            </div>
              {[1, 2, 3, 4, 5].map((value, index) => (
                <div
                style={{
                  flex: '0 0 auto',
                  width: '20%',
                }}
                >
                  <div className={'d-flex justify-content-center'}>
                    <img src={require(
                        '../../../assets/images/team_icons/cfc-blues/cfc-blues.png')}
                         alt={'team-icons'}
                         style={{
                           maxWidth: 39,
                           maxHeight: 39,
                           objectFit: 'cover',
                         }}
                         className={'img-fluid'}
                    />
                  </div>
                  <div
                      className={'d-flex justify-content-center align-items-center'}>
                    <p
                        style={{
                          width: '39px',
                          height: '21px',
                          borderRadius: '4px',
                          backgroundColor: (index === 0 || index === 3)  ? '#0e7d45' : index === 1 ?  '#e02020' : '#c6c6c6',
                        }}
                        className={'sfProDisplayFont text-white font-12 align-items-center d-flex justify-content-center'}>
                      3-1
                    </p>
                  </div>
                </div>
            ))}
          </div>
        </div>
    );
  };

  const renderHomeGoalKeeper = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-4'}`}>
          {sidelined !== undefined &&
              [sidelined?.localteam?.squad?.goalkeeper]?.map(
                  (players, index) => (
                      <div className={'col'}
                           onClick={() => getPlayerData(players)}

                           key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                        </span>
                        <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                            'font-10' :
                            'font-10'}`}
                           style={{color: '#000000'}}>
                          {players?.player_number + '. '}{players?.name}
                        </p>
                      </div>
                  ))}
        </div>
    );
  };

  const renderHomeDefenders = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-4'}`}>
          {sidelined !== undefined &&
              sidelined?.localteam?.squad?.line1?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}

                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderHomeMidfielders = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-3'}`}>
          {sidelined !== undefined &&
              sidelined?.localteam?.squad?.line2?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}

                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderHomeWingers = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-3'}`}>
          {sidelined !== undefined &&
              sidelined?.localteam?.squad?.line3?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}
                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderHomeStrikers = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-3'}`}>
          {sidelined !== undefined &&
              sidelined?.localteam?.squad?.line4?.map((players, index) => (
                  <div
                      onClick={() => getPlayerData(players)}
                      className={'col'}
                      key={index}>
                    <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                      {players?.yellowcards != 0 &&
                          renderIconsOnBench('yellowcard')}
                      {players?.redcards != 0 &&
                          renderIconsOnBench('redcard')}
                      {players?.goals != 0 && renderIconsOnBench('goal')}
                      <img src={players?.icon}
                           className={'player-unavailable ms-auto me-auto d-flex'}
                           alt={'player'}
                           height={isMobile ? '34px' : '52px'}
                           width={'auto'}
                           onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderHomeTeamFormation = () => {
    return (
        <div className={` ${sidelined?.localteam?.squad?.line4 ?
            'mt-5' :
            'mt-3'}`}>
          {renderHomeStrikers()}
          {renderHomeWingers()}
          {renderHomeMidfielders()}
          {renderHomeDefenders()}
          {renderHomeGoalKeeper()}
        </div>
    );
  };

  const renderIconsOnBench = (value, isBench) => {

    const renderSubIcon = () => {

      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginRight: '40px',
            display: 'none',
          };

      return (
          <img
              src={require(
                  '../../../assets/images/subEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    const renderYellowCardIcon = () => {
      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginLeft: '40px',
            display: 'none',
          };

      return (
          <img
              src={require(
                  '../../../assets/images/yellowEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    const renderRedCardIcon = () => {
      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginLeft: '40px',
            display: 'none',

          };
      return (
          <img
              src={require(
                  '../../../assets/images/yellowEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    const renderGoalIcon = () => {
      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginLeft: '50px',
            display: 'none',

          };

      return (
          <img
              src={require(
                  '../../../assets/images/goalEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    const renderAssistIcon = () => {
      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginRight: '40px',
          };
      return (
          <img
              src={require(
                  '../../../assets/images/assisstEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    switch (value) {
      case 'subs' :
        return renderSubIcon();
      case 'yellowcard':
        return renderYellowCardIcon();
      case 'redcard':
        return renderRedCardIcon();
      case 'goal':
        return renderGoalIcon();
      case 'assist':
        return renderAssistIcon();
      default:
        console.log('error no arg send to func renderIconsOnBench');
        break;
    }
  };

  const RenderStartingXI = () => {
    return (
        <div
            className={'ms-auto me-auto'}
        style={{
          width: '357px',
          height: '537.9px',
          borderRadius: '15px',
          backgroundColor: '#fff',
        }}>
            <div className={'ps-3'}>
            <p
            className={'font-20 sfProDisplayFontBold pt-2'}>
              Last Starting XI
            </p>
          </div>
            <div className={'p-0 m-0 bg-dark-green'}>
            <img src={require('../../../assets/images/fanzineSports/component_595_1@3x.png')}
                alt={'football-field'} className={'img-fluid'}
                 style={{
                   maxWidth:356,
                   maxHeight:473,
                   objectFit:'contain',
                   // marginLeft:'-3.75%',
                 }}
                 />
              <div className={'position-absolute'}>
                {renderHomeTeamFormation()}
              </div>
          </div>
        </div>
    );
  };

  return (
      <div
          style={{
            background: '#ededed',
          }}
          className={'vh-100 text-black'}>
        {nextMatchData && <RenderNextMatch matchData={nextMatchData} />}
        <RenderRecentForm/>
        <RenderStartingXI/>
      </div>
  );
};

export default ClubOverview;
