import Slider from 'react-slick';
import React, {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../../Configs/Constants';
import '../../assets/slider/HomePageCarousel.css';

const Carousel = (props) => {
  const {isMobile}= props;

  const [nextArrowPadding,setNextArrowPadding] = useState('24.5%')
  const [prevArrowPadding,setPrevArrowPadding] = useState('21.5%')
  const [isLargeScreen,setIsLargeScreen] = useState(false);

  useEffect(() => {
    if(window.screen.width >= 2550)
    {
      setIsLargeScreen(true);
      setPrevArrowPadding('21%')
      setNextArrowPadding('23.5%')
    }
    else if(window.screen.width >= 1360 && window.screen.width < 1920)
    {
      setPrevArrowPadding('22.5%')
      setNextArrowPadding('25.5%')

    }


  }, [window.screen.width]);


  function SampleNextArrow(props) {
    const {className, onClick} = props;
    let style = {
      height: isMobile ? '36px' :'100%',
      paddingLeft: isMobile ? '0px' : nextArrowPadding,
      marginLeft: isMobile ? '0px' : '25px',
    };

    return (
        <div
            className={className}
            style={{
              ...style,
              display: 'block',
              background: 'white',
              opacity: '0.2',
              zIndex: 10,
            }}
            onClick={onClick}
        />
    );
  }

  function SamplePrevArrow(props) {
    const {className, onClick} = props;

    let style = {
      height: isMobile ? '36px' :'100%',
      paddingLeft: isMobile ? '0px' : prevArrowPadding,
      marginLeft: isMobile ? '0px' : '25px',
    };

    return (
        <div
            className={className}
            style={{
              ...style,
              display: 'block',
              background: 'white',
              opacity: '0.2',
              zIndex: 10,
            }}
            onClick={onClick}
        />
    );
  }

  const renderSlide1 = () => {
    return (
        <div className={`${isMobile ? 'ps-3 pe-3' : 'ps-1 pe-1'}`}>
          <img
              className={'img-fluid'}
              style={{height: isLargeScreen ? 'auto': '590px', objectFit: isMobile ? 'cover' : 'contain'}}
              src={isMobile ?
                  require(
                      '../../assets/images/new-fanzine-slider/image-slider-mobile-wrexham.png') :
                  require(
                      '../../assets/images/new-fanzine-slider/slider-image-wrexham.png')}
              alt={'img-1'}/>
        </div>
    );
  };
  const renderSlide2 = () => {
    return (
        <div className={`${isMobile ? 'ps-3 pe-3' : 'ps-1 pe-1'}`}>
          <img
              className={'img-fluid'}
              style={{height: isLargeScreen ? 'auto': '590px', objectFit: isMobile ? 'cover' : 'contain'}}
              src={isMobile ?
                  require(
                      '../../assets/images/new-fanzine-slider/image-slider-mobile-madrid.png') :
                  require(
                      '../../assets/images/new-fanzine-slider/slider-image-madrid.png')}
              alt={'img-1'}/>
        </div>
    );
  };
  const renderSlide3 = () => {
    return (
        <div className={`${isMobile ? 'ps-3 pe-3' : 'ps-1 pe-1'}`}>
          <img
              className={'img-fluid'}
              style={{height: isLargeScreen ? 'auto': '590px', objectFit: isMobile ? 'cover' : 'contain'}}
              src={isMobile ?
                  require(
                      '../../assets/images/new-fanzine-slider/image-slider-mobile-psg.png') :
                  require(
                      '../../assets/images/new-fanzine-slider/slider-image-psg.png')}
              alt={'img-1'}/>
        </div>
    );
  };
  const renderSlide4 = () => {
    return (
        <div className={`${isMobile ? 'ps-3 pe-3' : 'ps-1 pe-1'}`}>
          <img
              className={'img-fluid'}
              style={{height: isLargeScreen ? 'auto': '590px', objectFit: isMobile ? 'cover' : 'contain'}}
              src={isMobile ?
                  require(
                      '../../assets/images/new-fanzine-slider/image-slider-mobile-juve.png') :
                  require(
                      '../../assets/images/new-fanzine-slider/slider-image-juve.png')}
              alt={'img-1'}/>
        </div>
    );
  };
  const renderSlide5 = () => {
    return (
        <div className={`${isMobile ? 'ps-3 pe-3' : 'ps-1 pe-1'}`}>
          <img
              className={'img-fluid'}
              style={{height: isLargeScreen ? 'auto': '590px', objectFit: isMobile ? 'cover' : 'contain'}}
              src={isMobile ?
                  require(
                      '../../assets/images/new-fanzine-slider/image-slider-mobile-barca.png') :
                  require(
                      '../../assets/images/new-fanzine-slider/slider-image-barca.png')}
              alt={'img-1'}/>
        </div>
    );
  };
  const renderSlide6 = () => {
    return (
        <div className={`${isMobile ? 'ps-3 pe-3' : 'ps-1 pe-1'}`}>
          <img
              className={'img-fluid'}
              style={{height: isLargeScreen ? 'auto': '590px', objectFit: isMobile ? 'cover' : 'contain'}}
              src={isMobile ?
                  require(
                      '../../assets/images/new-fanzine-slider/image-slider-mobile-wwc.png') :
                  require(
                      '../../assets/images/new-fanzine-slider/slider-image-wwc.png')}
              alt={'img-1'}/>
        </div>
    );
  };
  const renderSlide7 = () => {
    return (
        <div className={`${isMobile ? 'ps-3 pe-3' : 'ps-1 pe-1'}`}>
          <img
              className={'img-fluid'}
              style={{height: isLargeScreen ? 'auto': '590px', objectFit: isMobile ? 'cover' : 'contain'}}
              src={isMobile ?
                  require(
                      '../../assets/images/new-fanzine-slider/image-slider-mobile-bayern.png') :
                  require(
                      '../../assets/images/new-fanzine-slider/slider-image-bayern.png')}
              alt={'img-1'}/>
        </div>
    );
  };
  const renderSlide8 = () => {
    return (
        <div className={`${isMobile ? 'ps-3 pe-3' : 'ps-1 pe-1'}`}>
          <img
              className={'img-fluid'}
              style={{height: isLargeScreen ? 'auto': '590px', objectFit: isMobile ? 'cover' : 'contain'}}
              src={isMobile ?
                  require(
                      '../../assets/images/new-fanzine-slider/image-slider-mobile-inter.png') :
                  require(
                      '../../assets/images/new-fanzine-slider/slider-image-inter.png')}
              alt={'img-1'}/>
        </div>
    );
  };

  const settings = {
    dots: true,
    className: isMobile ? '' : 'ms-auto me-auto',
    centerMode: !isMobile,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    // centerPadding: "50px",
    // height:'590px',
    swipe: true,

    slidesToShow: isMobile ? 1 :  1.68,
    speed: 500,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
  };

  return (
      <div className={'bg-white container-fluid'}>
        <Slider
            style={{width: isMobile ? '100%' : '98%'}}
            {...settings}>
          {renderSlide1()}
          {renderSlide2()}
          {renderSlide3()}
          {renderSlide4()}
          {renderSlide5()}
          {renderSlide6()}
          {renderSlide7()}
          {renderSlide8()}
        </Slider>
      </div>
  );
};

export default Carousel;

