import React, {useEffect, useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../../assets/styles/styles.css';
import '../../assets/styles/responsive/stylesLaptop.css';
import '../../assets/styles/responsive/stylesTablet.css';
import '../../assets/styles/responsive/stylesMobile.css';
import '../../assets/styles/footballTargets.css';
import '../../assets/styles/fanzine.css';
import {COLORS, LOGO, NAV_CLASS, TEAM_NAME, TEXT_COLOR,} from '../../Configs/Targets';
import {SCREEN_SIZE} from '../../Configs/Constants';
import {Col, NavDropdown, NavItem, Row,} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {getHostname} from '../../CustomFunctions/getHostname';
import useComponentVisible from '../../CustomFunctions/useComponentVisible';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faInstagram, faTwitter,} from '@fortawesome/free-brands-svg-icons';
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import {slide as Menu} from 'react-burger-menu';
import SingleSportNavItem from "./SingleSportNavItem";

const FanzineNav = () => {
    let footballTable = [];
    const store = useSelector((state) => state);
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(
        true);

    const [width, setWidth] = useState(window.screen.width);
    const [showCart, setShowCart] = useState(false);
    const [navShadow, setNavShadow] = useState('navShadow');

    const updateScreenWidth = () => {
        setWidth(window.screen.width);
    };

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const route = window.location.pathname;
    const navColor = NAV_CLASS[target];
    const navColorDark = NAV_CLASS[target] + '-dark';
    const navColorHover = NAV_CLASS[target] + '-hover-color';
    const fontColor = TEXT_COLOR[target];
    const navName = TEAM_NAME[target];
    const color = COLORS[target];

    let [navLogo, setNavLogo] = useState(LOGO[target]);
    let [imageProps, setImageProps] = useState({});
    let [isMobile, setIsMobile] = useState(false);
    let [showDownloadBanner, setShowDownloadBanner] = useState(false);
    let [selectedSport, setSelectedSport] = useState(false);
    // const [show]
    let [isCollapsed, setIsCollapsed] = useState(true);
    let topNavBarDropdown = {width: '100%'};
    if (isMobile) {
        topNavBarDropdown = {
            width: '100%',
        };
    }

    useEffect(() => {
        setNavShadow('navShadow');
        setShowCart(false);
        if (route.includes('shop')) {
            setNavShadow('shadow-nav-dark');
            setShowCart(true);
        }
    }, [route]);

    useEffect(() => {
        const navLogoSmall = LOGO[target];
        const navLogoBig = LOGO[target];

        if (window.screen.width <= SCREEN_SIZE.TABLET) {
            setShowDownloadBanner(true);
        }
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setNavLogo(navLogoSmall);
            setImageProps({
                height: 40 /*50*/,  //as per new req of Jake
            });
            setIsMobile(true);
        } else {
            setNavLogo(navLogoBig);
            if ([
                'the-toffees',
                'unitedreds',
                'coys',
                'playuppompey',
                'foresttilidie',
                'comeonderby',
                'fanzinesports'].includes(target)) {
                setImageProps({
                    style: {marginTop: '-2em'}, height: 85,
                });
            } else if (['fanzine'].includes(target)) {
                setImageProps({
                    style: {marginTop: '2.5em'}, height: 120, width: 110,
                });
            } else {
                setImageProps({
                    height: 75,
                });
            }
            setIsMobile(false);
        }
        if (target !== 'fanzinesports' &&
            !document.getElementById('react-burger-menu-btn')
                .classList
                .contains('collapsed')) {
            document.getElementById('react-burger-menu-btn').click();
        }
    }, [window.screen.width]);

    useEffect(() => {
        if (isMobile && target !== 'fanzinesports') {
            if (!isCollapsed) {
                document.getElementById(
                    'react-burger-menu-btn').innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 fa-2x"' +
                    ' role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>';
                document.getElementById(
                    'responsive-navbar-nav-fanzine').style = 'display: block !important;position: fixed; right: 0px; z-index: 1100; width: 50%; height: 100%; transition: all 0.5s ease 0s;';
                document.getElementById('overlay').style = 'display: block !important;';

                try {
                    document.getElementById('subNavbarBg').style.zIndex = '1100';
                    let topMenuDd = document.getElementById('top-menu-dropdown');
                    let isOpen = topMenuDd.classList.contains('show');
                    if (!isOpen) {
                        document.getElementById('subNavbarBg').style.zIndex = '1';
                    } else {
                        document.getElementById('subNavbarBg').style.zIndex = '1100';
                    }
                } catch (e) {

                }
            } else {
                document.getElementById(
                    'react-burger-menu-btn').innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-11 fa-2x' +
                    '" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163' +
                    ' 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>';
                document.getElementById(
                    'responsive-navbar-nav-fanzine').style = 'display: none !important;';
                document.getElementById('overlay').style = 'display: none !important;';
                // document.getElementById('react-burger-menu-btn').innerHTML = "<FontAwesomeIcon icon={'bars'} className={fontColor}/>";
            }
        }
    }, [isCollapsed]);

    const handleCollapse = () => {
        // document.getElementsByClassName('nav-mega-menu-fanzine')[0].closest('.dropdown-menu').previousSibling.click();
        document.getElementById('react-burger-menu-btn').click();
    };

    const handleMainCollapse = () => {
        document.getElementById('react-burger-menu-btn').click();
    };

    const handleMainNavClick = (id) => {
        document.getElementById('subNavbar').style.zIndex = '1100';
        let topMenuDd = document.getElementById(id);
        let isOpen = topMenuDd.classList.contains('show');
        if (!isOpen) {
            document.getElementById('subNavbar').style.zIndex = '1';
        } else {
            document.getElementById('subNavbar').style.zIndex = '1100';
        }
    }

    const renderSingleSport = (sport, index) => {

        if(sport.name === 'NCAAF')
        {
            return;
        }

        if (sport?.leagues?.length) {
            return (
                <SingleSportNavItem
                    isMobile={isMobile}
                    fontColor={fontColor}
                    sport={sport}
                    topNavBarDropdown={topNavBarDropdown}
                    handleMainNavClick={handleMainNavClick}
                    handleCollapse={handleCollapse}
                    id={`sportNav${index}`}
                    fanzineTeamsLeagues={store?.football?.fanzineTeamsLeagues}
                    index={index}
                    renderMore={false}
                    handleMainCollapse={handleMainCollapse}
                />
            );
        } else {
            return (
                <NavItem
                    key={index}
                    id={`top_menu_dropdown_${index}`}
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ?
                        'ps-4 pt-2' :
                        'ps-3 pe-3'}`}>
                    <NavLink to={`/${sport?.name?.toLowerCase()}`}
                             className={`${fontColor} text-decoration-none ${isMobile ?
                                 'bebasFont' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        {sport?.name}
                    </NavLink>
                </NavItem>
            );
        }
    };

    const renderSports = () => {
        let sportLimit = 8;
        return (
            <>
                {store?.football?.fanzineTeamsLeagues?.length ?
                    store?.football?.fanzineTeamsLeagues?.map((fanzineTeamsLeague, index) => (
                        index < sportLimit && renderSingleSport(fanzineTeamsLeague, index)
                    )) : <></>}
                {
                    selectedSport ?
                        <SingleSportNavItem
                            isMobile={isMobile}
                            fontColor={'text-white'}
                            sport={selectedSport}
                            topNavBarDropdown={topNavBarDropdown}
                            handleMainNavClick={handleMainNavClick}
                            handleCollapse={handleCollapse}
                            index={999}

                            fanzineTeamsLeagues={store?.football?.fanzineTeamsLeagues}
                            renderWithoutDropdown={selectedSport?.leagues?.length === 0}
                            handleMainCollapse={handleMainCollapse}
                        />
                        : <></>
                }
                {/*{renderOther(store?.football.fanzineTeamsLeagues.slice(sportLimit))}*/}
            </>
        );
    };

    const renderOther = (sports = []) => {
        return (
            <NavDropdown className={` ${isMobile ? 'pt-2' : 'pt-2 ps-3 pe-3'}`}
                         title={<span className={`${fontColor} ${isMobile ?
                             'bebasFont ps-4 pt-2' :
                             'sfProDisplayFontBold font-16'}`}>{'Other Sports'}</span>}
                         renderMenuOnMount={true}
                         style={topNavBarDropdown}
                         id={`top_menu_dropdown_other`}
                         onClick={() => {
                             handleMainNavClick(`top_menu_dropdown_other`)
                         }}
            >
                <div className="pb-2 pt-2 bg-white shadow" style={{zIndex: 1100}}>
                    <div className="w-100">
                        {
                            sports.map((sport, index) => (
                                <SingleSportNavItem
                                    isMobile={isMobile}
                                    fontColor={'#000000'}
                                    sport={sport}
                                    topNavBarDropdown={topNavBarDropdown}
                                    handleMainNavClick={handleMainNavClick}
                                    handleCollapse={handleCollapse}
                                    index={index}
                                    fanzineTeamsLeagues={store?.football?.fanzineTeamsLeagues}
                                    selectedSport={selectedSport}
                                    setSelectedSport={setSelectedSport}
                                    renderMore={true}
                                    handleMainCollapse={handleMainCollapse}
                                />
                            ))
                        }
                    </div>
                </div>
            </NavDropdown>
        );
    }

    const renderMainNav = () => {
        return (<Navbar.Collapse id="responsive-navbar-nav-fanzine"
                                 className={`${navColor} w-100 responsive-navbar-nav-fanzine justify-content-end`}
        >
            {/*<Nav className="me-auto"/>*/}
            <div>
                <Nav
                    className={'justify-content-end float-xxl-end float-xl-end float-lg-end float-md-end float-sm-end p-xxl-0 p-xl-0 p-lg-0 p-md-0 p-sm-0 pe-2 nav-content' +
                        ' float-sm-start align-content-sm-start mt-xxl-2 mt-xl-2 mt-lg-2 container '}>
                    <div className={`d-flex flex-row justify-content-evenly`}>
                        {!isMobile && <NavItem
                            className={`d-flex flex-column  justify-content-center mt-2 ${isMobile ?
                                'ps-4 pt-2' :
                                'ps-3 pe-3'}`}>
                            <NavLink to={'/'}
                                     className={`${fontColor} text-decoration-none ${isMobile ?
                                         'bebasFont' :
                                         'sfProDisplayFontBold font-16'}`}
                                     onClick={handleMainCollapse}>
                                Home
                            </NavLink>
                        </NavItem>}

                        {renderSports()}
                        <NavItem
                            className={`d-flex flex-column justify-content-center mt-2 ${isMobile ?
                                'ps-4 pt-2' :
                                'ps-3 pe-3'}`}>
                            <NavLink to={'/advertisement'}
                                     className={`${fontColor} text-decoration-none ${isMobile ?
                                         'bebasFont' :
                                         'sfProDisplayFontBold font-16'}`}
                                     >
                                Advertising
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className={`d-flex flex-column justify-content-center mt-2 ${isMobile ?
                                'ps-4 pt-2' :
                                'ps-3 pe-3'}`}>
                            <NavLink to={'/shop'}
                                     className={`${fontColor} text-decoration-none ${isMobile ?
                                         'bebasFont' :
                                         'sfProDisplayFontBold font-16'}`}
                                     onClick={handleMainCollapse}>
                                Shop
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className={`d-flex flex-column justify-content-center mt-2 ${isMobile ?
                                'ps-4 pt-2' :
                                'ps-3 pe-3'}`}>
                            <NavLink to={'/contact'}
                                     className={`${fontColor} text-decoration-none ${isMobile ?
                                         'bebasFont' :
                                         'sfProDisplayFontBold font-16'}`}
                                     onClick={handleMainCollapse}>
                                Contact
                            </NavLink>
                        </NavItem>
                    </div>
                </Nav>

                {isMobile && renderSocialNav()}
            </div>
        </Navbar.Collapse>);
    };

    const renderMobileNav = () => {
        return (
            <Menu center className={`bg-black responsive-navbar-nav-fanzine`}
                  width={'100%'} id={'responsive-navbar-nav-fanzine'}>
                <NavItem
                    className={`d-flex flex-column pt-5 m-4 justify-content-center nav-link mt-2 ${isMobile ?
                        'pt-2' :
                        ''} border-bottom`}>
                    <NavLink to={'/'}
                             className={`text-white p-2 text-start text-decoration-none ${isMobile ?
                                 'sfProDisplayFont font-16' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        Home
                    </NavLink>
                </NavItem>
                {/*<NavDropdown className={`p-2 text-start fanzine-nav-responsive border-bottom`}*/}
                {/*             title={<span className={` text-white ${isMobile ? 'sfProDisplayFont font-16' : 'sfProDisplayFontBold font-16'}`}>Sports</span>}*/}
                {/*             id="top-menu-dropdown" renderMenuOnMount={true}>*/}
                {/*    <div className="pt-0 mt-n10 bg-dark">*/}
                {/*        <Row className={`p-0 m-0`}><Col xs="12" md="12"*/}
                {/*                                        className={`text-left col-12 d-flex flex-column justify-content-center p-0`}>*/}

                {/*        </Col>*/}
                {/*        </Row>*/}
                {/*    </div>*/}
                {/*</NavDropdown>*/}
                {/*<NavItem
                className={`d-flex flex-column justify-content-center mt-2 nav-link border-bottom ${isMobile ? 'pt-2' : ''}`}>
                <NavLink to={'/nfts'}
                         className={`text-white text-decoration-none ${isMobile ? 'sfProDisplayFont font-16' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                    NFTs
                </NavLink>
            </NavItem>*/}
                <NavDropdown
                    className={`m-4 border-bottom pt-3  text-start border-0 fanzine-nav-responsive`}
                    style={{border: 'none !important'}}
                    title={<span className={`text-start p-2 text-white ${isMobile ?
                        'sfProDisplayFont font-16' :
                        'sfProDisplayFontBold font-16'}`}>Football</span>}
                    id="top-menu-dropdown" renderMenuOnMount={true}>
                    <div className="pt-0 mt-n10 bg-black">
                        <Row className={`p-0 m-0`}>
                            {store.football.fanzineTeamsLeagues.length &&
                                store.football.fanzineTeamsLeagues.map((item, index) => {
                                    return (<Col key={index} xs="12" md="12"
                                                 className={`text-left d-flex flex-column justify-content-center p-0`}>
                                        {/*<NavLink to={item.paramsLink}
                                                 className={`dropdown-item text-white bg-black text-decoration-none ${isMobile ?
                                                     'pt-2 sfProDisplayFontBold font-16' :
                                                     'sfProDisplayFontBold' +
                                                     ' font-16'} h-100 d-flex flex-column justify-content-center`}
                                                 onClick={handleCollapse}>
                                            <div
                                                className={'flex-row justify-content-start align-middle text-white'}>
                                                <img src={navLogo} alt="" style={{width: "50px", height: "auto"}}/>
                                                {item.name} {item.link} {item.external ? "Yes" : "No"}
                                            </div>
                                        </NavLink>*/}
                                        {item.external ? <NavLink to={item.link}
                                                                  className={`dropdown-item text-white bg-black text-decoration-none ${isMobile ?
                                                                      'font-16' :
                                                                      'sfProDisplayFontBold' +
                                                                      ' font-16'} h-100 d-flex flex-column justify-content-center`}
                                                                  onClick={handleCollapse}>
                                                <div
                                                    className={'flex-row justify-content-start align-middle text-white'}>
                                                    {/*<img src={navLogo} alt="" style={{width: "50px", height: "auto"}}/>*/}
                                                    {item.name}
                                                </div>
                                            </NavLink>
                                            : <NavLink to={item.link}
                                                       className={`dropdown-item text-white bg-black text-decoration-none ${isMobile ?
                                                           'pt-2 sfProDisplayFontBold font-16' :
                                                           'sfProDisplayFontBold' +
                                                           ' font-16'} h-100 d-flex flex-column justify-content-center`}
                                                       onClick={handleCollapse}>
                                                <div
                                                    className={'flex-row justify-content-start align-middle text-white'}>
                                                    {/*<img src={navLogo} alt="" style={{width: "50px", height: "auto"}}/>*/}
                                                    {item.name}
                                                </div>
                                            </NavLink>}
                                    </Col>);
                                })}
                        </Row>
                    </div>
                </NavDropdown>
                <NavItem
                    className={`m-4 d-flex flex-column justify-content-center nav-link mt-2 border-bottom  ${isMobile ?
                        'pt-2' :
                        ''}`}>
                    <NavLink to={'/cricket'}
                             className={`p-2 text-start  text-white text-decoration-none ${isMobile ?
                                 'sfProDisplayFont font-16' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        Cricket
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`m-4 d-flex flex-column justify-content-center mt-2 nav-link border-bottom  ${isMobile ?
                        'pt-2' :
                        ''}`}>
                    <NavLink to={'/nba'}
                             className={`p-2 text-start  text-white text-decoration-none ${isMobile ?
                                 'sfProDisplayFont font-16' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        NBA
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`m-4 d-flex flex-column justify-content-center mt-2 nav-link border-bottom  ${isMobile ?
                        'pt-2' :
                        ''}`}>
                    <NavLink to={'/nfl'}
                             className={`p-2 text-start  text-white text-decoration-none ${isMobile ?
                                 'sfProDisplayFont font-16' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        NFL
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`m-4 d-flex flex-column justify-content-center mt-2 nav-link border-bottom  ${isMobile ?
                        'pt-2' :
                        ''}`}>
                    <NavLink to={'/mlb'}
                             className={`p-2 text-start  text-white text-decoration-none ${isMobile ?
                                 'sfProDisplayFont font-16' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        MLB
                    </NavLink>
                </NavItem>

                <NavItem
                    className={`m-4 d-flex flex-column justify-content-center mt-2 nav-link border-bottom `}>
                    <NavLink to={'/shop'}
                             className={`p-2 text-start text-white text-decoration-none ${isMobile ?
                                 'sfProDisplayFont font-16' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        Shop
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`m-4 d-flex flex-column justify-content-center mt-2 nav-link  border-bottom`}>
                    <NavLink to={'/contact'}
                             className={`text-white p-2 text-start text-decoration-none ${isMobile ?
                                 'sfProDisplayFont font-16' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        Contact
                    </NavLink>
                </NavItem>
            </Menu>);
    };

    function renderSocialNav() {
        return (<div className={`p-4 mt-5 w-100 border-top`}>
            <div className={`${fontColor} sfProDisplayFont`}>
                Follow us for the best {navName} content
            </div>
            <div className={'mt-3'}>
                <a href={store.misc.appInit && store.misc.appInit.facebook_url}
                   className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faFacebook} className={fontColor} size={'3x'}/>
                </a>
                <a href={store.misc.appInit && store.misc.appInit.twitter_url}
                   className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faTwitter} size={'3x'}
                                     className={`ms-4 ${fontColor}`}/>
                </a>
                <a href={store.misc.appInit && store.misc.appInit.instagram_url}
                   className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faInstagram} size={'3x'}
                                     className={`ms-4 ${fontColor}`}/>
                </a>
            </div>
        </div>);
    }

    const renderMobileSports = (value, valueIndex) => {
        return (
            <NavDropdown
                className={`m-4 mb-3 pt-1 text-start border-0 fanzine-nav-responsive border-bottom `}
                // style={{border: 'none !important'}}
                title={<span className={`text-start p-2 text-white ${isMobile ?
                    'sfProDisplayFont font-16' :
                    'sfProDisplayFontBold font-16'}`}>{value?.name}</span>}
                id={'top-menu-dropdown' + valueIndex} renderMenuOnMount={true}
            >
                <div className="pt-0 mt-n10 bg-black mt-1">
                    <Row className={`p-0 m-0 border-1 border-success`}>
                        <NavItem
                            style={{border: 'none !important'}}
                            title={`All ${value?.name}`}
                            id="top-menu-dropdown" renderMenuOnMount={true}
                        >
                            <NavLink className={'text-decoration-none'} to={`/${value?.name.toLowerCase()}`} onClick={handleMainCollapse}>
                                <p className={'text-white font-14'}>{`All ${value?.name}`}</p>
                            </NavLink>
                        </NavItem>
                        {value?.leagues?.map((item, index) => {
                            return (
                                <Col key={index} xs="12" md="12" className={'d-block'}>
                                    <NavItem
                                        style={{border: 'none !important'}}
                                        title={item.name}
                                        id="top-menu-dropdown" renderMenuOnMount={true}
                                    >
                                        <p className={'text-white font-14'}>{item.name}</p>
                                    </NavItem>
                                    {
                                        item.teams.map((team, index) => {
                                            return (
                                                <NavItem
                                                    key={index}
                                                    className={' d-block '}>
                                                    <NavLink className={'text-decoration-none'} to={team.link} onClick={handleMainCollapse}>
                                                        <p className={'text-white font-14'}>{team.name}</p>
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        })
                                    }
                                </Col>
                            );
                        })}
                    </Row>
                </div>
                <div className='border-bottom'></div>
            </NavDropdown>
        );
    }

    const renderMobileNew = () => {
        return (
            <Menu center className={`bg-black responsive-navbar-nav-fanzine px-2`}
                  width={'100%'} id={'responsive-navbar-nav-fanzine'}>
                <NavItem
                    className={`d-flex flex-column pt-5 m-4 justify-content-center nav-link mt-2 ${isMobile ?
                        'pt-2' :
                        ''} border-bottom border-secondary`}>
                    <NavLink to={'/'}
                             className={`text-white p-2 text-start text-decoration-none ${isMobile ?
                                 'sfProDisplayFont font-16' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        Home
                    </NavLink>
                </NavItem>
                {
                    store?.football?.fanzineTeamsLeagues?.map((value, valueIndex) => (
                        renderMobileSports(value, valueIndex)
                    ))
                }
                <NavItem
                    className={`m-4 d-flex flex-column justify-content-center mt-2 nav-link  border-bottom`}>
                    <NavLink to={'/shop'}
                             className={`text-white p-2 text-start text-decoration-none ${isMobile ?
                                 'sfProDisplayFont font-16' :
                                 'sfProDisplayFontBold font-16'}`}
                             onClick={handleMainCollapse}>
                        Shop
                    </NavLink>
                </NavItem>

            </Menu>);
    };


    const render = () => {

        const sportFromURL = route?.split('/')[1] || '';
        const sportName = sportFromURL.toUpperCase() || '';


        return (<div ref={ref}>
            <Navbar collapseOnSelect expand="md"
                    className={`fixed-top nav-bar navbar-dark ${navColor} ${navShadow} ${fontColor}`}
                    style={{height: isMobile && '45px'}}
            >
                <div className={`${isMobile ? 'w-100' : 'container'}`} style={{height: !isMobile && "58px" , zIndex: -100}}>
                    {isMobile ? (<div className={`${isMobile ? 'row mt-n2' : ''}`}>

                        <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile &&
                        'col-3 text-start'}`}>
                            <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                                <img src={navLogo}
                                     alt={navName}
                                     {...imageProps}
                                />
                            </NavLink>
                        </Navbar.Brand>
                        <NavLink to={`/${sportName.toLowerCase()}`} className={'text-decoration-none col-5 text-center mt-2'}>
                            <span className={'text-white'}>
                                {sportName}
                            </span>
                        </NavLink>
                        <div className="bm-overlay" id={'overlay'}></div>
                        <div className={`bm-burger-button ${isMobile &&
                        'col-3 d-inline-flex text-end float-end justify-content-end'} ${fontColor}`}>
                            <button id="react-burger-menu-btn" type="button"
                                    aria-label="Toggle navigation"
                                    className={`${fontColor} float-end pe-3 text-end navbar-toggler collapsed`}
                                    onClick={() => {
                                        setIsComponentVisible(true);
                                        setIsCollapsed(!isCollapsed);
                                    }}>
                                <FontAwesomeIcon icon={faBars} className={fontColor}
                                                 size={'1x'}/>
                            </button>
                        </div>


                        {/*{isComponentVisible && isMobile && renderMobileNav()}*/}
                        {isComponentVisible && isMobile && renderMobileNew()}
                        {!isMobile && renderMainNav()}


                    </div>) : (<>
                        <div className={` ${isMobile && 'col-2 d-inline-flex'}`}>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav"
                                           className={`${fontColor} float-start text-start`}
                                           id={'react-burger-menu-btn'}
                                           onClick={() => {
                                               setIsComponentVisible(true);
                                               setIsCollapsed(!isCollapsed);
                                           }}/>
                        </div>

                        <Navbar.Brand
                            className={`mt-xxl-3 mt-xl-3 mt-lg-3 pb-0 ${isMobile &&
                            'col-5 text-end'}`}
                        >
                            <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                                <img src={navLogo}
                                     alt={navName}
                                     {...imageProps}
                                />
                            </NavLink>
                        </Navbar.Brand>
                        {isComponentVisible && isMobile && renderMobileNav()}
                        {!isMobile && renderMainNav()}
                    </>)}
                </div>
            </Navbar>
        </div>);
    };

    return render();
};

export default FanzineNav;
