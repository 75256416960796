import Loader from '../../Loader/Loader';
import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import FanzineSportsNewsCard
  from '../FanzineSportsNewsCard/FanzineSportsNewsCard';

const FanzineSportsNewsOnSlider = (props) => {
  const {id, data, leagueName, bgColor, fontColor, isMobile, loading, allLeagues, isClubWebsite} = props;

  const renderLeftButton = (id) => {

    let style =
        {
          width: '63px',
          height: '68px',
          backgroundColor: '#fff',
          color: 'black',
          border: '0px',
          borderRadius: '0px'
        };

    const handleLeftClick = () => {
      document.getElementById('NewsSliderContainer' + id).scrollLeft -= 200;

    }


    return (
        <div
            style={{
              marginLeft: '-3em',
              marginRight: '-2em',
              zIndex: '1',
              marginTop: '-4em'
            }}

            onClick={handleLeftClick}
        >
          <Button className={''} style={style}>
            <AiOutlineLeft/>
          </Button>
        </div>
    );
  };

  const renderRightButton = (id) => {

    let style =
        {
          width: '63px',
          height: '68px',
          backgroundColor: '#fff',
          color: 'black',
          border: '0px',
          borderRadius: '0px'

        };

    const handleRightClick = () => {
      document.getElementById('NewsSliderContainer' + id).scrollLeft += 200;

    }

    return (
        <div
            style={{
              marginLeft: '-3em',
              zIndex: '1',
              marginTop: '-4em'

            }}

            onClick={handleRightClick}
        >
          <Button
              style={style}
          > <AiOutlineRight/>
          </Button>
        </div>
    );
  };
  const renderNews = (data, league_name, id) => {
    let leagueName = league_name;
    let style = {
      background:'#000000',
      color: '#ffffff',
    };

    return (
        <div className={`${isClubWebsite ? 'w-100 p-0' : 'w-100 p-3'}`}
             style={isClubWebsite ? {} : style}
        >
          <div className={`${isMobile ? 'w-100' : 'container'}`}>
            <div className={'d-flex justify-content-center align-items-center'}>
              {!isMobile && renderLeftButton(id)}
              <div
                  id={'NewsSliderContainer' + id}
                  className={`slider-mobile-container ${data?.length <= 3 ?
                      'justify-content-center' :
                      'overflow-scroll-x'}`}>
                {
                  data?.map((news,index) => index === 0 ?
                      <></>
                      : (
                          <>
                            <div
                                // onClick={handleClick}
                                className={`slider-mobile-news-menu text-left d-flex flex-column justify-content-center ${isMobile ?
                                    'pt-1 mt-4' :
                                    ''} `}
                            >
                              <FanzineSportsNewsCard
                                  bgColor={'black'}
                                  imageHeight={''}
                                  fontColor={'text-white'}
                                  news={news}
                                  newsOnSlider={true}
                                  isNewsPage={true}
                                  isMobile={isMobile}
                              />
                            </div>
                          </>
                      ),
                  )
                }
              </div>
              {!isMobile && renderRightButton(id)}
            </div>
          </div>
          <Loader loading={loading}/>
        </div>
    );
  };


  const renderMain = () => {
    return (
        <div className={'p-0'}>
          {data && renderNews(data, leagueName, id)}
        </div>
    )
  }

  return renderMain()

}


FanzineSportsNewsOnSlider.propTypes = {

  id: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  leagueName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};


export default FanzineSportsNewsOnSlider;
