import React, {useEffect, useState} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {SCREEN_SIZE} from '../../Configs/Constants';
import BecomingTheBiggest from './BecomingTheBiggest';
import MultiSport from './MultiSport';
import Carousel from './Carousel';
import FansLove from './FansLoveUs';
import ByNumbers from './ByNumbers';
import Audience from './Audience';
import Team from './Team';
import Advertising from './Advertisng';
import Partners from './Partners';
import {get} from '../../Axios/get';
import {CampaignEndPoint} from '../../Axios/EndPoints';
import {getHostname} from '../../CustomFunctions/getHostname';
import Cookies from "universal-cookie";
import CampaignPopup from '../../Components/Campaigns/CampaignPopup';
import LeagueCodePopup from '../../Components/Campaigns/LeagueCodePopup';

// import CrowdFunding from './CrowdFunding';

const HomePage = () => {
  const cookies = new Cookies();
  const [isMobile, setIsMobile] = useState(false);
    // const [windowIsBeingReSized,setWindowIsBeingReSized] = useState(false);

  const [campaign, setCampaign] = useState([]);
  const [campaignModalShow, setCampaignModalShow] = useState(false);
  const [campaignLeagueCodeModalShow, setCampaignLeagueCodeModalShow] = useState(false);


  const detectAdBlock = () => {
        let adBlockEnabled = false
        const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
        try {
             fetch(new Request(googleAdUrl)).catch(_ => adBlockEnabled = true)
        } catch (e) {
            adBlockEnabled = true
        } finally {
            console.log(`AdBlock Enabled: ${adBlockEnabled}`)
        }
    }


  const fetchCampaigns = () => {
    get(CampaignEndPoint)
        .then((response) => {
          let campaignData = response.data.data;
          // console.log("campaign", campaignData.length);
          let tempCampaign = [];
          let campaign = null;
          if (campaignData.length) {
            campaignData?.map((data) => {
              campaign = {
                id: data.id,
                campaign_id: data.campaign_id,
                type: data.type,
                leagueCode: data.league_code,
                image: data.image,
                title: data.title,
                description: data.description,
                questions: data.questions,
              };
              if (data.display_page === "home") {
                tempCampaign.push(campaign);
              }
            });
            const hostName = window.location.hostname;
            const domainObject = getHostname(hostName);
            const target = domainObject.host;
            if (cookies.get(`fanzine_campaign_${campaign.campaign_id}_${target}`) !== "1" && !campaignModalShow) {
              setCampaignModalShow(true);
            }
          }
          setCampaign(tempCampaign);
        })
        .catch((err) => {
          console.log("campaign_err", err);
        });
  };


  useEffect(() => {


      // window.addEventListener('resize', ()=>{
      //   if(window.innerWidth<1080)
      //   {
      //     setWindowIsBeingReSized(true);
      //   }
      //   else
      //   {
      //     setWindowIsBeingReSized(false);
      //   }
      // });

      detectAdBlock();
      campaign.length <= 0 && fetchCampaigns();


        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        document.getElementById('snack_dex1') && document.getElementById('snack_dex1').remove();

    }, [window.innerWidth,window.screen.width, isMobile]);


    const renderMain = () => {

        return (
            <div
                className={'text-center'}
            >
              {
                <CampaignPopup
                    setCampaignModalShow={setCampaignModalShow}
                    show={campaignModalShow}
                    onHide={() => setCampaignModalShow(false)}
                    showLeageCode={campaignLeagueCodeModalShow}
                    setCampaignLeagueCodeModalShow={setCampaignLeagueCodeModalShow}
                    onHideLeagueCode={() => setCampaignLeagueCodeModalShow(false)}
                    campaign={campaign}
                />
              }
              {
                <LeagueCodePopup
                    show={campaignLeagueCodeModalShow}
                    onHide={() => setCampaignModalShow(false)}
                    showLeageCode={campaignLeagueCodeModalShow}
                    setCampaignLeagueCodeModalShow={setCampaignLeagueCodeModalShow}
                    onHideLeagueCode={() => setCampaignLeagueCodeModalShow(false)}
                    campaign={campaign}
                />
              }
                {/* Commented for 2 months as this is old design we will later move back to this one */}
                <BecomingTheBiggest isMobile={isMobile}/>
                {/* This will be uncommented */}

                {/* CROWD FUNDING DESIGN */}
                {/*<CrowdFunding isMobile={isMobile} windowIsBeingReSized={windowIsBeingReSized}/>*/}
                <MultiSport isMobile={isMobile}/>
                <Carousel isMobile={isMobile}/>
                <FansLove isMobile={isMobile}/>
                <ByNumbers isMobile={isMobile}/>
                <Audience isMobile={isMobile}/>
                <Advertising isMobile={isMobile} />
                <Partners isMobile={isMobile} />
                <Team isMobile={isMobile}/>
            </div>
        );

    };

    return renderMain();
};

export default HomePage;
