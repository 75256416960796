import {useSearchParams} from 'react-router-dom';
import {FOOTBALL} from '../../../Axios/EndPoints';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {get} from '../../../Axios/get';
import FanzineNewsCard from '../../Cards/FanzineNewsCard';
import LeftNewsSkeleton from '../../Skeleton/LeftNewsSkeleton';
import GoogleAd from '../../Ads/GoogleAd';
import MoreStories from '../MoreStories';
import NewsSectionHeading from '../../NewsSectionHeading';
import RightNewsAds from '../News/RightNewsAds';
import {COLORS, FONT_COLOR} from '../../../Configs/Targets';
import Social from '../Social/Social';
import {chunk} from "../../Helper/chunk";
import {
    capitalizeWords
} from '../../../CustomFunctions/UpperCaseEveryFirstWord';
import FanzineSportsNewsCard
    from '../../FanzineSports/FanzineSportsNewsCard/FanzineSportsNewsCard';

const LatestNews = (props) => {
    const {
        isNewsPage,
        isMobile,
        data,
        target,
        showMoreStories,
        isClubPage,
        limit,
        limitRight,
    } = props;
    const [searchParams] = useSearchParams();
    const [latestNews, setLatestNews] = useState([]);
    const [latestNewsLoading, setLatestNewsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const fetchLatestNews = (endpoint) => {

        let id = (searchParams.get('team_id') || searchParams.get('league_id') || searchParams.get('teamType') || data?.clubId || '');
        let parameters = searchParams.get('league_id') ?
            '?league_id=' + id :
            data ? '?team_id=' + data?.clubId :
                '';

        parameters = searchParams.get('teamType') ? '?teamType=' + searchParams.get('teamType') : parameters;

        let tmpUrl = '';

        if (isNewsPage) {
            parameters += '?limit=' + props.limit;
        }

        let URL = FOOTBALL.LatestNewsEndPoint;

        if (id !== '') {
            URL += parameters;
        }

        get(endpoint ? endpoint : URL).then((response) => {
            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data) => {
                let news = {
                    main_team: data.main_team,
                    is_snack: data.is_snack,
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            let tempNewsArr = chunk(tempNewsData, 30);
            setLatestNews(tempNewsArr[0]);
            setLatestNewsLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        setLatestNewsLoading(true);
        fetchLatestNews(props.newsEndpoint);
    }, [searchParams.get('league_id'), searchParams.get('team_id'), searchParams.get('teamType'), data, props.newsEndpoint]);

    const renderLatestNews = (News, Sport, isMobile, title) => {
        const SportNews = News;
        const SportName = Sport;

        const renderLeftLatestNews = () => {
            return (
                <div className={` ${isNewsPage ?
                    'col-12' :
                    'col-xxl-8 col-xl-8  col-lg-8 col-md-8'}   col-sm-12`}>
                    <GoogleAd id={'4009154580'}/>
                    <NewsSectionHeading title={title} bgColor={COLORS[target]}
                                        fontColor={FONT_COLOR[target]}/>

                    <div className={`col-12`}>
                        <div className={`w-100`}>

                            <div className={'row '}>
                                {SportNews && SportNews.map((news, index) => {
                                    let limit = isNewsPage ? 30 : 9;

                                    return index < limit &&
                                        (
                                            <div
                                                className={`${index === 0 ?
                                                    'col-xl-12 m-0 p-1 col-lg-12 col-md-12' :
                                                    'col-xl-6 m-0 p-1 col-lg-6 col-md-6'} col-sm-12 col-xs-12`}
                                            >
                                                {isMobile && <FanzineSportsNewsCard
                                                    imageHeight={'123px'}
                                                    news={news}
                                                    showNewsSide={true}
                                                />}
                                                {!isMobile && <FanzineNewsCard
                                                    news={news}
                                                    imageHeight={(!isMobile &&
                                                        index === 0) ?
                                                        '500px' :
                                                        '277px'}
                                                    cardTextSize={''}
                                                    isNewsPage={''}
                                                    publisherIconSize={''}
                                                    isMain={''}
                                                />
                                                }                                            </div>
                                        );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        const renderSocial = () => {
            return (
                <div className={'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12'}>
                    <GoogleAd id={'4009154580'}/>
                    {
                        !isMobile &&
                        <Social isNewsPage={true} target={target} queryParams={searchParams.get('league_id') ?
                            '?league_id=' + searchParams.get('league_id') :
                            searchParams.get('teamType') ? '?teamType=' + searchParams.get('teamType') :
                                data ? '?team_id=' + data?.clubId :
                                    ''}/>
                    }
                    <RightNewsAds teamData={data} isMobile={isMobile} limit={limit ? limit : (isClubPage ? 2 : 6)} fullWidth={true}/>
                </div>
            );
        };

        const renderMoreStories = () => {
            return <MoreStories data={data} setPage={setPage} target={target}/>;
        };

        const renderMain = () => {
            return (
                <section className={'container'}>
                    <div className={`${isMobile ? 'w-100' : 'container'}`}>
                        <div className={`  ${!isMobile && 'row'}`}>
                            {!latestNewsLoading && renderLeftLatestNews()}
                            {latestNewsLoading && <LeftNewsSkeleton/>}
                            {isClubPage && renderSocial()}
                            {/*{isClubPage && <div className={'col-8'}/>}*/}
                            {!isClubPage && <RightNewsAds isMobile={isMobile} limit={limitRight ? limitRight : (isClubPage ? 2 : 6)} showHeader={true} title={''} target={target}/>}
                            <GoogleAd id={'4009154580'}/>
                        </div>
                        {data && (typeof showMoreStories !== 'undefined' ?
                            showMoreStories :
                            true) && renderMoreStories()}
                    </div>
                </section>
            );
        };

        return renderMain();
    };


    let teamNameRaw = (data?.team_name || data?.clubName || data?.name);

    return renderLatestNews(latestNews, 'football', isMobile, `${data ?
        'Latest ' + (capitalizeWords(teamNameRaw)) + ' News' :
        'Latest News'}`);

};

export default LatestNews;
