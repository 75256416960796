import React, {useEffect, useRef, useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../../assets/styles/styles.css';
import '../../assets/styles/responsive/stylesLaptop.css';
import '../../assets/styles/responsive/stylesTablet.css';
import '../../assets/styles/responsive/stylesMobile.css';
import '../../assets/styles/footballTargets.css';
import {
  COLORS, COLORS_RGB, COLORS_RGBA,
  FONT_COLOR,
  LOGO,
  LOGO_BANNER,
  LOGO_SMALL,
  NAV_CLASS,
  TEAM_NAME,
  TEXT_COLOR,
  TEXT_COLORS,
} from '../../Configs/Targets';
import {COMPARE_CATEGORY, SCREEN_SIZE} from '../../Configs/Constants';
import {
  Col,
  NavDropdown,
  NavItem,
  Overlay,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import {getHostname} from '../../CustomFunctions/getHostname';
import * as Icon from 'react-bootstrap-icons';
import useComponentVisible from '../../CustomFunctions/useComponentVisible';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAndroid,
  faApple,
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import {AndroidSVG} from '../../Constants/JS/AndroidSVG';
import CrowdFundingBanner from './CrowdFundingBanner';

const NavbarHeader = () => {
  const store = useSelector((state) => state);
  const shopStore = useSelector(state => state.shopify);
  const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(
      true);

  const [width, setWidth] = useState(window.screen.width);
  const [showCart, setShowCart] = useState(false);
  const [navShadow, setNavShadow] = useState('navShadow');
  const [showFilter, setShowFilter] = useState(false);
  const newTarget = useRef(null);

  const updateScreenWidth = () => {
    setWidth(window.screen.width);
  };

  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;
  const route = window.location.pathname;
  const navColor =  NAV_CLASS[target];
  const navColorDark = NAV_CLASS[target] + '-dark';
  const textColor = TEXT_COLOR[target];
  const navColorHover = NAV_CLASS[target] + '-hover-color';
  const fontColor = FONT_COLOR[target];
  const navName = TEAM_NAME[target];
  const color = COLORS[target];

  let [navLogo, setNavLogo] = useState(LOGO[target]);
  let [imageProps, setImageProps] = useState({});
  let [isMobile, setIsMobile] = useState(false);
  let [showDownloadBanner, setShowDownloadBanner] = useState(true);
  let [isCollapsed, setIsCollapsed] = useState(true);
  let topNavBarDropdown = {};

  if (isMobile) {
    topNavBarDropdown = {
      width: '100%',
    };
  }

  useEffect(() => {
    setNavShadow('navShadow');
    setShowCart(false);
    if (route.includes('shop')) {
      setNavShadow('shadow-nav-dark');
      setShowCart(true);
    }
  }, [route]);

  useEffect(() => {
    const navLogoSmall = LOGO_SMALL[target];
    const navLogoBig = LOGO[target];

    if (window.screen.width <= SCREEN_SIZE.TABLET) {
      setShowDownloadBanner(true);
    }
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setNavLogo(navLogoSmall);
      setImageProps({
        height: 50,
      });
      setIsMobile(true);
    } else {
      setNavLogo(navLogoBig);
      if ([
        'the-toffees',
        'unitedreds',
        'coys',
        'playuppompey',
        'foresttilidie',
        'comeonderby',
        'fanzinesports'].includes(target)) {
        setImageProps({
          style: {marginTop: '-2em'}, height: 85,
        });
      } else {
        setImageProps({
          height: 119,
        });
      }
      setIsMobile(false);
    }
    if (target !== 'fanzinesports' &&
        !document.getElementById('navbarMobileToggle')
            .classList
            .contains('collapsed')) {
      document.getElementById('navbarMobileToggle').click();
    }
  }, [window.screen.width]);

  useEffect(() => {
    if (isMobile && target !== 'fanzinesports') {
      if (!isCollapsed) {
        document.getElementById(
            'navbarMobileToggle').innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 fa-2x"' +
            ' role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>';
        document.getElementById(
            'responsive-navbar-nav').style = 'display: block !important;';
      } else {
        document.getElementById(
            'navbarMobileToggle').innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-11 fa-2x' +
            '" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163' +
            ' 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>';
        document.getElementById(
            'responsive-navbar-nav').style = 'display: none !important;';
        // document.getElementById('navbarMobileToggle').innerHTML = '<FontAwesomeIcon icon={\'bars\'} className={fontColor}/>';
      }
    }
  }, [isCollapsed]);

  useEffect(() => {
  }, [target, imageProps]);

  const handleCollapse = () => {
    document.getElementsByClassName('nav-mega-menu')[0].closest(
        '.dropdown-menu').previousSibling.click();
    document.getElementById('navbarMobileToggle').click();
  };

  const handleMainCollapse = () => {
    document.getElementById('navbarMobileToggle').click();
  };

  const renderMainNav = () => {

    const filterDropDown = () => {

      let avaiableMensCategory = [];
      let avaiableWomensCategory = [];
      let avaiableChildrensCategory = [];

      shopStore?.productTags?.map((value, index) => {

        if (value.includes('womens') || value.includes('women') ||
            value.includes('unisex')) {
          for (let i = 0; i < COMPARE_CATEGORY?.length; i++) {
            if (value?.includes(COMPARE_CATEGORY[i].compareName)) {
              let tempWomen = COMPARE_CATEGORY[i].displayName;
              if (!avaiableWomensCategory?.includes(tempWomen)) {
                avaiableWomensCategory.push(tempWomen);
              }
            }
          }
        }

        if (value.includes('mens') || value.includes('men') ||
            value.includes('unisex')) {
          for (let i = 0; i < COMPARE_CATEGORY?.length; i++) {
            if (value?.includes(COMPARE_CATEGORY[i].compareName)) {
              let tempMen = COMPARE_CATEGORY[i].displayName;
              if (!avaiableMensCategory?.includes(tempMen)) {
                if (tempMen === 'tshirt') {
                  continue;
                } else {
                  avaiableMensCategory.push(tempMen);
                }
              }
            }
          }
        }
        if (value.includes('children') || value.includes('juniors')) {
          for (let i = 0; i < COMPARE_CATEGORY?.length; i++) {
            if (value?.includes(COMPARE_CATEGORY[i].compareName)) {
              let tempChildren = COMPARE_CATEGORY[i].displayName;
              if (!avaiableChildrensCategory?.includes(tempChildren)) {
                avaiableChildrensCategory.push(tempChildren);
              }
            }
          }
        }

      });

      return (
          <div className={'d-flex align-items-start p-2 position-absolute'}
               style={{
                 width: '820px',
                 backgroundColor: 'white',
                 marginLeft: -370,
                 zIndex: 100,
                 top: 20,
               }}>
            <div className={'container'}>
              <div className={'row'}>
                <div
                    className={'col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12'}>
                  <NavLink className={'text-decoration-none'} to={'/shop'}>
                    <h6 style={{color: TEXT_COLORS[target]}}
                        className={`sfProDisplayFontBold  hover-bold pt-3 p-2 `}>{'Featured'}</h6>
                  </NavLink>
                  <div className={'mt-3 text-start ps-5  text-nowrap'}>
                    <NavLink className={'text-decoration-none'} to={'/newest'}>
                      <h6 className={`text-black p-2 hover-bold  `}>{'Latest Arrival'}</h6>
                    </NavLink>
                    <NavLink className={'text-decoration-none'} to={'/sale'}>
                      <h6 className={`text-black p-2 hover-bold  `}>{'Sale'}</h6>
                    </NavLink>
                    {/*<NavLink className={'text-decoration-none'} to={'/shop'}>*/}
                    {/*  <h6 className={`text-black p-2 hover-bold  `}>{'Best Sellers'}</h6>*/}
                    {/*</NavLink>*/}
                  </div>
                </div>
                <div
                    className={'col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12'}>
                  <NavLink className={'text-decoration-none'} to={'/shop/Mens'}>
                    <h6 style={{color: TEXT_COLORS[target]}}
                        className={`sfProDisplayFontBold  hover-bold pt-3 p-2 `}>{'Mens'}</h6>
                  </NavLink>
                  <div className={'mt-3 text-start ps-3 ms-2 text-nowrap'}>
                    {
                      avaiableMensCategory?.map((menItem, menIndex) => {

                        return (
                            <NavLink
                                key={menIndex}
                                className={'text-decoration-none'}
                                to={'/shop/Mens/' + menItem}>
                              <h6
                                  className={` hover-bold text-black p-2 `}>{menItem}</h6>
                            </NavLink>
                        );
                      })
                    }
                  </div>
                </div>
                <div
                    className={'col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12'}>
                  <NavLink className={'text-decoration-none'}
                           to={'/shop/Womens'}>
                    <h6 style={{color: TEXT_COLORS[target]}}
                        className={`sfProDisplayFontBold  hover-bold pt-3 p-2 `}>{'Womens'}</h6>
                  </NavLink>
                  <div className={'mt-3 text-start ps-3 text-nowrap'}>
                    {
                      avaiableWomensCategory?.map((WomenItem, WomenIndex) => {
                        return (
                            <NavLink className={'text-decoration-none'}
                                     to={'/shop/Womens/' + WomenItem}>
                              <h6 key={WomenIndex}
                                  className={` hover-bold text-black p-2 `}>{WomenItem}</h6>
                            </NavLink>
                        );
                      })
                    }
                  </div>
                </div>
                <div
                    className={'col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12'}>
                  <NavLink className={'text-decoration-none  mt-3'}
                           to={'/shop/Childrens'}>
                    <h6 style={{color: TEXT_COLORS[target]}}
                        className={`sfProDisplayFontBold  hover-bold pt-3 p-2 `}>{'Childrens'}</h6>
                  </NavLink>
                  <div className={'mt-3 text-start ps-3  text-nowrap'}>
                    {
                      avaiableChildrensCategory?.map(
                          (childrenItem, childrenIndex) => {
                            return (
                                <NavLink className={'text-decoration-none'}
                                         to={'/shop/Childrens/' + childrenItem}>
                                  <h6 key={childrenIndex}
                                      className={` hover-bold text-black p-2 `}>{childrenItem}</h6>
                                </NavLink>
                            );
                          })
                    }
                  </div>
                </div>
                <div
                    className={'col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12'}>
                  <NavLink className={'text-decoration-none'}
                           to={'/shop/Accessories/'}>
                    <h6 style={{color: TEXT_COLORS[target]}}
                        className={`sfProDisplayFontBold mt-3 hover-bold `}>Accessories</h6>
                  </NavLink>
                  <div className={'mt-3 text-start  ps-1 text-nowrap'}>
                    {/*<h6 className={`text-black p-2 `}>Hats & Scarves</h6>*/}
                    {/*<h6 className={`text-black p-2 `}>Framed Prints</h6>*/}
                    {/*<h6 className={`${'text-black'} p-2 `}>Cushions</h6>*/}
                    {/*<h6 className={`${'text-black'} p-2 `}>Towels</h6>*/}
                    {/*<h6 className={`${'text-black'} p-2 `}>Mugs</h6>*/}
                    {/*<h6 className={`${'text-black'} p-2 `}>Phone Cases</h6>*/}
                    {/*<h6 className={`${'text-black'} p-2 `}>Birthday Cards</h6>*/}
                    {/*<h6 className={`${'text-black'} p-2 `}>Home Essentials</h6>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
      );
    };

    return (<Navbar.Collapse id="responsive-navbar-nav"
                             className={`${navColor} w-100`}
                             style={{zIndex: '10000'}}>
      <Nav
          className={'justify-content-evenly float-xxl-end float-xl-end float-lg-end float-md-end float-sm-end p-xxl-0 p-xl-0 p-lg-0 p-md-0 p-sm-0 nav-content float-sm-start align-content-sm-start mt-xxl-2 mt-xl-2 mt-lg-2'}>
        {/* THIS IS HOME AND NEWS & SOCIAL CHANGE IS ADDED BECAUSE OF KEVIN REQUEST TO HAVE HOME AS DROPDOWN */}
        {/*<NavItem*/}
        {/*    className={`d-flex flex-column justify-content-center ps-2 ms-5  ${isMobile ?*/}
        {/*        'ps-4 pt-2' :*/}
        {/*        ''}`}>*/}
        {/*  <NavLink to={'/'}*/}
        {/*           className={`${fontColor} font-16 text-decoration-none ms-5 ${isMobile ?*/}
        {/*               'bebasFont' :*/}
        {/*               'sfProDisplayFontBold font-16'}`}*/}
        {/*           onClick={handleMainCollapse}>*/}
        {/*    Home*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        {isMobile ?
            <NavItem
                className={`d-flex flex-column justify-content-center  ${isMobile ?
                    'ps-4 pt-2' :
                    ''}`}>
              {/* THIS IS HOME AND NEWS & SOCIAL CHANGE IS ADDED BECAUSE OF KEVIN REQUEST TO HAVE HOME AS DROPDWON */}
              <NavLink to={true ? '/' : '/news?teamType=team'}
                       className={`${fontColor}  text-decoration-none ${isMobile ?
                           'bebasFont' :
                           'sfProDisplayFontBold font-16'}`}
                       onClick={handleMainCollapse}>
                {true ? 'Home' : 'News'}
              </NavLink>
            </NavItem> :
            <NavDropdown className={`no-caret-dropdown ps-2 ms-2 ${isMobile && 'pt-2'}`}
                         title={<span className={`${fontColor} font-16 ${isMobile ?
                             'bebasFont ps-4 pt-2' :
                             'sfProDisplayFontBold font-16'}`}>{true ? 'Home' : 'News & Social'}</span>}
                         id="top-menu-dropdown" renderMenuOnMount={true}
                         style={topNavBarDropdown}>
              {/* THIS IS HOME AND NEWS & SOCIAL CHANGE IS ADDED BECAUSE OF KEVIN REQUEST TO HAVE HOME AS DROPDWON */}
              <div className="nav-mega-menu pt-0">
                <div
                    className={`${navColor} sfProDisplayFontBold font-14 d-flex align-items-center`}
                    style={{height: '18px'}}>
                </div>
                <Row className={`p-0 m-0`}>
                  <div
                      className={`bg-dark-grey sfProDisplayFontBold font-14 d-flex align-items-center`}
                      style={{color: '#777777', height: '31px'}}>
                    {store.misc.appInit && store.misc.appInit.team_name}
                  </div>
                  {store.football.navbarTeamTypes &&
                      store.football.navbarTeamTypes.map((item) => {
                        if (item.display_on.includes('news')) {
                          return (<Col xs="4" md="4"

                                       className={`text-left ${navColorHover} d-flex flex-column justify-content-center p-0`}>
                            <NavLink to={`/news?teamType=${item.teamType}`}
                                     className={`${navColorHover} dropdown-item text-gray-custom text-decoration-none font-14 h-100 d-flex flex-column justify-content-center`}
                                     onClick={handleCollapse}>
                              <div
                                  className={'flex-row justify-content-start align-middle'}>
                                {' ' + item.name}
                              </div>
                            </NavLink>
                          </Col>);
                        }
                      })}
                  <div
                      className={`bg-dark-grey sfProDisplayFontBold font-14 d-flex align-items-center`}
                      style={{color: '#777777', height: '31px'}}>
                    Leagues
                  </div>
                  {store.football.navbarLeagues &&
                      store.football.navbarLeagues.map(
                          (item) => (<Col xs="4" md="4"
                                          className={`text-left ${navColorHover} d-flex flex-column justify-content-center p-0`}>
                            <NavLink to={`/news?league_id=${item.league_id}`}
                                     className={`${navColorHover} dropdown-item text-gray-custom text-decoration-none font-14 d-flex flex-column justify-content-center`}
                                     onClick={handleCollapse}>
                              <div
                                  className={'flex-row justify-content-start align-middle sfProDisplayFont '}>
                                {' ' + item.name}
                              </div>
                            </NavLink>
                          </Col>))}
                </Row>
              </div>
            </NavDropdown>}
        <NavItem
            className={`d-flex flex-column justify-content-center ps-2 ms-2 ${isMobile &&
            'ps-4 pt-2'}`}>
          <NavLink to={'/matches'}
                   className={`${fontColor} shop-nav text-decoration-none font-16 ${isMobile ?
                       'bebasFont' :
                       'sfProDisplayFontBold font-16'}`}
          >
            Matches
          </NavLink>
        </NavItem>
        <NavItem
            className={`d-flex flex-column justify-content-center  ps-2 ms-2 ${isMobile &&
            'ps-4 pt-2'}`}>
          <NavLink to={'/table'}
                   className={`${fontColor} shop-nav text-decoration-none font-16 ${isMobile ?
                       'bebasFont' :
                       'sfProDisplayFontBold font-16'}`}
          >
            Table
          </NavLink>
        </NavItem>
        <NavItem
            className={`d-flex flex-column justify-content-center ps-2 ms-2  ${isMobile &&
            'ps-4 pt-2'}`}>
          <NavLink to={'/video'}
                   className={`${fontColor} shop-nav text-decoration-none font-16 ${isMobile ?
                       'bebasFont' :
                       'sfProDisplayFontBold font-16'}`}
          >
            Videos
          </NavLink>
        </NavItem>
        {target !== 'coys' && <NavItem
            className={`d-flex flex-column justify-content-center ps-2 ms-2 ${isMobile &&
            'ps-4 pt-2'}`}>
          <NavLink to={'/shop'} ref={newTarget}
                   onMouseEnter={() => setShowFilter(true)}
                   onMouseLeave={() => setShowFilter(false)}
                   className={`${fontColor} shop-nav text-decoration-none font-16 ${isMobile ?
                       'bebasFont' :
                       'sfProDisplayFontBold font-16'}`}
          >
            Shop
            {!isMobile && <Overlay target={newTarget.current} show={showFilter}
                                   placement="bottom">
              {(props) => (
                  <Tooltip id="shop-nav-overlay" {...props} >
                    {filterDropDown()}
                  </Tooltip>
              )}
            </Overlay>
            }
          </NavLink>
        </NavItem>}

      </Nav>

      {renderSocialNav()}
    </Navbar.Collapse>);
  };

  function renderSocialNav() {
    return (<div className={`${isMobile ?
        'p-4 mt-5 w-100 border-top' :
        'ms-auto pe-5'}`}>
      {isMobile && <div className={`${fontColor} sfProDisplayFont`}>
        Follow us for the best {navName} content
      </div>}
      <div className={'mt-3'}>
        <a rel={'noreferrer'} href={store.misc.appInit &&
            store.misc.appInit.non_betting_urls.ios_appstore_url}
           target={'_blank'}
           className={'text-decoration-none'} style={{color: '#686868'}}>
          <FontAwesomeIcon icon={faApple} className={fontColor} size={'2x'}/>
        </a>
        <a rel={'noreferrer'} href={store.misc.appInit &&
            store.misc.appInit.non_betting_urls.android_play_store_url}
           target={'_blank'}
           className={'text-decoration-none ms-4'} style={{color: '#686868'}}>
          <AndroidSVG height={50} width={40}
                      // fill={'white'}
                      // fill={fontColor}
                      className={`ms-2 ${fontColor}`}
          />
          {/*<FontAwesomeIcon icon={faAndroid} className={`  ${fontColor}`} size={'2x'}/>*/}
        </a>
        <a rel={'noreferrer'}
           href={store.misc.appInit && store.misc.appInit.facebook_url}
           target={'_blank'}
           className={'text-decoration-none'} style={{color: '#686868'}}>
          <FontAwesomeIcon icon={faFacebook} className={` ms-4 ${fontColor}`}
                           size={'2x'}/>
        </a>
        <a rel={'noreferrer'}
           href={store.misc.appInit && store.misc.appInit.twitter_url}
           target={'_blank'}
           className={'text-decoration-none'} style={{color: '#686868'}}>
          <FontAwesomeIcon icon={faTwitter} size={'2x'}
                           className={`ms-4 ${fontColor}`}/>
        </a>
        <a rel={'noreferrer'}
           href={store.misc.appInit && store.misc.appInit.instagram_url}
           target={'_blank'}
           className={'text-decoration-none'} style={{color: '#686868'}}>
          <FontAwesomeIcon icon={faInstagram} size={'2x'}
                           className={`ms-4 ${fontColor}`}/>
        </a>
        <a rel={'noreferrer'} href={'https://www.fanzine.com'}
           target={'_blank'}
           className={'text-decoration-none'} style={{color: '#686868'}}>
          <img alt={'fanzineIcon'} src={require(
              '../../assets/images/v2/newFanzineLogoBlack01@3x.png')}
               style={{
                 height: 100,
                 width: 90,
                 marginTop: -15,
               }}
               className={`ms-3 position-absolute pt-n150 ${fontColor}`}/>
        </a>
      </div>
    </div>);
  }

  const renderDownloadBanner = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navLogoMedium = LOGO_BANNER[target];
    const appName = TEAM_NAME[target];
    const textColor = TEXT_COLOR[target];
    const fontColor = FONT_COLOR[target];
    const bgColor = NAV_CLASS[target];
    return (
        <div className={`row p-3 bg-white`}>
          <div className={!isMobile ? `col-4 d-flex flex-columm justify-content-end`
          :'col-2 d-flex flex-columm justify-content-start'
          }>
            <img src={navLogoMedium} alt={navName} height={'50'}/>
          </div>
          <div
              className={isMobile ?
                  `col-6 sfProDisplayFont d-flex flex-column justify-content-center`
                  :
                  `col-4 sfProDisplayFont d-flex flex-column justify-content-center`}>
            <div className={`flex-row justify-content-start`}>
              <div
                  className={`sfProDisplayFontBold ${textColor} font-16`}>{appName}</div>
              <div className={`sfProDisplayFont font-12`}>Open in
                the {appName} app
              </div>
            </div>
          </div>
          <div
              className={isMobile ?
                  'col-4 d-flex flex-column justify-content-center w-auto float-end me-0'
                  : `col-4 d-flex flex-column justify-content-center w-auto float-end me-0`}>
            <Link to={'/onelink'}
                  className={`btn rounded-pill ${bgColor} ${fontColor} btn-block sfProDisplayFont font-16`}>INSTALL</Link>
          </div>
        </div>
    );
  };

  const render = () => {
    return (<div className={`fixed-top`} ref={ref}>
      {
          // (showDownloadBanner && isMobile) ? renderDownloadBanner() : null
      }
      {/*{*/}
      {/*  <CrowdFundingBanner isMobile={isMobile} />*/}
      {/*}*/}
      {/*{isMobile ? (<></>) : (<div className={`topBar  ${navColorDark}`}/>)}*/}
      <Navbar collapseOnSelect expand="md"
              className={`nav-bar navbar-dark ${navColor} ${navShadow} ${fontColor}`}>
        <NavLink to={'/shop'} id={'navbarShop'}/>
        <div className={`${isMobile ? 'w-100' : 'container'}`}>
          {isMobile ? (<div className={`${isMobile ? 'row' : ''}`}>
            <div className={` ${isMobile &&
            'col-3 ps-4 d-inline-flex'} ${fontColor}`}>
              <button aria-controls="responsive-navbar-nav"
                      id="navbarMobileToggle" type="button"
                      aria-label="Toggle navigation"
                      className={`${fontColor} float-start text-start navbar-toggler collapsed`}
                      onClick={() => {
                        setIsComponentVisible(true);
                        setIsCollapsed(!isCollapsed);
                      }}>
                <FontAwesomeIcon icon={faBars} className={fontColor}
                                 size={'2x'}/>
              </button>
            </div>

            <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile &&
            'col-5 text-center'}`}
                          style={{zIndex: 1}}>
              <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                <img src={navLogo}
                     alt={navName}
                     {...imageProps}
                />
                {!['coys'].includes(target) ? (<span
                    className={`${fontColor} h2 align-middle d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex d-xxl-inline-flex bebasFont ps-2`}>
                                    {navName}
                                </span>) : null}
              </NavLink>
            </Navbar.Brand>
            {isMobile && <div
                className={`text-white flex-column float-end text-end font-weight-100 col-3 d-inline-flex justify-content-center`}>
              <div className={`sfProDisplayFont d-inline-flex ${showCart ?
                  'visible' :
                  'invisible'}`}>
                <Link to="/checkout"
                      style={{textDecoration: 'none', color: '#fff'}}>
                  <Icon.Cart4
                      className="bg-white rounded-circle ps-2 pe-2 pb-1 pt-1 me-2"
                      color={color} size="38"/>
                </Link>
                <div className={'d-flex flex-column justify-content-center'}>
                  <div
                      className={'d-flex flex-row justify-content-center small'}>
                    <span className={'font-weight-100'}>Cart</span>
                    <span
                        className={'font-weight-100 ps-1'}>({store.cart.cartItems.length})</span>
                  </div>
                </div>
              </div>
            </div>}
            {isComponentVisible && isMobile && renderMainNav()}
            {!isMobile && renderMainNav()}

          </div>) : (<>
            <div className={` ${isMobile && 'col-2 d-inline-flex'}`}>
              <Navbar.Toggle aria-controls="responsive-navbar-nav"
                             className={`${fontColor} float-start text-start`}
                             id={'navbarMobileToggle'}
                             onClick={() => {
                               setIsComponentVisible(true);
                               setIsCollapsed(!isCollapsed);
                             }}/>
            </div>

            <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile &&
            'col-5 text-end'}`}
                          style={{zIndex: 1}}>
              <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                <img src={navLogo}
                     className={'pt-4'}
                     alt={navName}
                     {...imageProps}
                />

                {!['coys'].includes(target) ? (<span
                    className={`${fontColor} font-30 align-middle d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex d-xxl-inline-flex bebasFont ps-2`}>
                                    {navName}
                                </span>) : null}
              </NavLink>
            </Navbar.Brand>
            {isMobile && <div
                className={`text-white flex-column float-end text-end font-weight-100 col-4`}>
              <div className={`sfProDisplayFont d-inline-flex ${showCart ?
                  'visible' :
                  'invisible'}`}>
                <div
                    className={`bg-white p-2 rounded-circle me-2`}>
                  <Link to="/checkout"
                        style={{textDecoration: 'none', color: '#fff'}}>
                    <Icon.Cart4 color={color} size="30"/>
                  </Link>
                </div>
                <div className={'d-flex flex-column justify-content-center'}>
                  <div
                      className={'d-flex flex-row justify-content-center small'}>
                    <span className={'font-weight-100'}>Cart</span>
                    <span className={'font-weight-100 ps-1'}>(0)</span>
                  </div>
                </div>
              </div>
            </div>}
            {isComponentVisible && isMobile && renderMainNav()}
            {!isMobile && renderMainNav()}
          </>)}
        </div>
      </Navbar>
    </div>);
  };

  const renderFanzineSports = () => {
    return (<div className={`fixed-top`} ref={ref}>
      {isMobile ? (<></>) : (<div className={`topBar ${navColorDark}`}/>)}
      <Navbar collapseOnSelect expand="md"
              className={`nav-bar navbar-dark ${navColor} ${navShadow} ${fontColor}`}>
        <div className={`${isMobile ?
            'w-100' :
            'container justify-content-start'}`}>
          {isMobile ? (<div className={`${isMobile ? 'row' : ''}`}>
            <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile &&
            'col-5 text-start'}`}
                          style={{zIndex: 1}}>
              <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                <img src={navLogo}
                     alt={navName}
                     {...imageProps}
                />
                {!['coys'].includes(target) ? (<span
                    className={`${fontColor} h2 align-middle d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex d-xxl-inline-flex bebasFont ps-2`}>
                                    {navName}
                                </span>) : null}
              </NavLink>
              {target === 'fanzinesports' ? (<>
                <NavLink to={'/?league_id=1000'}
                         className={'text-decoration-none ps-4'}>
                                    <span
                                        className={`${fontColor} h2 align-middle bebasFont ps-2`}>
                                        {'FPL News'}
                                    </span>
                </NavLink>
                <NavLink to={'/?league_id=1204'}
                         className={'text-decoration-none ps-4'}>
                                    <span
                                        className={`${fontColor} h2 align-middle bebasFont ps-2`}>
                                        {'EPL News'}
                                    </span>
                </NavLink>
              </>) : null}
            </Navbar.Brand>
            {isComponentVisible && isMobile && renderMainNav()}
            {!isMobile && renderMainNav()}

          </div>) : (
              <>
                <div className={` ${isMobile && 'col-2 d-inline-flex'}`}>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav"
                                 className={`${fontColor} float-start text-start`}
                                 id={'navbarMobileToggle'}
                                 onClick={() => {
                                   setIsComponentVisible(true);
                                   setIsCollapsed(!isCollapsed);
                                 }}/>
                </div>

                <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile &&
                'col-5 text-start'}`}
                              style={{zIndex: 1}}>
                  <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                    <img src={navLogo}
                         alt={navName}
                         {...imageProps}
                    />

                    {!['coys', 'fanzinesports'].includes(target) ? (
                        <span
                            className={`${fontColor} h2 align-middle d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex d-xxl-inline-flex bebasFont ps-2`}>
                                            {navName}
                                        </span>) : null}
                  </NavLink>
                  {target === 'fanzinesports' ? (<>
                    <NavLink to={'/?league_id=1000'}
                             className={'text-decoration-none ps-4'}>
                                        <span
                                            className={`${fontColor} h2 align-middle bebasFont ps-2`}>
                                            {'FPL News'}
                                        </span>
                    </NavLink>
                    <NavLink to={'/?league_id=1204'}
                             className={'text-decoration-none ps-4'}>
                                        <span
                                            className={`${fontColor} h2 align-middle bebasFont ps-2`}>
                                            {'Premier League News'}
                                        </span>
                    </NavLink>
                  </>) : null}
                < /Navbar.Brand>
              </>)}
        </div>
      </Navbar>
    </div>);
  };

  return target !== 'fanzinesports' ? render() : renderFanzineSports();
};

export default NavbarHeader;
