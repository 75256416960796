import GoogleAd from '../../Ads/GoogleAd';
import SkySportsAd from '../../Ads/SkySportsAd';
import FanzineNewsCard from '../../Cards/FanzineNewsCard';
import RightNewsAds from './RightNewsAds';
import React from 'react';
import {getHostname} from '../../../CustomFunctions/getHostname';
import NewsSectionHeading from '../../NewsSectionHeading';
import {COLORS, FONT_COLOR} from '../../../Configs/Targets';

const SingleNewsSection = (props) =>
{
  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;

  const {news, title, isMobile} =props;
  const renderNews = (data, tmpTitle) => {

    return (
        <div className={`container ${isMobile ? 'pt-5' : 'mt-5'}`}>
          <div className={`container ${isMobile && 'pt-5'} `}>
            <div className={`row ${isMobile && 'pt-3'}`}>
              <div
                  className={`col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12`}>
                {tmpTitle !== 'Latest News' && <GoogleAd id={'4009154580'}/>}
                <div className={'row pt-5'}>
                  <NewsSectionHeading title={tmpTitle === 'all' ? data[0]?.leagueName : tmpTitle} bgColor={COLORS[target]} fontColor={FONT_COLOR[target]}/>
                  {(data !== undefined && data !== null) && data?.map((newsInData, index) => {
                    let bigImage = (index === 0 || index === 3);
                    return index <= 15 &&
                        (
                            <div
                                className={`m-0 ${bigImage ?
                                    'col-12 mt-4 p-1' :
                                    'col-xl-6 col-lg-6 col-md-6 p-1'} col-sm-12 col-xs-12`}
                            >
                              {index === 3 ? <SkySportsAd/> :
                                  <FanzineNewsCard
                                      news={newsInData}
                                      imageHeight={bigImage && !isMobile ? '500px' : '245px'}
                                  />
                              }
                            </div>
                        );
                  })
                  }
                  {/*{(data === undefined || data === null) && <LatestNews isMobile={isMobile} isNewsPage={true} limit={leagueCount}/>}*/}
                </div>
                <GoogleAd id={'4009154580'}/>
              </div>
              {!isMobile && <RightNewsAds isMobile={isMobile} />}
            </div>
          </div>
        </div>
    );
  };

  const renderMain = (news,title) =>
  {
    return renderNews(news,title);
  }

  return renderMain(news,title)

}
export  default SingleNewsSection;
