const ClubMatches = () =>
{
  return(
      <div className={'text-white'}>
        Matches
      </div>
  )
}

export default ClubMatches;
