import {useSearchParams} from 'react-router-dom';
import {FOOTBALL} from '../../../Axios/EndPoints';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {get} from '../../../Axios/get';
import FanzineNewsCard from '../../Cards/FanzineNewsCard';
import GoogleAd from '../../Ads/GoogleAd';
import NewsSectionHeading from "../../NewsSectionHeading";
import {COLORS, FONT_COLOR} from "../../../Configs/Targets";

const RightNewsAds = (props) => {
    const {isMobile, limit, leagueId, sportEndpoint, fullWidth, apiPage, showHeader, title, target,teamData} = props;
    const [searchParams] = useSearchParams();
    const [rightNewsAds, setRightNewsAds] = useState([]);
    const [rightNewsAdsLoading, setRightNewsAdsLoading] = useState(true);
    const [page, setPage] = useState(1);

    const fetchRightNewsAds = () => {

        let league_id = (leagueId || searchParams.get('team_id') ||
            searchParams.get('league_id') || '');
        let parameters = leagueId ? '?league_id=' + league_id :
            searchParams.get('league_id') ?
                '?league_id=' + league_id + '&limit=' + (limit ? limit.toLocaleString() : '4') : searchParams.get('team_id') ?
                '?team_id=' + league_id + '&limit=' + (limit ? limit.toLocaleString() : '4') :
                '?limit=' + (limit ? limit.toLocaleString() : '4');

        // if (isNewsPage) {
        //   parameters += '?limit=' + props.limit;
        // }

        parameters = searchParams.get('teamType') ? '?teamType=' + searchParams.get('teamType') : parameters;

        let page = parameters === '' ? `?page=${apiPage || 3}` : `&page=${apiPage || 3}`
        let URL = sportEndpoint ? sportEndpoint : FOOTBALL.LatestNewsEndPoint + parameters + page;


        get(URL).then((response) => {
            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response?.data?.map((data) => {
                let news = {
                    main_team: data.main_team,
                    is_snack: data.is_snack,
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London')
                        .local()
                        .startOf('seconds')
                        .fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            setRightNewsAds([...rightNewsAds, ...tempNewsData]);
            setRightNewsAdsLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        setRightNewsAdsLoading(true);
        props.rightNews ? setRightNewsAds(props.rightNews) : fetchRightNewsAds();
    }, [searchParams.get('league_id'), searchParams.get('team_id'), apiPage]);

    const renderRightNewsAds = (News, isMobile) => {
        const SportNews = News;

        const renderRightAds = (isUnlimited) => {

            return (
                <div className={fullWidth ? 'col-12' : `ps-4 col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-1`}>
                    {
                        SportNews.map((value, index) => {

                            if (!isUnlimited && index > (limit || 5)) {
                                return '';
                            }

                            if (index === 1 && showHeader) {
                                return (<NewsSectionHeading title={title} bgColor={title === '' ? 'transparent' : COLORS[target]}
                                                            fontColor={title === '' ? 'transparent' : FONT_COLOR[target]}/>);
                            }

                            return (/*index === 0 ||*/ index % 3 === 0) ?
                                (
                                    <GoogleAd
                                        isRightNewsAds={true}
                                        height={'300px'}
                                        minHeight={'300px'}
                                        width={'300px'}
                                        id={'4009154580'}/>
                                ) :
                                (
                                    <div className={`col-12 ${index === 0 ? 'mt-n1' : 'mt-2'}`}>
                                        <FanzineNewsCard
                                            news={value}
                                            imageHeight={'200px'}
                                            cardTextSize={''}
                                            isNewsPage={false}
                                            showNewsBelow={true}
                                            publisherIconSize={''}
                                            isRightAds={true}
                                            isMain={''}
                                        />
                                    </div>
                                );
                        })
                    }
                </div>
            );
        };

        return renderRightAds();
    };

    return isMobile ? <></> : renderRightNewsAds(rightNewsAds, isMobile);

};

export default RightNewsAds;
