import {useEffect, useState} from 'react';
import moment from 'moment';
import {Link, useLocation} from 'react-router-dom';
import '../../../assets/styles/matches/MatchItem.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {MATCH_STATES} from '../../../Configs/Constants';

const MatchItem = (props) => {
    const {
        matches,
        liveMatchData,
        selectedDate,
        setIsSingleMatchSelected,
        setSingleMatchData,
        isMobile,
        matchStates,
        isFanzineClubMatches,
        selectedTeamType,
    } = props;
    const [isMatchLive, setIsMatchLive] = useState(false);
    const [liveMatchResult, setLiveMatchResult] = useState(null);
    const location = useLocation();

    const [homeTeamIcon, setHomeTeamIcon] = useState(
        (matches?.home_team?.icon.includes('resource.gunners') &&
            matches?.home_team?.icon.replace('resource.gunners',
                'testcdn.fanzine').replace('storage/', ''))
        ||
        (matches?.home_team?.icon.includes('testcdn') &&
            matches?.home_team?.icon.replace('testcdn', 'cdn')),
    );

    const [guestTeamIcon, setGuestTeamIcon] = useState(
        (matches?.guest_team?.icon.includes('resource.gunners') &&
            matches?.guest_team?.icon.replace('resource.gunners',
                'testcdn.fanzine').replace('storage/', ''))
        ||
        (matches?.guest_team?.icon.includes('testcdn') &&
            matches?.guest_team?.icon.replace('testcdn', 'cdn')),
    );

    let match;
    if (matches) {
        match = matches;
    }

    const SetIsMatchLiveStatus = () => {
        if (matchStates.FINISHED.includes(match?.state) ||
            matchStates.IN_PLAY.includes(match?.state)) {
            setIsMatchLive(true);
        } else {
            setIsMatchLive(false);
        }
    };

    useEffect(() => {
        SetIsMatchLiveStatus();
    }, [matches]);

    const UpdateState = (value) => {
        setLiveMatchResult(value);
    };

    useEffect(() => {

        if (selectedDate === moment(Date.now()).format('YYYY-MM-DD')) {
            // eslint-disable-next-line array-callback-return
            liveMatchData?.map((value) => {
                if (matches?.id === value.id) {
                    UpdateState(value);
                }
            });

            // setLiveMatchResult(liveMatchData);
        }

    }, [liveMatchData]);

    const handleSingleMatchSelect = (value) => {
        setIsSingleMatchSelected(true);
        setSingleMatchData(value);
    };

    const addDefaultSrc = (ev) => {
        ev.target.src = require('../../../assets/images/default-league.png');
    };

    let SingleMatchPageSection = '';
    if (isMatchLive) {
        SingleMatchPageSection = 'overview';
    } else {
        SingleMatchPageSection = 'preview';

    }

    const renderCricketMobileView = () => {
        return (
            <div
                className="row bg-white mt-2 mb-2 p-4 center-items text-decoration-none text-black"
                key={matches?.id}
                style={{cursor: 'pointer'}}
            >
                <div className={'col-12 align-items-start justify-content-start'}>
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <span className={'font-12 font-weight-500'}>
                                {matches?.match_type}
                            </span>
                        </div>
                        <div className={'col-6 text-end'}>
                            <span className={'font-12 font-weight-800'}>
                                {matches?.venue?.name}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className={`font-14 ${isMobile &&
                    'd-flex align-items-center'} p-1 font-weight-800 col-8 sfProDisplayFont text-end fixed`}
                    style={{color: '#252525'}}>
                    <img
                        className={`ms-2`}
                        height={isMobile ? 40 : 50}
                        width={isMobile ? 40 : 50}
                        style={{objectFit: 'contain'}}
                        alt={'team badge'}
                        src={homeTeamIcon ||
                            matches?.home_team?.icon.replace('testcdn', 'cdn')}
                        onError={addDefaultSrc}
                    ></img>
                    &nbsp;
                    &nbsp;

                    {matches?.home_team?.name}
                </div>
                <div className="col-4 text-center p-1 font-14 sfProDisplayFontBold">
                    {matchStates.UPCOMING.includes(
                        (liveMatchResult?.state || matches?.state)) ?
                        moment.utc(matches?.datetime || Date.now())
                            .local()
                            .format('HH:mm')
                        :
                        <div className={'row ms-auto me-auto'}
                             style={{
                                 width: 'max-content',
                                 display: matchStates.POSTPONED.includes(
                                         (liveMatchResult?.state || matches?.state)) &&
                                     'none',
                             }}>
                            <div
                                className={'justify-content-center d-flex h-25px align-items-center'}
                                style={{
                                    backgroundColor: (!location.pathname.includes(
                                        'cricket') && matchStates.IN_PLAY.includes(
                                        liveMatchResult?.state || matches?.state)) ?
                                        'green' :
                                        'transparent',
                                    color: (!location.pathname.includes('cricket') &&
                                        matchStates.IN_PLAY.includes(
                                            liveMatchResult?.state || matches?.state)) ?
                                        'white' :
                                        'black',
                                }}>
                                {liveMatchResult?.home_team?.total_score ||
                                    matches?.home_team?.total_score || '0-0'}
                            </div>
                        </div>
                    }
                    {
                        matchStates.UPCOMING.includes(
                            (liveMatchResult?.state || matches?.state))
                        && (liveMatchResult?.current_time_match ||
                            matches?.current_time_match)
                    }
                    {
                        matchStates.POSTPONED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Postponed'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.ABANDONED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Abandoned'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.CANCELLED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Cancelled'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.SUSPENDED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Suspended'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.INTERRUPTED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Interrupted'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                </div>
                <div
                    className={`font-14 ${isMobile &&
                    'd-flex align-items-center'} p-1 font-weight-800 col-8 sfProDisplayFont text-end fixed`}
                    style={{color: '#252525'}}>
                    <img
                        className={`ms-2`}
                        height={isMobile ? 40 : 50}
                        width={isMobile ? 40 : 50}
                        style={{objectFit: 'contain'}}
                        alt={'team badge'}
                        src={homeTeamIcon ||
                            matches?.visitor_team?.icon.replace('testcdn', 'cdn')}
                        onError={addDefaultSrc}
                    ></img>
                    &nbsp;
                    &nbsp;
                    {matches?.visitor_team?.name}
                </div>
                <div className="col-4 p-1 text-center font-14 sfProDisplayFontBold">
                    {matchStates.UPCOMING.includes(
                        (matches?.state || liveMatchResult?.state)) ?
                        moment.utc(matches?.datetime || Date.now())
                            .local()
                            .format('HH:mm')
                        :
                        <div className={'row ms-auto me-auto'}
                             style={{
                                 width: 'max-content',
                                 display: matchStates.POSTPONED.includes(
                                         (matches?.state || liveMatchResult?.state)) &&
                                     'none',
                             }}>
                            <div
                                className={'justify-content-center d-flex h-25px align-items-center'}
                                style={{
                                    backgroundColor: matchStates.IN_PLAY.includes(
                                        matches?.state || liveMatchResult?.state) ?
                                        'green' :
                                        'transparent',
                                    color: (matchStates.IN_PLAY.includes(
                                        matches?.state || liveMatchResult?.state)) ?
                                        'white' :
                                        'black',
                                }}>
                                {liveMatchResult?.visitor_team?.total_score ||
                                    matches?.visitor_team?.total_score || '0-0'}
                            </div>
                        </div>
                    }
                    {
                        matchStates.UPCOMING.includes(
                            (liveMatchResult?.state || matches?.state))
                        && (liveMatchResult?.current_time_match ||
                            matches?.current_time_match)
                    }
                    {
                        matchStates.POSTPONED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Postponed'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.ABANDONED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Abandoned'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.CANCELLED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Cancelled'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.SUSPENDED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Suspended'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.INTERRUPTED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Interrupted'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                </div>
                <div className={'col-12 align-items-start justify-content-start'}>
                    <span className={'font-12 font-weight-800'}>
                        {matches?.comments}
                    </span>
                </div>
            </div>
        );
    };

    return (location.pathname.includes('cricket') && isMobile) ?
        renderCricketMobileView()
        :
        (
            <Link
                to={location.pathname.includes('football') ? `/football/matches/${matches?.id}/${SingleMatchPageSection}` : MATCH_STATES.IN_PLAY.includes(matches.id) ? `/football/matches/${matches.id}/overview`
            :
                    MATCH_STATES.FINISHED.includes(matches?.state) ?
                        `/football/matches/${matches.id}/overview`
                        :
                        MATCH_STATES.UPCOMING.includes(matches?.state) ?
                            `/football/matches/${matches.id}/preview`
                            :
                            `/football/matches/${matches.id}/preview`

            }
                className="row  bg-white mt-2 mb-2 p-4 center-items text-decoration-none text-black"
                key={matches?.id}
                style={{cursor: 'pointer'}}
                /*onClick={() => {
                    handleSingleMatchSelect(matches);
                }}*/
            >
                {!isMobile && isFanzineClubMatches && selectedTeamType !== 'all' && <div className={'col-2'}>
                    <div className={'row'}>
                        <div className={'col-14 text-nowrap font-12 font-weight-800 text-center'}>
                            {moment(new Date(matches?.datetime)).format('ddd DD MMM YYYY')}
                        </div>
                        <div
                            style={{
                                color:'#959595',
                            }}
                            className={'col-12 font-weight-300 text-nowrap font-12 text-center'}>
                            {(matches?.league_name)?.toUpperCase()}
                        </div>
                    </div>
                    </div>}
                <div
                    className={matches?.home_team?.name.length > 13 ?
                        `${isMobile ?  'col-4' : (isFanzineClubMatches && selectedTeamType !== 'all') ? 'col-3' : 'col-5'} text-end fixed d-flex align-items-center justify-content-end sfProDisplayFont font-14` :
                        ` ${isMobile ?
                        'd-flex align-items-center font-14' : 'font-16'} sfProDisplayFont ${isMobile ? 'col-4' :
                         (isFanzineClubMatches && selectedTeamType !== 'all') ? 'col-3' : 'col-5'} text-end fixed`}
                    style={{color: '#252525'}}>
                    {matches?.home_team?.name}
                    <img
                        className={`ms-2`}
                        height={isMobile ? 35 : 50}
                        width={isMobile ? 35 : 50}
                        style={{objectFit: 'contain'}}
                        alt={'team badge'}
                        src={homeTeamIcon ||
                            matches?.home_team?.icon?.replace('testcdn', 'cdn')}
                        onError={addDefaultSrc}
                    ></img>
                </div>
                <div className={`text-center sfProDisplayFontBold ${isMobile ? 'font-14 d-flex justify-content-center col-3': 'font-16 col-2' }`}>
                    {matchStates.UPCOMING.includes(
                        (liveMatchResult?.state || matches?.state)) ?
                        moment.utc(matches?.datetime || Date.now())
                            .local()
                            .format('HH:mm')
                        :
                        <div className={`row ${isMobile ? '' : 'ms-auto me-auto'}`}
                             style={{
                                 width: 'fit-content',
                                 display: matchStates.POSTPONED.includes(
                                         (liveMatchResult?.state || matches?.state)) &&
                                     'none',
                             }}>
                            <div className={'text-gray justify-content-center d-flex align-items-center'}>
                                {matchStates.FINISHED.includes(
                                        (liveMatchResult?.state || matches?.state))
                                    && (liveMatchResult?.current_time_match ||
                                        matches?.current_time_match)
                                }
                            </div>
                            <div
                                className={'justify-content-center d-flex align-items-center'}
                                style={{
                                    backgroundColor: (!location.pathname.includes(
                                        'cricket') && matchStates.IN_PLAY.includes(
                                        liveMatchResult?.state || matches?.state)) ?
                                        '#74dbef' :
                                        'transparent',
                                    color: '#000',
                                }}>
                                {liveMatchResult?.total || matches?.total || '0-0'}
                            </div>
                        </div>
                    }
                    {
                        matchStates.UPCOMING.includes(
                            (liveMatchResult?.state || matches?.state))
                        && (liveMatchResult?.current_time_match ||
                            matches?.current_time_match)
                    }
                    {
                        matchStates.POSTPONED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Postponed'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.ABANDONED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Abandoned'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.CANCELLED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Cancelled'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.SUSPENDED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Suspended'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                    {
                        matchStates.INTERRUPTED.includes(
                            (liveMatchResult?.state || matches.state))
                        && 'Interrupted'
                        // (liveMatchResult?.current_time_match || matches.current_time_match)
                    }
                </div>
                <div className={`sfProDisplayFont ${isMobile ?
                'd-flex align-items-center font-14 col-4' :
                    (isFanzineClubMatches && selectedTeamType !== 'all') ? 'col-3' : 'font-16 col-4'
                }`}
                     style={{
                         display: 'flex',
                         alignItems: 'center',
                         color: '#252525',
                     }}>
                    <img
                        className={`me-2`}
                        height={isMobile ? 35 : 50}
                        width={isMobile ? 35 : 50}
                        style={{objectFit: 'contain'}}
                        alt={'team badge'}
                        src={guestTeamIcon ||
                            matches?.guest_team?.icon?.replace('testcdn', 'cdn')}
                        onError={addDefaultSrc}
                    ></img>
                    {matches?.guest_team?.name}
                </div>
                <div className={'col-1 text-center font-14 sfProDisplayFontBold'}>
                    <span
                        className="fa-layers fa-fw d-flex justify-content-center align-items-center"
                        style={{
                            color: (matchStates.IN_PLAY.includes(matches?.state) ||
                                matchStates.IN_PLAY.includes(
                                    liveMatchResult?.state)) ?
                                'white' :
                                'black',
                        }}
                    >
                        <p
                            className={'d-flex align-items-center justify-content-center ps-0 ms-4 mt-3 text-black font-16'}
                            style={{zIndex: '2'}}
                        >
                            {matchStates.IN_PLAY.includes(
                                    (liveMatchResult?.state || matches?.state))
                                && ((liveMatchResult?.current_time_match !== '' ? liveMatchResult?.current_time_match : null) ||
                                    (matches?.current_time_match !== '' ? matches?.current_time_match : null))
                            }
                        </p>
                        <FontAwesomeIcon icon={faCircle}
                                         size={'3x'}
                                         style={{
                                             color: matchStates.IN_PLAY.includes(
                                                 (liveMatchResult?.state || matches?.state))
                                             && ((liveMatchResult?.current_time_match !== '' ? liveMatchResult?.current_time_match : null) ||
                                                 (matches?.current_time_match !== '' ? matches?.current_time_match : null)) ? '#74dbef' : 'transparent',
                                         }}
                        >
                        </FontAwesomeIcon>
                    </span>

                </div>
            </Link>
        );
};

export default MatchItem;

