import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {NavLink, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {NAV_CLASS, TEXT_COLORS} from '../../Configs/Targets';
import {getHostname} from '../../CustomFunctions/getHostname';
import {ColorIcon} from '../ClubShop/ColorIcon/ColorIcon';

const NewProductComponent = (props) => {
  const store = useSelector(state => state);
  const location = useLocation();

  // console.log('props.item.src =', props.item?.colorImages)
  const [imageSrc, setImageSrc] = useState(props.item.src);

  const render = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let arrowColor = NAV_CLASS[target] + '-before';
    let showArrows = 'block';
    let item = props.item;
    let showDetail = props.showDetail;
    let showColors = props.showColors;
    let showPriceFirst = props.showPriceFirst;
    let priceColor = props.priceColor ? props.priceColor : '#000000';
    let productColorHex = store.shopify.productColors;

    return (
        <div className={'d-flex pt-3 flex-column'}>
          <NavLink to={target === 'fanzine' ?
              `/${location.pathname.split('/')[1]}/shop/product/${item.handle}`
              : `/shop/product/${item.handle}`}
                   className={'text-decoration-none text-black'}>
            <div key={item.id} className={'slide ms-1 me-1 d-flex justify-content-center'}>
              {item.onSale && <div className="slick-slide-title-right">
                                <span
                                    className={'bg-sale text-dark rounded-circle small p-3 bebasFont'}>
                                    Sale
                                </span>
              </div>}
              <img src={imageSrc} alt={item.alt} className={'img-fluid'}
                   style={{
                     height: 446,
                     width: 446,
                     objectFit: 'cover',
                   }}/>
              {item.bestSeller && <div className="slick-slide-label-left">
                                <span
                                    className={'bg-yellow text-blue small pt-0 ps-1 pe-1 pb-0 sfProDisplayFont'}>
                                    Best Seller
                                </span>
              </div>}
            </div>
          </NavLink>
          {
              showDetail &&
              (<div className={'container'}>
                {showPriceFirst &&
                    (<div
                        className={'text-center sfProDisplayFont font-weight-800'}>
                      {item.oldPrice && (
                          <span className={'me-3 small'}
                                style={{color: '#929191'}}>was&nbsp;
                            <span
                                        className={'text-decoration-line-through small'}
                                        style={{color: '#929191'}}>
                                                £{item.oldPrice}
                                            </span></span>
                      )}
                      <span style={{color: priceColor}}>
                                                £{item.price || '£0.00'}
                                            </span>
                    </div>)
                }
                {
                    !showPriceFirst && (
                        <div
                            className={`text-center sfProDisplayFontBold font-weight-800`}
                            style={{color: TEXT_COLORS[target]}}>
                          {/*{GetConvertedPrice(item.price,store)}*/}
                          £{item.price}
                        </div>
                    )
                }
                <div
                    className={'text-center text-black robotoFont font-weight-400'}>
                  {item.name}
                </div>
                {
                    props.showColors &&
                    (
                        <div
                            className={'text-center sfProDisplayFont font-weight-800'}>
                          {

                              item.colors && item.colors.map((value) => {
                                return (
                                    /*<FontAwesomeIcon icon={faCircle} color={productColorHex[value]} className={`bg-dark rounded-circle shadow-nav ms-1 me-1`} size={'1x'}
                                                     onClick={() => {
                                                         setImageSrc(item.colorImages[value])
                                                      }} style={{cursor: "pointer"}}
                                                     title={value}/>*/
                                    <div onClick={() => {
                                      setImageSrc(item.colorImages[value]);
                                    }} style={{display: 'inline-block'}}>
                                      <ColorIcon color={value}
                                                 colorHex={productColorHex}
                                                 filters={false}/>
                                    </div>
                                );
                              })
                          }
                        </div>
                    )
                }
              </div>)
          }

          {/*{*/}
          {/*	props.newShopItems &&*/}
          {/*	<div className={"justify-content-center align-items-center d-flex mx-2 mb-2 "}>*/}
          {/*		<div style={{borderRadius: "50%", backgroundColor: "white", padding: "10px"}} className={"me-2 border border-dark cursor-pointer"}></div>*/}
          {/*		<div style={{borderRadius: "50%", backgroundColor: "#0032a1", padding: "10px"}}  className={"me-2 border border-dark cursor-pointer"}></div>*/}
          {/*		<div style={{borderRadius: "50%", backgroundColor: "#000000", padding: "10px"}}  className={"border border-dark cursor-pointer"}></div>*/}
          {/*	</div>*/}
          {/*}*/}
        </div>

    );
  };

  return render();
};

NewProductComponent.propTypes = {
  item: PropTypes.object,
  showColors: PropTypes.bool,
};

export default NewProductComponent;
