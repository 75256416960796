const ClubNews = () =>
{
  return(
      <div className={'text-white'}>
        News
      </div>
  )
}

export default ClubNews;
