import React from "react";
import "../styles/FanzineSportsGoalUpNextVideoStyles.css";
import Card from 'react-bootstrap/Card';
const FanzineSportsGoalUpNext = (props) => {
  const { news, src, index,setSelectedVideo,item,selectedVideo,isGoal } = props;

  const handleVideoClick = (selectedItem) =>
  {
    setSelectedVideo(selectedItem);
  }


  return (
      <div
          style={{
            background: item?.url === selectedVideo?.url ? '#000000': 'transparent',
          }}
          onClick={()=>handleVideoClick(item)}
          className={"row mb-4 cursor-pointer"} key={index}>
        <div className={"col-md-7 col-lg-7 col-xl-7 col-xxl-7 p-3"}>
          <span className={"font-18 robotoCondensedBold text-white"}>{news}</span>
        </div>
        <div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5 d-flex align-items-center justify-content-center"}>
          <Card
              className={'border-0'}>
            <Card.Img
                style={{
                  objectFit:'cover',
                }}
                src={src}
                alt="Card image"
            />
            {item?.url === selectedVideo?.url && <Card.ImgOverlay
                style={{
                  marginTop: '5.2em',
                  marginLeft: '-1em',
                  fontWeight:700,
                }}>
              <Card.Text
                  className={'bg-yellow text-black w-100 font-16 d-flex align-items-center justify-content-center'}>
                NOW PLAYING
              </Card.Text>
            </Card.ImgOverlay>
            }
          </Card>
        </div>
      </div>
  );
};
export default FanzineSportsGoalUpNext;
